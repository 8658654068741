import { StyleSheet } from "react-native";

export const styles = StyleSheet.create({
  profileItem: {
    paddingLeft: 8,
    rowGap: 8,
    width: 90,
  },
  profileItemLoading: {
    rowGap: 8,
  },
  skeletonsContainer: {
    columnGap: 8,
    flexDirection: "row",
    paddingHorizontal: 8,
  },
});
