import { StyleSheet } from "react-native";

export const styles = StyleSheet.create({
  closeButton: {
    height: 24,
    margin: 0,
    width: 24,
  },
  container: {
    columnGap: 8,
    flexDirection: "row",
    margin: 16,
  },
  textContainer: {
    alignItems: "flex-start",
    flexDirection: "column",
    flexShrink: 1,
    rowGap: 8,
  },
});
