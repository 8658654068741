import { REACT_APP_BLOOM_WEBSITE } from "@env";
import { LinkingOptions } from "@react-navigation/native";
import Constant from "expo-constants";
import * as Linking from "expo-linking";

import { Routes, Tabs, RootStackParamList } from "@app/common/types/navigation.type";
import { removeUndefinedFromObject } from "@app/utils/object.util";

const prefix = Linking.createURL("", { scheme: Constant.expoConfig?.slug.replace("-", "") });

// Verify that the route you're adding/modifying isnt used anywhere else.
export const getLinking = (initialRouteName: Routes, isNewHomeScreenEnabled: boolean): LinkingOptions<RootStackParamList> | undefined => {
  const linkConfig: LinkingOptions<RootStackParamList> = {
    prefixes: [prefix, REACT_APP_BLOOM_WEBSITE, "localhost:19006"],
    config: {
      initialRouteName,
      screens: {
        [Routes.root]: {
          initialRouteName: Tabs.feed,
          screens: {
            [Tabs.home]: {
              initialRouteName: Routes.home,
              screens: {
                [Routes.home]: "home",
                [Routes.notifications]: "home/notifications",
                [Routes.searchStack]: {
                  initialRouteName: Routes.search,
                  screens: {
                    [Routes.search]: "home/search",
                    [Routes.searchResults]: "home/search-result",
                  },
                },
                [Routes.contests]: "home/contests",
                [Routes.seeAllCollabs]: "home/:type/:sortBy?",
                [Routes.seeAllVideos]: "home/videos/:type",
              },
            },
            [Tabs.feed]: {
              initialRouteName: Routes.feed,
              screens: {
                [Routes.feed]: "feed",
                [Routes.productFromFeed]: "feed/p/:index",
                [Routes.notifications]: "notifications",
              },
            },
            [Tabs.search]: isNewHomeScreenEnabled
              ? undefined
              : {
                  initialRouteName: Routes.search,
                  screens: {
                    [Routes.search]: "search",
                    [Routes.searchResults]: "search/results",
                  },
                },
            [Tabs.profile]: {
              initialRouteName: Routes.profile,
              screens: {
                [Routes.profile]: "profile",
                [Routes.editProfile]: "profile/edit",
                [Routes.editProfileField]: "profile/edit/:fieldName",
                [Routes.settings]: "profile/settings",
                [Routes.notificationSettings]: "profile/settings/notifications",
                [Routes.about]: "profile/settings/about",
                [Routes.changePassword]: "profile/settings/change-password",
                [Routes.godMode]: "profile/god-mode",
                [Routes.managePromos]: "profile/god-mode/manage-promos",
                [Routes.deleteAccount]: "profile/delete",
                [Routes.referAFriend]: "profile/refer-a-friend",
              },
            },
            [Tabs.cartOrders]: {
              initialRouteName: Routes.cartOrders,
              screens: {
                [Routes.cartOrders]: "cart-orders",
              },
            },
            [Tabs.studio]: {
              screens: {
                [Routes.studio]: "studio/workshop",
              },
            },
          },
        },
        [Routes.productUpdateStack]: {
          initialRouteName: Routes.productUpdate,
          screens: {
            // if modified, must update server.js and share-button.component.tsx
            [Routes.productUpdate]: {
              path: "update/:updateId/:index?",
              parse: {
                index: Number,
              },
            },
            [Routes.createProductUpdate]: "create-product-update/:collabId?/:updateId?",
            [Routes.editProductUpdateField]: "create-product-update/edit-field/:fieldName",
          },
        },
        [Routes.preorderStack]: {
          initialRouteName: Routes.preorder,
          screens: {
            [Routes.preorder]: "preorder/:collabId/:variantId",
          },
        },
        [Routes.onboardingStack]: {
          initialRouteName: Routes.userInfo,
          screens: {
            [Routes.userInfo]: "user-info",
            [Routes.notificationsPermission]: "notifications/permissions",
            [Routes.linkInstagramAccount]: "link-instagram",
          },
        },
        [Routes.askForAQuote]: "ask-for-a-quote/:collabHandle",
        [Routes.startCampaign]: "start-campaign/:collabHandle",
        [Routes.updateCollabStage]: "update-collab-stage/:collabId",
        [Routes.createContest]: "create-contest",
        [Routes.createCatalogProduct]: "create-catalog-product",
        [Routes.createPromo]: "profile/god-mode/manage-promos/create-promo",
        [Routes.handleAuthAction]: "auth",
        [Routes.feedback]: "feedback",
        [Routes.image]: "image",
        [Routes.video]: "video",
        [Routes.studioMiniCreateCollab]: "studio/create-concept/new",
        [Routes.studioMiniEditField]: "studio/create-concept/edit-field",
        [Routes.studioMiniExploreDetail]: "studio/explore/:imageId",
        [Routes.studioMiniGeneration]: "studio/create-image",
        [Routes.studioMiniSaved]: "studio/saved",
        [Routes.studioMiniSavedDetails]: "studio/saved/:imageId",
        [Routes.studioMiniSelectImageSource]: "studio/create-concept/select-image-source",
        [Routes.activeContests]: "studio/contests/active",
        [Routes.managePaymentMethods]: "payment-methods",
        [Routes.manageShippingAddress]: "shipping-address",
        [Routes.collabSuccess]: "p/:collabId/success/:type",
        [Routes.superplans]: "/superplans",
        [Routes.superseller]: "/superseller",
        [Routes.productUpdates]: "p/:collabId/updates",
        [Routes.searchStack]: isNewHomeScreenEnabled
          ? {
              initialRouteName: Routes.search,
              screens: {
                [Routes.search]: "search",
                [Routes.searchResults]: "search/results",
              },
            }
          : undefined,
        /* if modified, must update server.js and share-button.component.tsx */
        [Routes.profile]: "u/:id",
        [Routes.product]: "p/:id",
        [Routes.contest]: "contest/:id",
        [Routes.contestEntries]: "contest/:id/entries",
        /* if modified, must update server.js and share-button.component.tsx */
        [Routes.notFound]: "*",
      },
    },
  };

  return removeUndefinedFromObject(linkConfig);
};
