import { QueryHookOptions, gql, useQuery } from "@apollo/client";

import { collabFields } from "@app/common/graphql/fragments.graphql";
import { PaginatedCollabs, UnderReviewCollabsFilteringArgs, UnderReviewCollabsSortingMethod } from "@app/common/graphql/generated/schema.graphql";
import { QueryResult } from "@app/common/types/apollo-result.type";

interface Response {
  underReviewCollabs: PaginatedCollabs;
}

interface Variables {
  first?: number;
  offset?: number;
  sortBy: UnderReviewCollabsSortingMethod;
  where?: UnderReviewCollabsFilteringArgs;
}

const underReviewCollabsQuery = gql`
  query UnderReviewCollabs($first: Int, $offset: Int, $sortBy: UnderReviewCollabsSortingMethod!, $where: UnderReviewCollabsFilteringArgs) {
    underReviewCollabs(first: $first, offset: $offset, sortBy: $sortBy, where: $where) {
      nodes {
        ...CollabFields
      }
      pageInfo {
        hasNextPage
      }
      totalCount
    }
  }
  ${collabFields}
`;

export function useUnderReviewCollabs(options: QueryHookOptions<Response, Variables>): QueryResult<Response, "underReviewCollabs"> {
  const { data, loading, error, fetchMore, networkStatus } = useQuery<Response, Variables>(underReviewCollabsQuery, {
    notifyOnNetworkStatusChange: true,
    ...options,
  });

  return { data: data?.underReviewCollabs, loading, error, fetchMore, networkStatus };
}
