import React, { FC, memo } from "react";

import { LinearGradient } from "expo-linear-gradient";
import { useTranslation } from "react-i18next";
import { Pressable, StyleProp, View, ViewStyle } from "react-native";
import { useTheme } from "react-native-paper";

import { Profile } from "@app/common/graphql/generated/schema.graphql";
import { darkTheme } from "@app/common/style/theme";
import { Routes, useNavigation } from "@app/common/types/navigation.type";
import { Chip } from "@app/components/common/chip/chip.component";
import { Image } from "@app/components/common/image/image.component";
import { Text } from "@app/components/common/text/text.component";

import { CreatorName } from "../../creator-name/creator-name.component";

import { styles } from "./profile-list-item.style";

interface Props {
  profile: Profile;
  width: number;
  isFeaturedItem?: boolean;
  style?: StyleProp<ViewStyle>;
}

const profileImagePlaceholder = require("@app/assets/images/profile-image-placeholder.png") as number;

const ProfileListItem: FC<Props> = ({ profile, width, isFeaturedItem, style }) => {
  const navigation = useNavigation();
  const { t } = useTranslation();
  const { roundness } = useTheme();

  const handleItemPress = (): void => navigation.navigate(Routes.profile, { id: profile.username });

  return (
    <Pressable onPress={handleItemPress} style={[styles.root, { width }, style]}>
      <View>
        <Image key={profile.userId} source={profile.imageUrl} defaultSource={profileImagePlaceholder} width={width} style={styles.image} />
        <LinearGradient locations={[0, 0.75, 1]} colors={["rgba(0,0,0,0)", "rgba(0,0,0,0)", "rgba(0,0,0,0.5)"]} style={styles.gradient} />
        {isFeaturedItem && (
          <Chip
            label={t("featured.label")}
            textColor="onTertiaryContainer"
            bgColor="tertiaryContainer"
            textVariant="body2"
            theme={darkTheme}
            style={[styles.chip, { borderBottomRightRadius: roundness }]}
          />
        )}
        <View style={styles.content}>
          <CreatorName
            name={profile.username}
            badge={profile.badge}
            textProps={{ variant: "caption", color: "common.white", numberOfLines: 1, maxWidth: width - 35, style: styles.username }}
            style={styles.creatorName}
          />
          <Text variant="caption" color="tertiary" numberOfLines={1} theme={darkTheme} style={styles.displayName}>
            {` · ${profile.displayName}`}
          </Text>
        </View>
      </View>
    </Pressable>
  );
};

export default memo(ProfileListItem);
