import React, { FC } from "react";

import { createNativeStackNavigator } from "@react-navigation/native-stack";

import { SearchStackParamList } from "@app/common/types/navigation/stack-param-list.type";
import { HomeStackScreenProps, RootTabStackScreenProps, Routes, Tabs } from "@app/common/types/navigation.type";
import { SearchHeader } from "@app/components/products/search-header/search-header.component";
import { SearchScreen } from "@app/screens/search/search.screen";
import { SearchResultsScreen } from "@app/screens/search-results/search-results.screen";

import { commonScreenOptions } from "../router.const";

const Stack = createNativeStackNavigator<SearchStackParamList>();

export const TabSearchStack: FC<RootTabStackScreenProps<Tabs.search>> = ({ navigation }) => {
  return (
    <>
      <SearchHeader onSearch={query => navigation.navigate(Tabs.search, { screen: Routes.searchResults, params: { query } })} />
      <Stack.Navigator
        initialRouteName={Routes.search}
        screenOptions={{
          ...commonScreenOptions(),
          headerShown: false,
        }}>
        <Stack.Screen name={Routes.search} component={SearchScreen} />
        <Stack.Screen name={Routes.searchResults} component={SearchResultsScreen} />
      </Stack.Navigator>
    </>
  );
};

export const SearchStack: FC<HomeStackScreenProps<Routes.searchStack>> = ({ navigation }) => {
  return (
    <>
      <SearchHeader
        alwaysDisplayBackButton
        autoFocus
        onGoBack={() => navigation.pop()}
        onSearch={query => navigation.navigate(Routes.searchStack, { screen: Routes.searchResults, params: { query } })}
      />
      <Stack.Navigator
        initialRouteName={Routes.search}
        screenOptions={{
          ...commonScreenOptions(),
          headerShown: false,
        }}>
        <Stack.Screen name={Routes.search} component={SearchScreen} />
        <Stack.Screen name={Routes.searchResults} component={SearchResultsScreen} />
      </Stack.Navigator>
    </>
  );
};
