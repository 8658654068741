import React, { FC, useEffect } from "react";

import { View } from "react-native";
import { ActivityIndicator } from "react-native-paper";

import { NavigationProps, Routes } from "@app/common/types/navigation.type";
import { ScreenWrapper } from "@app/components/common/screen-wrapper/screen-wrapper.component";
import { AddPaymentMethodButton } from "@app/components/preorders/add-payment-method-button/add-payment-method-button.component";
import { PaymentMethodList } from "@app/components/preorders/payment-method-list/payment-method-list.component";
import { PaymentMethodListMode } from "@app/components/preorders/payment-method-list/payment-method-list.types";
import { useProfileContext } from "@app/context/profile/profile.context";
import { useSnackbarContext } from "@app/context/snackbar/snackbar.context";
import { usePaymentMethods } from "@app/hooks/api/preorder/use-payment-methods.hook";
import { useShippingAddress } from "@app/hooks/api/preorder/use-shipping-address.hook";
import { useNewHomeNavigation } from "@app/hooks/utils/use-new-home-navigation.hook";

import { styles } from "./manage-payment-methods.style";

export const ManagePaymentMethodsScreen: FC<NavigationProps<Routes.managePaymentMethods>> = () => {
  const { profile, loading: loadingProfile } = useProfileContext();
  const { showErrorSnackbar } = useSnackbarContext();

  const { navigateToMainScreen } = useNewHomeNavigation();

  useEffect(() => {
    if (!loadingProfile && !profile) {
      navigateToMainScreen();
    }
  }, [loadingProfile, navigateToMainScreen, profile]);

  const {
    data: paymentMethods,
    loading: loadingPaymentMethods,
    refetch: refetchPaymentMethods,
  } = usePaymentMethods({
    variables: { userId: profile?.userId },
    onError: err => showErrorSnackbar({ refetch: refetchPaymentMethods, error: err }),
  });

  const {
    data: shippingAddress,
    loading: loadingShippingAddress,
    refetch: refetchShippingAddress,
  } = useShippingAddress({
    variables: { userId: profile?.userId },
    onError: err => showErrorSnackbar({ refetch: refetchShippingAddress, error: err }),
  });

  const noPaymentMethods = !loadingPaymentMethods && !paymentMethods?.length;

  return (
    <ScreenWrapper withScrollView contentContainerStyle={styles.root}>
      <View style={styles.container}>
        {loadingPaymentMethods || loadingShippingAddress ? (
          <ActivityIndicator size="large" />
        ) : (
          <>
            <PaymentMethodList mode={PaymentMethodListMode.nonSelectable} paymentMethods={paymentMethods ?? []} />
            <AddPaymentMethodButton
              disabled={loadingPaymentMethods}
              size="large"
              mode={noPaymentMethods ? "contained" : "text"}
              shippingAddress={shippingAddress}
            />
          </>
        )}
      </View>
    </ScreenWrapper>
  );
};
