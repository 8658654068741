import React, { FC, useEffect, useState } from "react";

import * as Google from "expo-auth-session/providers/google";
import { AuthError, GoogleAuthProvider, UserCredential, signInWithCredential } from "firebase/auth";
import { GoogleLogo } from "phosphor-react-native";
import { useTranslation } from "react-i18next";
import { ActivityIndicator, IconButton, IconButtonProps, useTheme } from "react-native-paper";

import GoogleIcon from "@app/assets/logos/google-logo.svg";
import { getAuthWhenReady } from "@app/context/auth/firebase";
import { googleConfig } from "@app/context/auth/firebase.common";
import { useSnackbarContext } from "@app/context/snackbar/snackbar.context";
import { useHandleAuthError, LoginAction } from "@app/hooks/utils/use-handle-auth-error.hook";
import { log } from "@app/utils/logger/logger.util";

interface Props extends Omit<IconButtonProps, "children" | "icon"> {
  onSignInSuccess?: (credential: UserCredential) => void;
}

export const GoogleButton: FC<Props> = ({ onSignInSuccess, ...props }) => {
  const { t } = useTranslation();
  const { colors } = useTheme();

  const [loading, setLoading] = useState(false);

  const { showErrorSnackbar } = useSnackbarContext();

  const { handleAuthError } = useHandleAuthError();

  const [request, response, promptAsync] = Google.useAuthRequest({
    androidClientId: googleConfig.androidClientId,
    iosClientId: googleConfig.iosClientId,
    expoClientId: googleConfig.webClientId,
    webClientId: googleConfig.webClientId,
  });

  useEffect(() => {
    if (response?.type === "success") {
      const credential = GoogleAuthProvider.credential(null, response.params.access_token);

      getAuthWhenReady()
        .then(auth =>
          signInWithCredential(auth, credential)
            .then(userCredential => {
              log.info("[GoogleButton]: Login successful");
              onSignInSuccess?.(userCredential);
            })
            .catch((error: AuthError) => handleAuthError(error, LoginAction.loginWithGoogle)),
        )
        .catch((error: AuthError) => handleAuthError(error, LoginAction.getAuth));
    } else if (response?.type === "error") {
      log.info(`[GoogleButton]: Login failed ${response.error?.code}`);
      showErrorSnackbar({ error: response.error });
    }

    setLoading(false);
  }, [handleAuthError, onSignInSuccess, response, showErrorSnackbar, t]);

  return (
    <IconButton
      icon={({ size, color }) =>
        loading ? (
          <ActivityIndicator size={size} color={colors.secondary} />
        ) : props.iconColor ? (
          <GoogleLogo weight="bold" size={size + 6} color={color} />
        ) : (
          <GoogleIcon height={size} width={size} />
        )
      }
      disabled={!request || loading}
      onPress={() => {
        setLoading(true);
        log.info("[GoogleLogin]: attempting to login with google");
        void promptAsync();
      }}
      {...props}
    />
  );
};
