import { StyleSheet } from "react-native";

export const styles = StyleSheet.create({
  borderRadius: {
    borderRadius: 10,
  },
  buttonContainer: {
    marginTop: 12,
  },
  buttonContent: {
    flexDirection: "row-reverse",
  },
  rootColumn: {
    alignItems: "center",
    justifyContent: "center",
    padding: 20,
    rowGap: 8,
  },
  rootRow: {
    padding: 16,
  },
  rootRowContentContainer: {
    alignItems: "flex-start",
  },
});
