import React, { FC } from "react";

import {
  ConceptsSortingMethod,
  ForSaleCollabsSortingMethod,
  InDevelopmentCollabsSortingMethod,
  ProfilesSortingMethod,
  UnderReviewCollabsSortingMethod,
} from "@app/common/graphql/generated/schema.graphql";
import { ChildrenProp } from "@app/common/types/children-prop.interface";
import { useHome } from "@app/hooks/api/home/use-home.hook";

import { useProfileContext } from "../profile/profile.context";
import { useSnackbarContext } from "../snackbar/snackbar.context";

import { HomeContext } from "./home.context";

export const HomeContextProvider: FC<ChildrenProp> = ({ children }) => {
  const { profile } = useProfileContext();
  const { showErrorSnackbar } = useSnackbarContext();

  const homePage = useHome({
    variables: {
      first: 6,
      firstProfile: 20,
      conceptsSortBy: ConceptsSortingMethod.trending,
      conceptsWhere: profile?.userId ? { notAlreadySeen: true } : undefined,
      forSaleCollabsSortBy: ForSaleCollabsSortingMethod.random,
      inDevelopmentCollabsSortBy: InDevelopmentCollabsSortingMethod.newest,
      underReviewCollabsSortBy: UnderReviewCollabsSortingMethod.trending,
      profilesSortBy: ProfilesSortingMethod.mostPopular,
      userId: profile?.userId,
    },
    onError: error => showErrorSnackbar({ error }),
  });

  return <HomeContext.Provider value={homePage}>{children}</HomeContext.Provider>;
};
