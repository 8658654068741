import React, { FC, useCallback } from "react";

import { useApolloClient } from "@apollo/client";
import { X } from "phosphor-react-native";
import { View } from "react-native";
import { IconButton, useTheme } from "react-native-paper";

import { TrackEvent } from "@app/common/enums/track-events.enum";
import { FeatureAnnouncement as ApiFeatureAnnouncement, AppLink } from "@app/common/graphql/generated/schema.graphql";
import { lightTheme } from "@app/common/style/theme";
import { Button } from "@app/components/common/button/button.component";
import { Skeleton } from "@app/components/common/skeleton/skeleton.component";
import { Text } from "@app/components/common/text/text.component";
import { SuperplanBackground } from "@app/components/superplans/superplan-background/superplan-background.component";
import { useMixpanelContext } from "@app/context/mixpanel/mixpanel.context";
import { useProfileContext } from "@app/context/profile/profile.context";
import { useMarkFeatureAnnouncementAsSeen } from "@app/hooks/api/home/use-mark-feature-announcement-as-seen.hook";
import { useNavigateToAppLink } from "@app/hooks/utils/use-navigate-to-app-link.hook";
import { useWindowDimensions } from "@app/hooks/utils/use-window-dimensions.hook";

import { getAdIcon } from "../ad.utils";

import { styles } from "./feature-announcement.style";

interface Props {
  featureAnnouncement: ApiFeatureAnnouncement | null | undefined;
  loading: boolean;
}

export const FeatureAnnouncement: FC<Props> = ({ featureAnnouncement, loading }) => {
  const { colors } = useTheme();
  const { width } = useWindowDimensions();

  const client = useApolloClient();
  const { profile } = useProfileContext();
  const { navigateToAppLink } = useNavigateToAppLink();
  const { trackInMixpanel } = useMixpanelContext();

  const { markFeatureAnnouncementAsSeen } = useMarkFeatureAnnouncementAsSeen();

  const handleCtaPress = useCallback(
    (link: AppLink, featureAnnouncementId: string) => {
      navigateToAppLink(link);
      trackInMixpanel(TrackEvent.featureAnnouncementInteraction, { announcementId: featureAnnouncementId });
    },
    [navigateToAppLink, trackInMixpanel],
  );

  const handleDismiss = useCallback(
    (featureAnnouncementId: string) => {
      if (profile?.userId) {
        void markFeatureAnnouncementAsSeen({ variables: { featureAnnouncementId, userId: profile.userId } });
      }

      client.cache.modify({
        fields: {
          currentFeatureAnnouncement: () => {
            return null;
          },
        },
      });

      trackInMixpanel(TrackEvent.dismissFeatureAnnouncement, { announcementId: featureAnnouncementId });
    },
    [client.cache, markFeatureAnnouncementAsSeen, profile?.userId, trackInMixpanel],
  );

  if (loading) return <Skeleton height={172} width={width} radius="square" />;

  if (!featureAnnouncement) return undefined;

  return (
    <SuperplanBackground>
      <View style={styles.container}>
        <View style={styles.textContainer}>
          <Text variant="h8" color="common.black" lineHeight={22}>
            {featureAnnouncement.title}
          </Text>
          <Text variant="body2" color="common.black">
            {featureAnnouncement.text}
          </Text>
          <Button
            color="primary"
            mode="contained"
            theme={lightTheme}
            icon={iconProps => getAdIcon(featureAnnouncement.icon, typeof iconProps.size === "number" ? iconProps.size : undefined, iconProps.color)}
            onPress={() => handleCtaPress(featureAnnouncement.link, featureAnnouncement.featureAnnouncementId)}>
            {featureAnnouncement.linkText}
          </Button>
        </View>
        <IconButton
          icon={X}
          onPress={() => handleDismiss(featureAnnouncement.featureAnnouncementId)}
          iconColor={colors.common.black}
          style={styles.closeButton}
          testID="feature-announcement-dismiss-button"
          aria-label="feature-announcement-dismiss-button"
        />
      </View>
    </SuperplanBackground>
  );
};
