import { StyleSheet } from "react-native";

export const styles = StyleSheet.create({
  deleteButton: {
    position: "absolute",
    right: -20,
    top: -20,
  },
  imageWithFabContainer: {
    marginTop: 16,
  },
  root: {
    alignItems: "center",
    rowGap: 16,
  },
});
