import React, { FC, ReactElement, useCallback, useMemo } from "react";

import { ListRenderItem, TouchableOpacity, View } from "react-native";

import { Profile } from "@app/common/graphql/generated/schema.graphql";
import { customFonts } from "@app/common/style/fonts";
import { Routes, useHomeNavigation } from "@app/common/types/navigation.type";
import { AvatarImage } from "@app/components/common/avatar-image/avatar-image.component";
import { Skeleton } from "@app/components/common/skeleton/skeleton.component";
import { Text } from "@app/components/common/text/text.component";
import { useHomeContext } from "@app/context/home/home.context";

import { HomeSectionType } from "../../home.type";
import { HomeSection } from "../home-section.component";

import { styles } from "./profile-section.style";

interface Props {
  title: string;
  type: HomeSectionType;
}

export const ProfileSection: FC<Props> = ({ title, type }) => {
  const navigation = useHomeNavigation();

  const { data, loading, error } = useHomeContext();

  const avatarSize = 80;
  const profiles = data?.[type].nodes as Profile[] | undefined;

  const renderProfileItem = useCallback<ListRenderItem<Profile>>(
    ({ item }) => (
      <TouchableOpacity style={styles.profileItem} onPress={() => navigation.navigate(Routes.profile, { id: item.username })}>
        <AvatarImage image={item.imageUrl} size={avatarSize} fallbackText={item.displayName.slice(0, 2).toUpperCase()} />
        <Text numberOfLines={1} variant="caption">
          {item.displayName}
        </Text>
      </TouchableOpacity>
    ),
    [navigation],
  );

  const emptySection = useMemo((): ReactElement | undefined => {
    if (!loading && !error) return undefined;

    return (
      <View style={styles.skeletonsContainer}>
        {new Array(8).fill(0).map((_, index) => (
          <View key={index} style={styles.profileItemLoading}>
            <Skeleton width={avatarSize} height={avatarSize} radius="round" />
            <Skeleton width={60} height={customFonts.caption.lineHeight} />
          </View>
        ))}
      </View>
    );
  }, [error, loading]);

  return (
    <HomeSection
      data={profiles}
      renderItem={renderProfileItem}
      title={title}
      ListEmptyComponent={emptySection}
      hidden={!loading && !error && !profiles?.length}
    />
  );
};
