import React, { FC, useCallback, useEffect } from "react";

import { X } from "phosphor-react-native";
import { useTranslation } from "react-i18next";
import { SafeAreaView, View } from "react-native";
import { ActivityIndicator, IconButton, useTheme } from "react-native-paper";

import ShareIcon from "@app/assets/icons/share-thin.svg";
import { isNotFound } from "@app/common/apollo/apollo.utils";
import { CartOrdersTab } from "@app/common/enums/cart-orders-tab.enum";
import { Stage } from "@app/common/graphql/generated/schema.graphql";
import { PreorderStackScreenProps } from "@app/common/types/navigation/screen-navigation.type";
import { Routes, Tabs } from "@app/common/types/navigation.type";
import { Button, ButtonProps } from "@app/components/common/button/button.component";
import { Image } from "@app/components/common/image/image.component";
import { BackButton } from "@app/components/common/navigation/back-button.component";
import { ScreenWrapper } from "@app/components/common/screen-wrapper/screen-wrapper.component";
import { ShareButton } from "@app/components/common/share-button/share-button.component";
import { Text } from "@app/components/common/text/text.component";
import { PreorderBottomActionButton } from "@app/components/preorders/preorder-bottom-action-button/preorder-bottom-action-button.component";
import { PreorderConfirmation } from "@app/components/preorders/preorder-confirmation/preorder-confirmation.component";
import { usePreorderContext } from "@app/context/preorder/preorder.context";
import { useProfileContext } from "@app/context/profile/profile.context";
import { useSnackbarContext } from "@app/context/snackbar/snackbar.context";
import { useCollab } from "@app/hooks/api/use-collab.hook";
import { useNewHomeNavigation } from "@app/hooks/utils/use-new-home-navigation.hook";
import { useWindowDimensions } from "@app/hooks/utils/use-window-dimensions.hook";

import { styles } from "./preorder.style";

export const PreorderScreen: FC<PreorderStackScreenProps<Routes.preorder>> = ({ navigation, route }) => {
  const { t } = useTranslation();
  const { width: screenWidth } = useWindowDimensions();
  const { colors } = useTheme();
  const { profile, loading: loadingProfile } = useProfileContext();
  const { showErrorSnackbar } = useSnackbarContext();

  const { isAnonymous, preorderCompleted, loadingPreorder, setInput } = usePreorderContext();
  const { navigateToMainScreen } = useNewHomeNavigation();

  const { collabId, variantId, quantity, orderId } = route.params;
  const parsedQuantity = quantity ? parseInt(quantity) : NaN;

  useEffect(() => {
    setInput(prev => ({ ...prev, variantId, collabId, quantity: parsedQuantity, orderId }));
  }, [collabId, orderId, parsedQuantity, setInput, variantId]);

  useEffect(() => {
    if (!collabId) {
      showErrorSnackbar();
      navigateToMainScreen();
    } else if (!variantId || isNaN(parsedQuantity)) {
      showErrorSnackbar();
      navigation.replace(Routes.product, { id: collabId });
    }
  }, [collabId, loadingProfile, navigation, profile, parsedQuantity, showErrorSnackbar, variantId, navigateToMainScreen]);

  const { data: product, refetch: refetchCollab } = useCollab({
    variables: { collabId },
    onCompleted: data => {
      const { collab } = data;
      if (collab.progress.stage !== Stage.underReview || !collab.progress.reviewEndAt || !collab.price) {
        showErrorSnackbar();
        navigation.replace(Routes.product, { id: collabId });
      }
    },
    onError: err => {
      if (isNotFound(err)) {
        navigation.replace(Routes.notFound);
        return;
      }
      showErrorSnackbar({ refetch: refetchCollab, error: err });
    },
  });

  const handleClose = useCallback(
    () =>
      navigation.navigate(Routes.root, {
        screen: Tabs.cartOrders,
        params: { screen: Routes.cartOrders, params: { tab: CartOrdersTab.orders }, initial: false },
      }),
    [navigation],
  );

  useEffect(() => {
    navigation.setOptions({
      headerLeft: props => <BackButton onPress={() => navigation.goBack()} disabled={loadingPreorder} {...props} />,
    });
  }, [loadingPreorder, navigation]);

  useEffect(() => {
    if (preorderCompleted) {
      if (orderId) {
        handleClose();
        return;
      }
      navigation.setOptions({
        title: "",
        headerLeft: () => <></>,
        headerRight: props => <IconButton icon={X} {...props} onPress={handleClose} />,
      });
    }
  }, [handleClose, navigation, orderId, preorderCompleted]);

  const ShareCollabButton: FC<Pick<ButtonProps, "containerStyle" | "mode">> = ({ containerStyle, mode }) => (
    <ShareButton
      buttonComponent={({ onPress }) => (
        <Button
          icon={({ size, ...iconProps }) => <ShareIcon width={size} height={size} {...iconProps} />}
          mode={mode}
          onPress={onPress}
          size="large"
          containerStyle={containerStyle}
          fullWidth>
          {t("preorder.success.cta")}
        </Button>
      )}
      entityId={collabId}
      prettyId={product?.handle}
      entityType="collab"
      image={product?.images[0]}
    />
  );

  return (
    <>
      <ScreenWrapper
        contentContainerStyle={styles.root}
        withScrollView
        withKeyboardAvoidingView
        staticContent={<PreorderBottomActionButton shareButtonComponent={ShareCollabButton} />}>
        {preorderCompleted ? (
          <>
            {product && <Image source={product?.images[0]?.imageUrl} width={screenWidth} style={styles.fullScreen} />}
            <View style={[styles.fullScreen, { backgroundColor: colors.backdrop }]} />

            <View style={[styles.fullScreen, styles.completedText]}>
              <Text variant="h3" textAlign="center">
                {t("preorder.success.title")}
              </Text>
              <Text variant="h7" textAlign="center">
                {t("preorder.success.subtitle", { name: product?.name })}
              </Text>
              {isAnonymous && <ShareCollabButton mode="outlined" />}
            </View>
          </>
        ) : (
          <PreorderConfirmation product={product} />
        )}
      </ScreenWrapper>

      {loadingPreorder && (
        <View style={[styles.fullScreen, styles.loading, { backgroundColor: colors.backdrop }]}>
          <ActivityIndicator size="large" color={colors.onBackground} />
        </View>
      )}

      {!loadingPreorder && <SafeAreaView style={[styles.bottomIosHandle, { backgroundColor: colors.tertiaryContainer }]} />}
    </>
  );
};
