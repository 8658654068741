import React, { FC, ReactNode, useMemo, useState } from "react";

import { EmailAuthProvider } from "firebase/auth";
import { Bell, CaretRight, CreditCard, HouseLine, Info, LockSimple, UserCircleMinus, UserFocus, Trash } from "phosphor-react-native";
import { useTranslation } from "react-i18next";
import { TouchableWithoutFeedback, View } from "react-native";
import { Divider, List } from "react-native-paper";
import { Style } from "react-native-paper/lib/typescript/components/List/utils";

import { customFonts } from "@app/common/style/fonts";
import { ProfileStackScreenProps, Routes } from "@app/common/types/navigation.type";
import { Alert } from "@app/components/common/alert/alert.component";
import { ScreenWrapper } from "@app/components/common/screen-wrapper/screen-wrapper.component";
import { Text } from "@app/components/common/text/text.component";
import { BadgeLegend } from "@app/components/profile/badge-legend/badge-legend.component";
import { DevView } from "@app/components/profile/dev-view/dev-view.component";
import { useAuthContext } from "@app/context/auth/auth.context";
import { useProfileContext } from "@app/context/profile/profile.context";
import { getAppVersion } from "@app/utils/app.util";
import { conditionalItem } from "@app/utils/conditional-item-in-array.util";
import { isWeb } from "@app/utils/device.util";
import { clearImageCache } from "@app/utils/file.util";
import { reportError } from "@app/utils/logger/logger.util";

import { styles } from "./settings.style";

interface SettingInterface {
  title: string;
  icon: (props: { color: string; style: Style }) => ReactNode;
  onPress?: () => void;
}

export const SettingsScreen: FC<ProfileStackScreenProps<Routes.settings>> = ({ navigation }) => {
  const { t } = useTranslation();
  const { state } = useAuthContext();
  const { profile } = useProfileContext();

  const [displayDevView, setDisplayDevView] = useState(false);
  const connectedAndVerified = state.connected && state.verified;

  const settings = useMemo<SettingInterface[]>(
    () => [
      ...conditionalItem<SettingInterface>(!!profile, {
        title: t("settings.notifications.label"),
        icon: props => <Bell {...props} />,
        onPress: () => navigation.navigate(Routes.notificationSettings),
      }),
      ...conditionalItem<SettingInterface>(connectedAndVerified, {
        title: t("settings.shippingAddress.label"),
        icon: props => <HouseLine {...props} />,
        onPress: () => navigation.navigate(Routes.manageShippingAddress),
      }),
      ...conditionalItem<SettingInterface>(connectedAndVerified, {
        title: t("settings.paymentInformation.label"),
        icon: props => <CreditCard {...props} />,
        onPress: () => navigation.navigate(Routes.managePaymentMethods),
      }),
      {
        title: t("settings.about.label"),
        icon: props => <Info {...props} />,
        onPress: () => navigation.navigate(Routes.about),
      },
      ...conditionalItem<SettingInterface>(connectedAndVerified, {
        title: t("settings.subscriptions"),
        icon: props => <UserFocus {...props} />,
        onPress: () => navigation.navigate(Routes.superplans),
      }),
      ...conditionalItem<SettingInterface>(connectedAndVerified && state.providers.includes(EmailAuthProvider.EMAIL_PASSWORD_SIGN_IN_METHOD), {
        title: t("settings.changePassword"),
        icon: props => <LockSimple {...props} />,
        onPress: () => navigation.navigate(Routes.changePassword),
      }),
      ...conditionalItem<SettingInterface>(connectedAndVerified, {
        title: t("settings.deleteAccount.title"),
        icon: props => <UserCircleMinus {...props} />,
        onPress: () => navigation.navigate(Routes.deleteAccount),
      }),
      ...conditionalItem<SettingInterface>(!isWeb, {
        title: t("settings.freeUpSpace"),
        icon: props => <Trash {...props} />,
        onPress: () => {
          clearImageCache()
            .then(() => Alert.alert(t("cta.done")))
            .catch(reportError);
        },
      }),
    ],
    [connectedAndVerified, navigation, profile, state.providers, t],
  );

  return (
    <ScreenWrapper withScrollView>
      {settings.map(setting => (
        <List.Item
          key={`setting-item-${setting.title}`}
          title={setting.title}
          left={setting.icon}
          right={props => <CaretRight {...props} />}
          onPress={setting.onPress}
          titleStyle={customFonts.body1}
        />
      ))}

      <TouchableWithoutFeedback delayLongPress={20000} onLongPress={() => setDisplayDevView(prev => !prev)}>
        <View>
          <Text variant="body2" color="tertiary" style={styles.versionContainer}>
            {t("app.appVersion", { version: getAppVersion() })}
          </Text>
        </View>
      </TouchableWithoutFeedback>

      <Divider style={styles.divider} />

      <BadgeLegend />

      {displayDevView && <DevView />}
    </ScreenWrapper>
  );
};
