import React, { FC } from "react";

import { Bell } from "phosphor-react-native";
import { View } from "react-native";
import { Badge, IconButton } from "react-native-paper";

import { useStreamContext } from "@app/context/stream/stream.context";

import { styles } from "./notification-button.style";

interface Props {
  onButtonPress: () => void;
}

export const NotificationButton: FC<Props> = ({ onButtonPress }) => {
  const { userHasNewNotifs } = useStreamContext();

  return (
    <View>
      <IconButton icon={({ color, size }) => <Bell color={color} size={size} />} onPress={onButtonPress} testID="notifications-button" />
      <Badge visible={!!userHasNewNotifs} size={8} style={styles.notifButtonBadge} />
    </View>
  );
};
