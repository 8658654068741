import React, { FC, useMemo } from "react";

import { CurrencyCircleDollar, HourglassSimpleMedium, Vault } from "phosphor-react-native";
import { useTranslation } from "react-i18next";
import { FlatList } from "react-native";
import { useTheme } from "react-native-paper";

import HandCoins from "@app/assets/icons/hand-coins-thin.svg";
import { campaignDurationInDays } from "@app/common/constants/products.const";
import { ListItem } from "@app/components/common/list-item/list-item.component";
import { ProductPrice } from "@app/components/products/product-price/product-price.component";
import { useConfigContext } from "@app/context/config/config.context";
import { isWeb } from "@app/utils/device.util";

import { styles } from "./start-campaign-info.style";

interface Props {
  price?: string;
  compareAtPrice?: string;
  orderCountNeeded?: number;
}

export const StartCampaignInfo: FC<Props> = ({ price, compareAtPrice, orderCountNeeded }) => {
  const { t } = useTranslation("common", { keyPrefix: "startCampaign.dialog.explanation.canStartCampaign" });
  const { colors, roundness } = useTheme();
  const { config } = useConfigContext();

  const offScriptPercentageOfRevenue = config?.offScriptPercentageOfRevenue;

  const startCampaignInfos = useMemo(
    () => [
      {
        value: <ProductPrice price={price} compareAtPrice={compareAtPrice} commonTextProps={{ variant: "h8" }} />,
        description: t("price.description"),
        icon: <CurrencyCircleDollar size={18} color={colors.tertiary} />,
      },
      {
        value: t("orders.value", { orderCountNeeded }),
        description: t("orders.description"),
        icon: <HandCoins width={18} height={18} color={colors.tertiary} />,
      },
      {
        value: t("duration.value", { campaignDurationInDays }),
        description: t("duration.description"),
        icon: <HourglassSimpleMedium size={18} color={colors.tertiary} />,
      },
      {
        value: t("revenu.value", { offScriptPercentageOfRevenue }),
        description: t("revenu.description"),
        icon: <Vault size={18} color={colors.tertiary} />,
      },
    ],
    [colors.tertiary, compareAtPrice, offScriptPercentageOfRevenue, orderCountNeeded, price, t],
  );

  return (
    <FlatList
      data={startCampaignInfos}
      renderItem={({ item }) => (
        <ListItem
          title={item.value}
          titleProps={{ variant: "h8" }}
          subtitle={item.description}
          left={item.icon}
          contentContainerStyle={styles.listItemContentContainer}
          style={[{ backgroundColor: colors.tertiaryContainer, borderRadius: roundness }, styles.listItem]}
        />
      )}
      scrollEnabled={isWeb}
      contentContainerStyle={styles.root}
    />
  );
};
