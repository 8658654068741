import React, { FC, useCallback, useMemo, useRef, useState } from "react";

import { useApolloClient } from "@apollo/client";
import { CaretDoubleRight, FlagBanner, Pencil, Star, TrashSimple } from "phosphor-react-native";
import { useTranslation } from "react-i18next";
import { FlatList, ListRenderItem } from "react-native";
import { ActivityIndicator, IconButton, IconButtonProps, useTheme } from "react-native-paper";

import DotsThreeVertical from "@app/assets/icons/dots-three-outline-vertical-duotone.svg";
import HandCoins from "@app/assets/icons/hand-coins-thin.svg";
import { Progress, Stage } from "@app/common/graphql/generated/schema.graphql";
import { Routes, useNavigation } from "@app/common/types/navigation.type";
import { Alert } from "@app/components/common/alert/alert.component";
import { BottomSheet } from "@app/components/common/bottom-sheet/bottom-sheet.component";
import { BottomSheetRefProps } from "@app/components/common/bottom-sheet/bottom-sheet.types";
import { ListItem, ListItemProps } from "@app/components/common/list-item/list-item.component";
import { ReportDialog } from "@app/components/common/report-dialog/report-dialog.component";
import { useAuthContext } from "@app/context/auth/auth.context";
import { useProfileContext } from "@app/context/profile/profile.context";
import { useSnackbarContext } from "@app/context/snackbar/snackbar.context";
import { useSetFeaturedCollab } from "@app/hooks/api/god-mode/use-set-featured-collab.hook";
import { useUpdateCollabCampaign } from "@app/hooks/api/god-mode/use-update-collab-campaign.hook";
import { featuredCollabQuery } from "@app/hooks/api/products/use-featured-collab.hook";
import { useOpenStudio } from "@app/hooks/utils/use-open-studio.hook";
import { conditionalItem } from "@app/utils/conditional-item-in-array.util";
import { mapFeaturedStage } from "@app/utils/featured.util";
import { isAdmin } from "@app/utils/user.util";

import { DeleteProductConfirmationDialog } from "../delete-product-confirmation-dialog/delete-product-confirmation-dialog.component";

import { iconSize, styles } from "./product-more-button.style";

interface Props extends Pick<IconButtonProps, "style"> {
  productId: string;
  productCreatorId: string;
  productProgress: Progress;
  orderCountNeeded?: number;
}

export const ProductMoreButton: FC<Props> = ({ productId, productCreatorId, productProgress, orderCountNeeded, style }) => {
  const { t } = useTranslation();
  const { colors } = useTheme();
  const client = useApolloClient();
  const navigation = useNavigation();
  const { showErrorSnackbar, showSuccessSnackbar } = useSnackbarContext();
  const { openEditStudio } = useOpenStudio();

  const { stage: productStage, canStartCampaign, canUpdateCollab } = productProgress;

  const sheetRef = useRef<BottomSheetRefProps>(null);
  const { profile } = useProfileContext();
  const { state } = useAuthContext();
  const { setFeaturedCollab } = useSetFeaturedCollab();
  const { updateCollabCampaign, loading: loadingUpdateCollabCampaign } = useUpdateCollabCampaign();

  const [deleteConfirmationDialogVisible, setDeleteConfirmationDialogVisible] = useState(false);
  const [reportDialogVisible, setReportDialogVisible] = useState(false);

  const handleOpenBottomSheet = (): void => {
    sheetRef?.current?.open();
  };

  const handleEditCollabStage = useCallback(() => {
    sheetRef.current?.close();
    navigation.navigate(Routes.updateCollabStage, { collabId: productId });
  }, [navigation, productId]);

  const handleUpdateOrderCountNeeded = useCallback(() => {
    Alert.prompt(
      t("godMode.updateOrderCountNeeded.enterOrderCountNeeded"),
      t("godMode.updateOrderCountNeeded.explanation", { currentOrderCountNeeded: orderCountNeeded }),
      [
        { text: t("cta.cancel"), style: "cancel", onPress: () => sheetRef.current?.close() },
        {
          style: "destructive",
          text: t("cta.submit"),
          onPress: (newOrderCountNeeded?: string) => {
            if (!newOrderCountNeeded) return;
            const valueAsNumber = parseInt(newOrderCountNeeded);
            if (!valueAsNumber) return;

            void updateCollabCampaign({
              variables: { collabId: productId, input: { orderCountNeeded: valueAsNumber } },
              onCompleted: () => showSuccessSnackbar({ message: t("godMode.updateOrderCountNeeded.success") }),
            });
            sheetRef.current?.close();
          },
        },
      ],
    );
  }, [orderCountNeeded, productId, showSuccessSnackbar, t, updateCollabCampaign]);

  const handleSetFeaturedCollab = useCallback((): void => {
    sheetRef?.current?.close();
    const featuredStage = mapFeaturedStage[productStage];
    if (!productId || !featuredStage) return;

    Alert.alert(t("error.warning"), t("godMode.setFeatured.confirmationCollab"), [
      { text: t("cta.cancel"), style: "cancel" },
      {
        style: "destructive",
        text: t("cta.yes"),
        onPress: () => {
          void setFeaturedCollab({
            variables: { collabId: productId, stage: featuredStage },
            onCompleted: () => {
              showSuccessSnackbar();
              void client.refetchQueries({ include: [featuredCollabQuery] });
            },
            onError: error => showErrorSnackbar(error),
          });
        },
      },
    ]);
  }, [client, productId, productStage, setFeaturedCollab, showErrorSnackbar, showSuccessSnackbar, t]);

  const handleReport = useCallback(() => {
    sheetRef.current?.close();
    setReportDialogVisible(true);
  }, []);

  const handleDelete = useCallback((): void => {
    sheetRef?.current?.close();
    setDeleteConfirmationDialogVisible(true);
  }, []);

  const handleEdit = useCallback(() => {
    sheetRef?.current?.close();
    openEditStudio(productId);
  }, [openEditStudio, productId]);

  const options = useMemo<ListItemProps[]>(
    () => [
      ...conditionalItem<ListItemProps>(isAdmin(state), {
        title: t("godMode.updateStage.label"),
        left: <CaretDoubleRight color={colors.godMode} size={iconSize} />,
        titleProps: { color: "godMode", variant: "subtitle1" },
        onPress: handleEditCollabStage,
      }),
      ...conditionalItem<ListItemProps>(
        isAdmin(state) && (productStage === Stage.underReview || (productStage === Stage.concept && canStartCampaign)),
        {
          title: t("godMode.updateOrderCountNeeded.label"),
          left: loadingUpdateCollabCampaign ? (
            <ActivityIndicator color={colors.godMode} size={iconSize} />
          ) : (
            <HandCoins color={colors.godMode} width={iconSize} height={iconSize} />
          ),
          titleProps: { color: "godMode", variant: "subtitle1" },
          onPress: handleUpdateOrderCountNeeded,
        },
      ),
      ...conditionalItem<ListItemProps>(isAdmin(state), {
        title: t("godMode.setFeatured.collab"),
        left: <Star color={colors.godMode} size={iconSize} />,
        onPress: handleSetFeaturedCollab,
        titleProps: { color: "godMode", variant: "subtitle1" },
      }),
      ...conditionalItem<ListItemProps>((profile?.userId === productCreatorId && canUpdateCollab) || isAdmin(state), {
        title: t("cta.edit"),
        left: <Pencil color={colors.primary} size={iconSize} />,
        titleProps: { variant: "subtitle1" },
        onPress: handleEdit,
      }),
      ...conditionalItem<ListItemProps>(
        (profile?.userId === productCreatorId && [Stage.concept, Stage.contest].includes(productStage)) || isAdmin(state),
        {
          title: t("cta.delete"),
          left: <TrashSimple color={colors.error} size={iconSize} />,
          titleProps: { color: "error", variant: "subtitle1" },
          onPress: handleDelete,
        },
      ),
      ...conditionalItem<ListItemProps>(profile?.userId !== productCreatorId, {
        title: t("cta.report"),
        left: <FlagBanner color={colors.error} size={iconSize} />,
        titleProps: { color: "error", variant: "subtitle1" },
        onPress: handleReport,
      }),
    ],
    [
      canStartCampaign,
      canUpdateCollab,
      colors.error,
      colors.godMode,
      colors.primary,
      handleDelete,
      handleEdit,
      handleEditCollabStage,
      handleReport,
      handleSetFeaturedCollab,
      handleUpdateOrderCountNeeded,
      loadingUpdateCollabCampaign,
      productCreatorId,
      productStage,
      profile?.userId,
      state,
      t,
    ],
  );

  const renderOption = useCallback<ListRenderItem<ListItemProps>>(
    ({ item }) => <ListItem {...item} contentContainerStyle={[styles.listItem, item.contentContainerStyle]} />,
    [],
  );

  const shouldShowButton = !!options.length;

  return (
    shouldShowButton && (
      <>
        <IconButton
          icon={({ color, size }) => <DotsThreeVertical color={color} width={size} height={size} />}
          style={style}
          onPress={handleOpenBottomSheet}
          iconColor={colors.common.white}
        />

        <BottomSheet ref={sheetRef}>
          <FlatList
            data={options}
            renderItem={renderOption}
            keyExtractor={item => item.title as string}
            showsVerticalScrollIndicator={false}
            style={styles.listContainer}
          />
        </BottomSheet>

        <DeleteProductConfirmationDialog
          productId={productId}
          visible={deleteConfirmationDialogVisible}
          onDismiss={() => setDeleteConfirmationDialogVisible(false)}
        />

        <ReportDialog entityId={productId} entityType="collab" visible={reportDialogVisible} onDismiss={() => setReportDialogVisible(false)} />
      </>
    )
  );
};
