import { QueryHookOptions, gql, useQuery } from "@apollo/client";

import { SupercoinLimit, SupercoinLimitPreorderInput } from "@app/common/graphql/generated/schema.graphql";
import { QueryResult } from "@app/common/types/apollo-result.type";

interface Response {
  supercoinLimitForCreatePreorder: SupercoinLimit;
}

interface Variables {
  input: SupercoinLimitPreorderInput;
}

export const supercoinLimitForCreatePreorderQuery = gql`
  query SupercoinLimitForCreatePreorder($input: SupercoinLimitPreorderInput!) {
    supercoinLimitForCreatePreorder(input: $input) {
      balance
      limitWithCurrentBalance
      limitWithInfiniteBalance
    }
  }
`;

export function useSupercoinLimitForCreatePreorder(
  options?: QueryHookOptions<Response, Variables>,
): QueryResult<Response, "supercoinLimitForCreatePreorder"> {
  const { data, loading, error, refetch } = useQuery<Response, Variables>(supercoinLimitForCreatePreorderQuery, {
    ...options,
  });

  return { data: data?.supercoinLimitForCreatePreorder, loading, error, refetch };
}
