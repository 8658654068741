import { useRoute } from "@react-navigation/native";

import { useNavigation } from "@app/common/types/navigation.type";
import { useProfileContext } from "@app/context/profile/profile.context";
import { log } from "@app/utils/logger/logger.util";
import { isObjectWithProperties } from "@app/utils/object.util";

export function useHandleReferral(): void {
  const navigation = useNavigation();

  const { setReferrerId } = useProfileContext();

  const route = useRoute();

  const params = route.params;

  if (isObjectWithProperties(params, "referrerId")) {
    if (typeof params.referrerId === "string" && params.referrerId !== "") {
      log.info("Setting ReferrerId ", params.referrerId);
      setReferrerId(params.referrerId);
      navigation.setParams({ referrerId: undefined });
    }
  }
}
