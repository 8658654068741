import React, { FC, ReactNode } from "react";

import { LinearGradient } from "expo-linear-gradient";
import { StyleProp, TouchableOpacity, ViewStyle } from "react-native";

import supercoin from "@app/assets/images/gold-dollar-coins-straight.png";
import { supercoinsGradient } from "@app/common/style/common.style";
import { Image } from "@app/components/common/image/image.component";
import { Text } from "@app/components/common/text/text.component";
import { useLoginContext } from "@app/context/auth/login/login.context";
import { useProfileContext } from "@app/context/profile/profile.context";

import { styles } from "./reward-chip.style";

interface Props {
  content: ReactNode;
  style?: StyleProp<ViewStyle>;
  openLoginOnPressAsAnonymous?: boolean;
}

export const RewardChip: FC<Props> = ({ content, style, openLoginOnPressAsAnonymous }) => {
  const { profile } = useProfileContext();
  const { openLogin } = useLoginContext();

  const pressable = !profile?.userId && openLoginOnPressAsAnonymous;

  const handlePress = (): void => {
    if (pressable) {
      openLogin();
    }
  };

  return (
    <TouchableOpacity disabled={!pressable} onPress={handlePress}>
      <LinearGradient colors={supercoinsGradient} locations={[0, 1]} style={[styles.milestoneContainer, style]}>
        {typeof content === "string" ? <Text variant="badgeLabel">{content}</Text> : content}
        <Image source={supercoin} style={styles.supercoinImage} />
      </LinearGradient>
    </TouchableOpacity>
  );
};
