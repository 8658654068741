import React, { FC } from "react";

import { Info } from "phosphor-react-native";
import { useTranslation } from "react-i18next";
import { View } from "react-native";

import FinancialQuote from "@app/assets/icons/financial-quote.svg";
import { Collab } from "@app/common/graphql/generated/schema.graphql";
import { Routes, useNavigation } from "@app/common/types/navigation.type";
import { Button, ButtonProps } from "@app/components/common/button/button.component";
import { Chip } from "@app/components/common/chip/chip.component";
import { useStartCampaignButton } from "@app/hooks/utils/use-start-campaign-button.hook";

import { styles } from "./open-start-campaign-flow-button.style";

interface Props extends Omit<ButtonProps, "children"> {
  collab: Collab;
  buttonComponent?: FC<{ onPress: () => void }>;
}

export const OpenStartCampaignFlowButton: FC<Props> = ({
  collab,
  // eslint-disable-next-line @typescript-eslint/naming-convention
  buttonComponent: ButtonComponent,
  ...buttonProps
}) => {
  const { t } = useTranslation();
  const navigation = useNavigation();

  const {
    handle,
    creator: { userId },
    progress,
  } = collab;

  const { isStartCampaignButtonVisible } = useStartCampaignButton();

  const startCampaignProps = { creatorId: userId, progress };
  const displayStartCampaignButton = isStartCampaignButtonVisible(startCampaignProps);
  const waitingForQuote = progress.waitingForQuote;

  const handlePress = (): void => {
    if (progress.canRequestQuote) {
      navigation.navigate(Routes.askForAQuote, { collabHandle: handle });
    } else {
      navigation.navigate(Routes.startCampaign, { collabHandle: handle });
    }
  };

  if (!displayStartCampaignButton) return null;

  return (
    <View style={styles.root}>
      <Chip
        label={waitingForQuote ? t("startCampaign.obtainingQuote") : t("startCampaign.startYourCampaign")}
        color={waitingForQuote ? "info" : progress.canStartCampaign ? "success" : "warning"}
        style={styles.chip}
      />
      {displayStartCampaignButton && !waitingForQuote && (
        <>
          {ButtonComponent ? (
            <ButtonComponent onPress={handlePress} />
          ) : (
            <Button
              mode="contained"
              icon={iconProps => (progress.canStartCampaign ? <FinancialQuote {...iconProps} /> : <Info {...iconProps} weight="fill" />)}
              fullWidth
              onPress={handlePress}
              {...buttonProps}>
              {progress.canRequestQuote ? t("startCampaign.moreInfo") : t("startCampaign.quoteAvailable")}
            </Button>
          )}
        </>
      )}
    </View>
  );
};
