import React, { FC, ReactElement, useCallback, useMemo } from "react";

import { ListRenderItem } from "react-native";

import { CollabUpdate } from "@app/common/graphql/generated/schema.graphql";
import { CollabUpdateSectionItem } from "@app/components/home/home-section/collab-update-section/collab-update-section-item/collab-update-section-item.component";
import { useHomeContext } from "@app/context/home/home.context";
import { useResponsiveWidthListItem } from "@app/hooks/utils/use-responsive-width-list-item.hook";
import { useWindowDimensions } from "@app/hooks/utils/use-window-dimensions.hook";

import { HomeSectionType } from "../../home.type";
import { HomeSection } from "../home-section.component";

import { CollabUpdateSectionLoading } from "./collab-update-section-loading/collab-update-section-loading.component";

interface Props {
  title: string;
  type: HomeSectionType;
}

export const CollabUpdateSection: FC<Props> = ({ title, type }) => {
  const { width: screenWidth } = useWindowDimensions();
  const { itemWidth } = useResponsiveWidthListItem();
  const { data, loading, error } = useHomeContext();

  const updates = data?.[type].nodes as CollabUpdate[] | undefined;

  const renderCollabItem = useCallback<ListRenderItem<CollabUpdate>>(
    ({ item }) => <CollabUpdateSectionItem update={item} width={screenWidth / 2} />,
    [screenWidth],
  );

  const emptySection = useMemo((): ReactElement | undefined => {
    if (!loading && !error) return undefined;

    return <CollabUpdateSectionLoading screenHeight="auto" quantity={4} screenWidth={screenWidth} itemWidth={itemWidth} horizontal />;
  }, [error, itemWidth, loading, screenWidth]);

  return (
    <HomeSection
      data={updates}
      renderItem={renderCollabItem}
      title={title}
      ListEmptyComponent={emptySection}
      hidden={!loading && !error && !updates?.length}
    />
  );
};
