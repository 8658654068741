import React, { FC, useMemo, JSX, useCallback } from "react";

import { HourglassMedium } from "phosphor-react-native";
import { useTranslation } from "react-i18next";
import { SafeAreaView, View } from "react-native";
import { ActivityIndicator, Divider, useTheme } from "react-native-paper";

import heartIconAnimatedDark from "@app/assets/lotties/heart-filled-dark.json";
import heartIconAnimatedLight from "@app/assets/lotties/heart-filled-light.json";
import { isNotFound } from "@app/common/apollo/apollo.utils";
import { AvailablePurchaseMethod, Stage } from "@app/common/graphql/generated/schema.graphql";
import { ChildrenProp } from "@app/common/types/children-prop.interface";
import { NavigationProps, Routes } from "@app/common/types/navigation.type";
import { OpenStartCampaignFlowButton } from "@app/components/campaign/open-start-campaign-flow-button/open-start-campaign-flow-button.component";
import { PurchaseCampaignItemCaption } from "@app/components/campaign/purchase-campaign-item-caption/purchase-campaign-item-caption";
import { Head } from "@app/components/common/head/head.component";
import { ListItem } from "@app/components/common/list-item/list-item.component";
import { ScreenWrapper } from "@app/components/common/screen-wrapper/screen-wrapper.component";
import { ShareMore } from "@app/components/common/share-more/share-more.component";
import { LikeButton } from "@app/components/products/like-button/like-button.component";
import { OpenVariantPickerButton } from "@app/components/products/open-variant-picker-button/open-variant-picker-button.component";
import { Product } from "@app/components/products/product/product.component";
import { useSnackbarContext } from "@app/context/snackbar/snackbar.context";
import { useCollab } from "@app/hooks/api/use-collab.hook";
import { useStartCampaignButton } from "@app/hooks/utils/use-start-campaign-button.hook";
import { conditionalItem } from "@app/utils/conditional-item-in-array.util";
import { isCollabId } from "@app/utils/id.util";

import { styles } from "./product.style";

const ButtonsWrapper: FC<ChildrenProp> = ({ children }) => {
  const { colors } = useTheme();

  return <View style={[styles.buttonsWrapper, { backgroundColor: colors.tertiaryContainer }, styles.buttonContainer]}>{children}</View>;
};

export const ProductScreen: FC<NavigationProps<Routes.product>> = ({ navigation, route }) => {
  const { dark, colors, roundness } = useTheme();
  const { t } = useTranslation();
  const { showErrorSnackbar } = useSnackbarContext();
  const { isStartCampaignButtonVisible } = useStartCampaignButton();

  const { id: collabIdOrHandle } = route.params;

  const { data: product, refetch } = useCollab({
    variables: collabIdOrHandle ? (isCollabId(collabIdOrHandle) ? { collabId: collabIdOrHandle } : { handle: collabIdOrHandle }) : undefined,
    onError: err => {
      if (isNotFound(err)) {
        navigation.replace(Routes.notFound);
        return;
      }
      showErrorSnackbar({ refetch, error: err });
    },
  });

  const startCampaignButtonProps = useMemo(
    () => (product ? { creatorId: product.creator.userId, progress: product.progress } : undefined),
    [product],
  );
  const startCampaignButtonVisible = isStartCampaignButtonVisible(startCampaignButtonProps);
  const waitingForQuote = product?.progress.waitingForQuote;

  const LikeButtonComponent: FC<{ mode?: "outlined" | "contained" }> = useCallback(
    ({ mode = "outlined" }) =>
      product ? (
        <LikeButton
          liked={product.liked}
          collabId={product.collabId}
          numberOfLikes={product.numberOfLikes}
          animatedIconSource={dark ? heartIconAnimatedDark : heartIconAnimatedLight}
          size="large"
          mode={product.liked ? "contained" : mode}
          loading={false}
          disabled={false}
        />
      ) : undefined,
    [dark, product],
  );

  const mapPurchaseButtons: Record<AvailablePurchaseMethod, JSX.Element | undefined> = useMemo(
    () => ({
      [AvailablePurchaseMethod.unavailable]: (
        <ButtonsWrapper>
          <LikeButtonComponent mode="contained" />
        </ButtonsWrapper>
      ),
      [AvailablePurchaseMethod.preorder]: product ? (
        <ButtonsWrapper>
          <PurchaseCampaignItemCaption
            stage={product.progress.stage}
            reviewEndAt={product.progress.reviewEndAt}
            campaignStatus={product.campaign?.status}
          />
          <OpenVariantPickerButton product={product} buyAction="preorder" size="large" displayPrice />
          <LikeButtonComponent />
        </ButtonsWrapper>
      ) : undefined,
      [AvailablePurchaseMethod.checkout]: product ? (
        <ButtonsWrapper>
          <PurchaseCampaignItemCaption
            stage={product.progress.stage}
            reviewEndAt={product.progress.reviewEndAt}
            campaignStatus={product.campaign?.status}
          />
          <OpenVariantPickerButton product={product} buyAction="addToCart" size="large" displayPrice />
          <OpenVariantPickerButton product={product} buyAction="buyNow" size="large" mode="outlined" displayPrice />
          <LikeButtonComponent />
        </ButtonsWrapper>
      ) : undefined,
    }),
    [LikeButtonComponent, product],
  );

  const mapButtons: Record<Stage, JSX.Element | undefined> = useMemo(
    () => ({
      [Stage.concept]: product ? (
        <ButtonsWrapper>
          {startCampaignButtonVisible && startCampaignButtonProps && (
            <>
              {waitingForQuote && (
                <ListItem
                  title={t("startCampaign.dialog.title.waitingForQuote")}
                  titleProps={{ variant: "subtitle1" }}
                  description={t("startCampaign.dialog.explanation.waitingForQuote")}
                  descriptionProps={{ variant: "body2", numberOfLines: 0 }}
                  left={<HourglassMedium color={colors.onBackground} />}
                  style={[styles.campaignInfoContainer, { backgroundColor: colors.surfaceVariant, borderRadius: roundness }]}
                  textContainerStyle={styles.campaignInfoTextContainer}
                />
              )}
              <OpenStartCampaignFlowButton collab={product} size="large" mode="contained" />
              <Divider />
            </>
          )}
          <LikeButtonComponent mode={startCampaignButtonVisible ? "outlined" : "contained"} />
        </ButtonsWrapper>
      ) : undefined,
      [Stage.underReview]: product ? mapPurchaseButtons[product.availablePurchaseMethod] : undefined,
      [Stage.forSale]: product ? mapPurchaseButtons[product.availablePurchaseMethod] : undefined,
      [Stage.contest]: (
        <ButtonsWrapper>
          <LikeButtonComponent mode="contained" />
        </ButtonsWrapper>
      ),
      [Stage.hidden]: (
        <ButtonsWrapper>
          <LikeButtonComponent mode="contained" />
        </ButtonsWrapper>
      ),
    }),
    [
      LikeButtonComponent,
      colors.onBackground,
      colors.surfaceVariant,
      mapPurchaseButtons,
      product,
      roundness,
      startCampaignButtonProps,
      startCampaignButtonVisible,
      t,
      waitingForQuote,
    ],
  );

  if (!product)
    return (
      <ScreenWrapper style={styles.root}>
        <ActivityIndicator size="large" />
      </ScreenWrapper>
    );

  return (
    <>
      <ScreenWrapper withScrollView contentContainerStyle={styles.root} staticContent={mapButtons[product.progress.stage]}>
        <Head
          meta={[
            { property: "og:type", content: "product" },
            { property: "og:title", content: product.name },
            { property: "og:description", content: product.description ?? "" },
            { property: "og:image", content: product.images.length ? product.images[0].imageUrl : "" },
            { name: "twitter:card", content: "summary_large_image" },
            { name: "twitter:site", content: "@offscriptmtl" },
            ...conditionalItem(!!product.creator.twitterLink, { name: "twitter:creator", content: product.creator.twitterLink }),
          ]}
        />

        <Product product={product} />
        <ShareMore />
      </ScreenWrapper>

      <SafeAreaView style={[styles.bottomIosHandle, { backgroundColor: colors.tertiaryContainer }]} />
    </>
  );
};
