import { FetchResult, gql, MutationFunctionOptions, useMutation } from "@apollo/client";

import { ClientName } from "@app/common/enums/client-name.enum";
import { VideoStatus } from "@app/common/graphql/generated/schema.graphql";
import { MutationResult } from "@app/common/types/apollo-result.type";

interface Response {
  uploadVideo: {
    thumbnailUrl: string;
    videoManifestUrl: string;
    status: VideoStatus;
    uploadVideoId: string;
    userId: string;
  };
}

interface Variables {
  input: FormData;
  userId: string;
  videoTarget: string;
}

interface UploadStudioVideoResult extends MutationResult<Response, "uploadVideo"> {
  uploadVideo: (options?: MutationFunctionOptions<Response, Variables>) => Promise<FetchResult>;
}

const uploadVideoMutation = gql`
  # eslint-disable-next-line @graphql-eslint/known-type-names
  mutation UploadVideo($input: FormData!, $userId: String!, $videoTarget: String) {
    # eslint-disable-next-line @graphql-eslint/fields-on-correct-type
    uploadVideo(input: $input, userId: $userId, videoTarget: $videoTarget)
      @rest(
        bodyKey: "input"
        bodySerializer: "fileEncode"
        method: "PUT"
        path: "/upload/{args.userId}/video?videoTarget={args.videoTarget}"
        type: "File"
      ) {
      status
      thumbnailUrl
      uploadVideoId
      userId
      videoManifestUrl
    }
  }
`;

export function useUploadVideo(): UploadStudioVideoResult {
  const [uploadVideo, { loading, error, data }] = useMutation<Response, Variables>(uploadVideoMutation, {
    context: { clientName: ClientName.bloomRest },
  });

  return { uploadVideo, loading, error, data: data?.uploadVideo };
}
