import React, { FC, useCallback, useEffect } from "react";

import { InstagramLogo } from "phosphor-react-native";
import { useTranslation } from "react-i18next";
import { View } from "react-native";

import InstagramIcon from "@app/assets/logos/instagram-logo.svg";
import { ActionFromOnboardingStep, OnboardingStep, TrackEvent } from "@app/common/enums/track-events.enum";
import { OnboardingStackScreenProps, Routes } from "@app/common/types/navigation.type";
import { Button } from "@app/components/common/button/button.component";
import { OnboardingScreenLayout } from "@app/components/initial-states/onboarding-screen-layout/onboarding-screen-layout.component";
import { LinkInstagramAccountButton } from "@app/components/profile/link-intagram-account-button/link-instagram-account-button.component";
import { useMixpanelContext } from "@app/context/mixpanel/mixpanel.context";
import { useOnboardingContext } from "@app/context/onboarding/onboarding.context";
import { useProfileContext } from "@app/context/profile/profile.context";
import { UserStateEnum } from "@app/context/profile/user-state.enum";

import { styles } from "./link-instagram-account.style";

export const LinkInstagramAccountScreen: FC<OnboardingStackScreenProps<Routes.linkInstagramAccount>> = ({ navigation }) => {
  const { t } = useTranslation();
  const { mixpanel } = useMixpanelContext();

  const { updateState, loadingUpdateState, profile } = useProfileContext();
  const { onOnboardingStepCompleted } = useOnboardingContext();

  const handleSkip = useCallback((): void => {
    updateState({ input: { [UserStateEnum.linkInstagramAccountFullScreenDismissed]: true } });
    mixpanel?.track(TrackEvent.actionFromOnboardingStep, {
      index: 2, // corresponding to the third step of the onboarding
      step: OnboardingStep.linkInstagramAccount,
      action: profile?.instagramUsername ? ActionFromOnboardingStep.next : ActionFromOnboardingStep.skip,
    });
    onOnboardingStepCompleted(Routes.linkInstagramAccount, navigation);
  }, [mixpanel, navigation, onOnboardingStepCompleted, profile?.instagramUsername, updateState]);

  useEffect(() => {
    if (profile?.instagramUsername) {
      handleSkip();
    }
  }, [handleSkip, profile?.instagramUsername]);

  useEffect(() => {
    navigation.setOptions({
      headerRight: () => (
        <Button onPress={handleSkip} loading={loadingUpdateState}>
          {t("cta.skip")}
        </Button>
      ),
    });
  }, [handleSkip, loadingUpdateState, navigation, t]);

  return (
    <OnboardingScreenLayout
      title={t("onboarding.instagram.title")}
      subtitle={t("onboarding.instagram.subtitle")}
      description={t("onboarding.instagram.description")}
      content={
        <View style={styles.instagramIconContainer}>
          <InstagramIcon width={24} height={24} />
        </View>
      }
      bottomButton={buttonProps => <LinkInstagramAccountButton {...buttonProps} icon={InstagramLogo} hideLinkingWarning />}
    />
  );
};
