import React, { FC } from "react";

import { StyleProp, View, ViewStyle } from "react-native";
import { useTheme } from "react-native-paper";

import { CollabUpdate } from "@app/common/graphql/generated/schema.graphql";
import { CreatorNameWithAvatar } from "@app/components/profile/creator-name-with-avatar/creator-name-with-avatar.component";
import { UpdateListItem } from "@app/components/updates/update-list-item/update-list-item.component";

import { styles } from "./collab-update-section-item.style";

interface Props {
  update: CollabUpdate;
  width: number;
  style?: StyleProp<ViewStyle>;
}

export const CollabUpdateSectionItem: FC<Props> = ({ update, width, style }) => {
  const { colors } = useTheme();

  return (
    <View style={[styles.root, { width, backgroundColor: colors.tertiaryContainer }, style]}>
      <CreatorNameWithAvatar
        profile={update.user}
        imageSize={32}
        textProps={{ variant: "caption" }}
        width={width}
        style={[styles.creatorName, { backgroundColor: colors.background }]}
      />

      <UpdateListItem update={update} width={width} />
    </View>
  );
};
