import React, { FC } from "react";

import { HomeStackScreenProps, Routes } from "@app/common/types/navigation.type";
import { ScreenWrapper } from "@app/components/common/screen-wrapper/screen-wrapper.component";
import { Contests } from "@app/components/contests/contests/contests.component";

export const ContestsScreen: FC<HomeStackScreenProps<Routes.contests>> = () => (
  <ScreenWrapper>
    <Contests />
  </ScreenWrapper>
);
