import React, { PropsWithChildren, ReactNode } from "react";

import { FeatureFlag } from "@app/context/launch-darkly/feature-flag.enum";
import { useLaunchDarklyContext } from "@app/context/launch-darkly/launch-darkly.context";

interface Props {
  name: FeatureFlag;
  fallbackOnFeatureDisabled?: ReactNode;
}

export const ConditionalFeature: React.FC<PropsWithChildren<Props>> = ({ children, name, fallbackOnFeatureDisabled }) => {
  const { isFeatureEnabled } = useLaunchDarklyContext();

  return isFeatureEnabled(name) ? children : fallbackOnFeatureDisabled;
};
