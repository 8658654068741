/* eslint-disable complexity */
import React, { FC } from "react";

import { LinearGradient } from "expo-linear-gradient";
import { CheckSquare, LinkSimple } from "phosphor-react-native";
import { useTranslation } from "react-i18next";
import { Linking, View, ViewProps } from "react-native";
import { ActivityIndicator, IconButton, useTheme } from "react-native-paper";

import InstagramIcon from "@app/assets/logos/instagram-logo.svg";
import TwitterIcon from "@app/assets/logos/twitter-logo.svg";
import instagramAnimatedDark from "@app/assets/lotties/instagram-logo-dark.json";
import instagramAnimatedLight from "@app/assets/lotties/instagram-logo-light.json";
import userListAnimatedDark from "@app/assets/lotties/user-list-dark.json";
import userListAnimatedLight from "@app/assets/lotties/user-list-light.json";
import { Profile } from "@app/common/graphql/generated/schema.graphql";
import { instagramGradient } from "@app/common/style/common.style";
import { Routes, useProfileNavigation } from "@app/common/types/navigation.type";
import { Button } from "@app/components/common/button/button.component";
import { EmptyState } from "@app/components/common/empty-state/empty-state.component";
import { ListItem } from "@app/components/common/list-item/list-item.component";
import { Text } from "@app/components/common/text/text.component";
import { useProfileContext } from "@app/context/profile/profile.context";
import { UserStateEnum } from "@app/context/profile/user-state.enum";
import { useSnackbarContext } from "@app/context/snackbar/snackbar.context";
import { useGenerateInstagramLoginUrl } from "@app/hooks/api/use-generate-instagram-login.hook";
import { useInstagramLink } from "@app/hooks/api/use-instagram-link.hook";
import { useNavigateToWebview } from "@app/hooks/utils/use-navigate-to-webview.hook";

import { ProfileAvatar } from "../profile-avatar/profile-avatar.component";

import { styles } from "./profile-header.style";

interface Props extends ViewProps {
  profile: Profile;
  isCurrentUser: boolean;
}

export const ProfileHeader: FC<Props> = ({ profile, isCurrentUser, style, ...viewProps }) => {
  const { t } = useTranslation();
  const navigation = useProfileNavigation();
  const { navigateToWebview } = useNavigateToWebview();
  const { colors } = useTheme();
  const { showErrorSnackbar } = useSnackbarContext();

  const { updateState, state: userState } = useProfileContext();
  const { generateInstagramLoginUrl, loading: loadingGenerateInstagramUrl } = useGenerateInstagramLoginUrl();
  const { getInstagramLink, loading } = useInstagramLink({ skip: !profile.instagramUsername, variables: { userId: profile.userId } });

  const isEmptyProfile =
    !profile.bio &&
    !profile.externalLink &&
    !profile.imageUrl &&
    !profile.pronouns.length &&
    !profile.twitterLink &&
    !profile.occupation &&
    !profile.video;

  const handleOpenUrl = (url?: string): void => {
    if (!url) return;
    void Linking.openURL(url);
  };

  const handleLinkInstagram = (): void => {
    void generateInstagramLoginUrl({
      variables: { userId: profile.userId },
      onCompleted: link => navigateToWebview(link.generateInstagramLoginUrl.url, "Instagram"),
      onError: error => showErrorSnackbar({ error }),
    });
  };

  const handlePressInstagram = (): void => {
    getInstagramLink()
      .then(resp => {
        const link = resp.data?.instagramLink.instagramLink;
        if (!link) throw new Error("No link returned");
        handleOpenUrl(link);
      })
      .catch(() => {
        showErrorSnackbar({ message: t("userInfo.fields.instagramLink.errorLink") });
      });
  };

  const handleEditProfile = (): void => navigation.navigate(Routes.editProfile);

  return (
    <View style={[styles.root, style]} {...viewProps}>
      <ListItem
        title={profile.displayName}
        description={profile.occupation}
        left={<ProfileAvatar imageUrl={profile.imageUrl} video={profile.video} name={profile.displayName} size={48} />}
        titleProps={{ variant: "h9", testID: "profile-displayName" }}
        descriptionProps={{ variant: "body2", color: "tertiary" }}
        right={
          isCurrentUser && (
            <Button mode={isEmptyProfile ? "contained" : "outlined"} onPress={handleEditProfile}>
              {isEmptyProfile ? t("profile.editProfile") : t("cta.edit")}
            </Button>
          )
        }
        contentContainerStyle={styles.nameOccImageContent}
      />

      {(!!profile.pronouns.length || profile.bio) && (
        <Text style={styles.bioPronouns}>
          {!!profile.pronouns.length && <Text color="tertiary">{`${profile.pronouns.join("/")} `}</Text>}
          <Text>{profile.bio}</Text>
        </Text>
      )}

      {(profile.externalLink || profile.twitterLink || profile.instagramUsername) && (
        <View style={[styles.row, styles.centerLinks]}>
          {!!profile.externalLink && (
            <IconButton
              icon={({ color }) => <LinkSimple size={24} color={color} />}
              onPress={() => handleOpenUrl(profile.externalLink)}
              style={styles.linkButton}
            />
          )}
          {!!profile.twitterLink && (
            <IconButton
              icon={({ color }) => <TwitterIcon height={24} width={24} color={color} />}
              onPress={() => handleOpenUrl(profile.twitterLink)}
              style={styles.linkButton}
            />
          )}
          {!!profile.instagramUsername && (
            <View>
              <IconButton
                disabled={loading}
                icon={() => (loading ? <ActivityIndicator size={24} /> : <InstagramIcon height={24} width={24} />)}
                onPress={handlePressInstagram}
                style={styles.linkButton}
              />
              <View style={styles.verifiedBadge}>
                <CheckSquare color={colors.onBackground} size={21} weight="fill" />
              </View>
            </View>
          )}
        </View>
      )}

      {isEmptyProfile && isCurrentUser && !userState?.[UserStateEnum.editProfileDismissed] && (
        <EmptyState
          animatedIcon={{ dark: userListAnimatedDark, light: userListAnimatedLight }}
          message={t("emptyState.profile.message")}
          cta={[{ label: t("emptyState.profile.cta"), onPress: handleEditProfile }]}
          onDismiss={() => updateState({ input: { [UserStateEnum.editProfileDismissed]: true } })}
          style={[styles.emptyState, { backgroundColor: colors.tertiaryContainer }]}
        />
      )}
      {!profile.instagramUsername && isCurrentUser && !userState?.[UserStateEnum.linkInstagramAccountDismissed] && (
        <LinearGradient colors={instagramGradient} start={{ x: 0, y: 0 }} end={{ x: 1, y: 0 }} style={styles.instagramGradient}>
          <View style={styles.linkInstagramWrapper}>
            <EmptyState
              animatedIcon={{ dark: instagramAnimatedDark, light: instagramAnimatedLight }}
              message={t("emptyState.instagram.message")}
              cta={[{ label: t("emptyState.instagram.cta"), onPress: handleLinkInstagram, loading: loadingGenerateInstagramUrl }]}
              onDismiss={() => updateState({ input: { [UserStateEnum.linkInstagramAccountDismissed]: true } })}
              style={[styles.emptyState, { backgroundColor: colors.tertiaryContainer }]}
            />
          </View>
        </LinearGradient>
      )}
    </View>
  );
};
