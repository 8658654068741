import { QueryHookOptions, gql, useQuery } from "@apollo/client";

import { collabFields } from "@app/common/graphql/fragments.graphql";
import { PaginatedCollabs, ConceptsSortingMethod, ConceptsFilteringArgs } from "@app/common/graphql/generated/schema.graphql";
import { QueryResult } from "@app/common/types/apollo-result.type";

interface Response {
  concepts: PaginatedCollabs;
}

interface Variables {
  first?: number;
  offset?: number;
  sortBy: ConceptsSortingMethod;
  where?: ConceptsFilteringArgs;
}

const conceptsQuery = gql`
  query Concepts($first: Int, $offset: Int, $sortBy: ConceptsSortingMethod!, $where: ConceptsFilteringArgs) {
    concepts(first: $first, offset: $offset, sortBy: $sortBy, where: $where) {
      nodes {
        ...CollabFields
      }
      pageInfo {
        hasNextPage
      }
      totalCount
    }
  }
  ${collabFields}
`;

export function useConcepts(options: QueryHookOptions<Response, Variables>): QueryResult<Response, "concepts"> {
  const { data, loading, error, fetchMore, networkStatus } = useQuery<Response, Variables>(conceptsQuery, {
    notifyOnNetworkStatusChange: true,
    ...options,
  });

  return { data: data?.concepts, loading, error, fetchMore, networkStatus };
}
