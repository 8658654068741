import { StyleSheet } from "react-native";

export const styles = StyleSheet.create({
  buttonWithDescription: {
    rowGap: 4,
    width: "100%",
  },
  deleteButton: {
    position: "absolute",
    right: -20,
    top: -20,
  },
  playContainer: {
    left: 0,
    padding: 4,
    position: "absolute",
    top: 0,
  },
  preview: {
    borderRadius: 0,
  },
  root: {
    alignItems: "center",
    rowGap: 16,
  },
  uploadDescription: {
    alignSelf: "flex-start",
  },
  videoPreviewWithFabContainer: {
    marginTop: 16,
  },
});
