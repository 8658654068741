import { StyleSheet } from "react-native";

export const styles = StyleSheet.create({
  button: {
    flexDirection: "row-reverse",
  },
  buttonLabel: {
    textTransform: "uppercase",
  },
  header: {
    paddingLeft: 16,
    paddingRight: 4,
  },
  hidden: {
    borderWidth: 0.1,
    height: 0,
  },
  root: {
    marginBottom: 24,
    rowGap: 16,
  },
  tableContainer: {
    columnGap: 1,
  },
});
