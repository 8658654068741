import {
  ConceptsSortingMethod,
  ForSaleCollabsSortingMethod,
  InDevelopmentCollabsSortingMethod,
  UnderReviewCollabsSortingMethod,
} from "@app/common/graphql/generated/schema.graphql";

// need to match the query name
export enum HomeSectionType {
  ads = "ads",
  behindTheScenesVideos = "behindTheScenesVideos",
  concepts = "concepts",
  currentFeatureAnnouncement = "currentFeatureAnnouncement",
  forSaleCollabs = "forSaleCollabs",
  inDevelopmentCollabs = "inDevelopmentCollabs",
  latestCollabUpdatesForUser = "latestCollabUpdatesForUser",
  profiles = "profiles",
  purchaseableCollabs = "purchaseableCollabs",
  underReviewCollabs = "underReviewCollabs",
}

export type HomeCollabSectionType =
  | HomeSectionType.concepts
  | HomeSectionType.forSaleCollabs
  | HomeSectionType.inDevelopmentCollabs
  | HomeSectionType.purchaseableCollabs
  | HomeSectionType.underReviewCollabs;

export type HomeVideoSectionType = HomeSectionType.behindTheScenesVideos;

export enum HomeDataType {
  ad,
  collab,
  profile,
  update,
  video,
}

export type HomeSortByType =
  | ConceptsSortingMethod
  | ForSaleCollabsSortingMethod
  | InDevelopmentCollabsSortingMethod
  | UnderReviewCollabsSortingMethod
  | undefined;

export interface HomeSectionItemType {
  title: string;
  type: HomeSectionType;
  dataType: HomeDataType;
  defaultSort?: HomeSortByType;
}
