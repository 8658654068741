import React, { FC } from "react";

import { ActivityIndicator } from "react-native-paper";

import { Routes, ProductUpdateStackScreenProps } from "@app/common/types/navigation.type";
import { ScreenWrapper } from "@app/components/common/screen-wrapper/screen-wrapper.component";
import { ProductPreview } from "@app/components/products/product-preview/product-preview.component";
import { UpdateDetails } from "@app/components/updates/update-details/update-details.component";
import { useCollabUpdate } from "@app/hooks/api/updates/use-collab-update.hook";
import { useCollab } from "@app/hooks/api/use-collab.hook";
import { useNewHomeNavigation } from "@app/hooks/utils/use-new-home-navigation.hook";

import { styles } from "./product-update.style";

export const ProductUpdateScreen: FC<ProductUpdateStackScreenProps<Routes.productUpdate>> = ({ navigation, route }) => {
  const { index, updateId } = route.params;

  const { navigateToMainScreen } = useNewHomeNavigation();

  const { data: update } = useCollabUpdate({
    variables: { collabUpdateId: updateId },
    onError: () => {
      navigation.canGoBack() ? navigation.goBack() : navigateToMainScreen();
    },
  });

  const collabId = update?.collabId;
  const { data: collab } = useCollab({ variables: { collabId: collabId ?? "" }, skip: !collabId });

  if (!update)
    return (
      <ScreenWrapper>
        <ActivityIndicator size="large" />
      </ScreenWrapper>
    );

  return (
    <ScreenWrapper withScrollView>
      {collab && <ProductPreview product={collab} style={styles.productOverlay} />}
      <UpdateDetails update={update} index={index != null ? index + 1 : undefined} />
    </ScreenWrapper>
  );
};
