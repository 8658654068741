import React, { FC, useCallback, useMemo } from "react";

import { ArrowRight, X } from "phosphor-react-native";
import { Trans, useTranslation } from "react-i18next";
import { View } from "react-native";
import { IconButton, Modal, Portal, useTheme } from "react-native-paper";
import { ViewProps } from "react-native-svg/lib/typescript/fabric/utils";

import FeatureAnnouncementImage from "@app/assets/icons/supercreator-badge.svg";
import gradient from "@app/assets/images/full-screen-superseller-gradient.png";
import OffscriptLogo from "@app/assets/logos/offscript-logo.svg";
import { SuperplanType } from "@app/common/enums/superplan-type.enum";
import { TrackEvent, ViewSuperplanScreenOrigin } from "@app/common/enums/track-events.enum";
import { lightTheme, transparent } from "@app/common/style/theme";
import { ChildrenProp } from "@app/common/types/children-prop.interface";
import { Routes, useNavigation } from "@app/common/types/navigation.type";
import { Button } from "@app/components/common/button/button.component";
import { Text } from "@app/components/common/text/text.component";
import { SuperplanBackground } from "@app/components/superplans/superplan-background/superplan-background.component";
import { FeatureFlag, currentFeatureAnnouncementId } from "@app/context/launch-darkly/feature-flag.enum";
import { useLaunchDarklyContext } from "@app/context/launch-darkly/launch-darkly.context";
import { useLocalStorageContext } from "@app/context/local-storage/local-storage.context";
import { LocalStorageKeys } from "@app/context/local-storage/local-storage.type";
import { useMixpanelContext } from "@app/context/mixpanel/mixpanel.context";
import { useOnboardingContext } from "@app/context/onboarding/onboarding.context";
import { useProfileContext } from "@app/context/profile/profile.context";
import { useComponentSize } from "@app/hooks/utils/use-component-size.hook";
import { useWindowDimensions } from "@app/hooks/utils/use-window-dimensions.hook";
import { isDesktop } from "@app/utils/device.util";
import { isLikelyInEmbeddedWebview } from "@app/utils/user-agent.util";

import { Image } from "../image/image.component";

import { styles } from "./deprecated-feature-announcement.style";

export const DeprecatedFeatureAnnouncement: FC<Pick<ViewProps, "style">> = ({ style }) => {
  const { trackInMixpanel } = useMixpanelContext();
  const { t } = useTranslation();
  const { colors, dark, roundness } = useTheme();
  const navigation = useNavigation();
  const { width: windowWidth, height: windowHeight } = useWindowDimensions();

  const maxCardHeight = windowHeight - 175;
  const desktopCardHeight = windowWidth + 150;

  const [cardSize, handleCardLayout] = useComponentSize({ defaultSize: { height: maxCardHeight } });
  const [contentSize, handleContentSize] = useComponentSize({ defaultSize: { height: 250 } });
  const availableHeightForImage = cardSize.height - contentSize.height;
  const imageSize = 120;

  const { showLoginScreen, numberOfStepsToShow } = useOnboardingContext();
  const { loading: loadingLocalStorage, featureAnnouncementsViewed, setLocalStorage } = useLocalStorageContext();
  const { profile, loading: loadingProfile } = useProfileContext();
  const { isFeatureEnabled } = useLaunchDarklyContext();

  const isNewHomePageEnabled = isFeatureEnabled(FeatureFlag.enableNewHomePage);
  const sawOnboardingInThisSession = numberOfStepsToShow > 0;
  const hasAlreadySeenFeatureAnnouncement = featureAnnouncementsViewed.includes(currentFeatureAnnouncementId);
  const loading = loadingLocalStorage || loadingProfile;

  const visible = useMemo(
    () =>
      !sawOnboardingInThisSession &&
      isFeatureEnabled(FeatureFlag.showFeatureAnnouncement) &&
      !isLikelyInEmbeddedWebview() &&
      !showLoginScreen &&
      !loading &&
      !hasAlreadySeenFeatureAnnouncement &&
      !!profile?.userId,
    [hasAlreadySeenFeatureAnnouncement, isFeatureEnabled, loading, profile?.userId, sawOnboardingInThisSession, showLoginScreen],
  );

  const handleDismiss = useCallback(
    (track?: boolean) => {
      setLocalStorage(LocalStorageKeys.featureAnnouncementsViewed, featureAnnouncementsViewed.concat(currentFeatureAnnouncementId));
      if (track) trackInMixpanel(TrackEvent.dismissFeatureAnnouncement, { announcementId: currentFeatureAnnouncementId });
    },
    [featureAnnouncementsViewed, setLocalStorage, trackInMixpanel],
  );

  const handleCta = useCallback(() => {
    handleDismiss(false);
    navigation.navigate(Routes.superplans, { tab: SuperplanType.supercreator });
    trackInMixpanel(TrackEvent.viewSuperplanScreen, {
      origin: ViewSuperplanScreenOrigin.featureAnnouncement,
      superplanType: SuperplanType.supercreator,
    });
    trackInMixpanel(TrackEvent.featureAnnouncementInteraction, { announcementId: currentFeatureAnnouncementId });
  }, [handleDismiss, navigation, trackInMixpanel]);

  if (isNewHomePageEnabled) {
    if (!visible) return null;

    return (
      <SuperplanBackground style={style}>
        <View style={styles.homeContainer}>
          <View style={styles.homeTitleContainer}>
            <Text variant="h8" color="common.black">
              {t("featureAnnouncement.whatsNew")} {t("featureAnnouncement.title")}
            </Text>
            <IconButton
              icon={X}
              onPress={() => handleDismiss(true)}
              iconColor={colors.common.black}
              style={styles.closeButton}
              testID="feature-announcement-dismiss-button"
              aria-label="feature-announcement-dismiss-button"
            />
          </View>
          <Trans
            i18nKey="featureAnnouncement.subtitle"
            parent={({ children }: ChildrenProp) => (
              <Text variant="body2" color="common.black">
                {children}
              </Text>
            )}
            components={{
              b: (
                <Text variant="h9" color="common.black">
                  <></>
                </Text>
              ),
            }}
          />
          <Button color="primary" mode="contained" theme={lightTheme} contentStyle={styles.contentButton} onPress={handleCta}>
            {t("featureAnnouncement.cta")}
          </Button>
        </View>
      </SuperplanBackground>
    );
  }

  return (
    <Portal>
      <Modal
        dismissable={false}
        visible={visible}
        style={styles.modal}
        contentContainerStyle={{ shadowColor: transparent }}
        testID="feature-announcement">
        <View style={[styles.contentContainer, { maxWidth: windowWidth }]}>
          <View style={styles.logoContainer}>
            <OffscriptLogo height={32} width={89} color={colors.primary} />
          </View>

          <View
            onLayout={handleCardLayout}
            style={[
              styles.announcementContainer,
              {
                backgroundColor: dark ? colors.common.gray : colors.common.white,
                borderRadius: roundness * 2,
                borderColor: colors.surfaceDisabled,
                ...(isDesktop ? { height: desktopCardHeight } : {}),
                maxHeight: maxCardHeight,
              },
            ]}>
            <Image source={gradient} blurRadius={16} style={styles.background} />
            <View style={[styles.imageContainer, { height: availableHeightForImage }]}>
              <FeatureAnnouncementImage width={imageSize} height={imageSize} />
            </View>
            <View onLayout={handleContentSize} style={styles.announcementText}>
              <View>
                <Text variant="h6" textAlign="center" color="common.black">
                  {t("featureAnnouncement.whatsNew")}
                </Text>
                <Text variant="h5" textAlign="center" color="common.black">
                  {t("featureAnnouncement.title")}
                </Text>
              </View>
              <Trans
                i18nKey="featureAnnouncement.subtitle"
                parent={({ children }: ChildrenProp) => (
                  <Text variant="body2" textAlign="center" color="common.black">
                    {children}
                  </Text>
                )}
                components={{
                  b: (
                    <Text variant="h9" textAlign="center" color="common.black">
                      <></>
                    </Text>
                  ),
                }}
              />
              <Button
                size="large"
                color="primary"
                mode="contained"
                fullWidth
                icon={({ color, size }) => <ArrowRight size={size} color={color} />}
                theme={lightTheme}
                contentStyle={styles.contentButton}
                onPress={handleCta}>
                {t("featureAnnouncement.cta")}
              </Button>
            </View>
          </View>

          <Button onPress={() => handleDismiss(true)} testID="feature-announcement-dismiss-button">
            {t("cta.dismiss")}
          </Button>
        </View>
      </Modal>
    </Portal>
  );
};
