import { useCallback, useMemo, useRef } from "react";

import * as StoreReview from "expo-store-review";

import { useLocalStorageContext } from "@app/context/local-storage/local-storage.context";
import { LocalStorageKeys } from "@app/context/local-storage/local-storage.type";
import { useProfileContext } from "@app/context/profile/profile.context";
import { UserStateEnum } from "@app/context/profile/user-state.enum";
import { log, reportError } from "@app/utils/logger/logger.util";

interface Response {
  requestFirstReview: () => void;
  requestReview: () => Promise<void>;
}

export const useRequestReview = (): Response => {
  const { profile, state, updateState, loading: loadingProfile } = useProfileContext();
  const { firstOpenEventDate, loading: loadingLocalStorage, setLocalStorage } = useLocalStorageContext();
  const userReviewAskedCount = state?.[UserStateEnum.userReviewAskedCount] ?? 0;
  const timeoutId = useRef<NodeJS.Timeout | null>(null);
  const isCurrentlyAsking = useRef<boolean>(false);

  const requestReview = useCallback(async () => {
    if (!profile || isCurrentlyAsking.current) return;
    const canReview = await StoreReview.isAvailableAsync();

    if (!canReview) return;
    const hasAction = await StoreReview.hasAction();

    if (!hasAction) return;

    isCurrentlyAsking.current = true;
    log.info("[ReviewRequest]: Trying to request user review");
    StoreReview.requestReview()
      .then(() => {
        updateState({ input: { [UserStateEnum.userReviewAskedCount]: userReviewAskedCount + 1 } });
      })
      .catch(reportError);

    isCurrentlyAsking.current = false;
  }, [profile, updateState, userReviewAskedCount]);

  const requestFirstReview = useCallback(() => {
    if (timeoutId.current) {
      clearTimeout(timeoutId.current);
      timeoutId.current = null;
    }

    if (!profile || loadingLocalStorage || userReviewAskedCount !== 0 || loadingProfile) return;

    const now = Date.now();
    if (firstOpenEventDate == null) {
      setLocalStorage(LocalStorageKeys.firstOpenEventDate, now);
    }

    const askReviewInMs = 3600000; // 1h * 60min * 60sec * 1000ms

    const timeToRequest = (firstOpenEventDate ?? now) + askReviewInMs;
    const timeLeft = timeToRequest - Date.now();

    if (timeLeft < 0) {
      void requestReview();
    } else {
      timeoutId.current = setTimeout(() => {
        void requestReview();
      }, timeLeft);
    }
  }, [firstOpenEventDate, loadingLocalStorage, loadingProfile, profile, requestReview, setLocalStorage, userReviewAskedCount]);

  const result = useMemo<Response>(
    () => ({
      requestReview,
      requestFirstReview,
    }),
    [requestFirstReview, requestReview],
  );

  return result;
};
