import { StyleSheet } from "react-native";

import { videoRatio } from "@app/common/constants/video.const";

export const styles = StyleSheet.create({
  imageThumbnail: {
    alignItems: "center",
    aspectRatio: videoRatio,
    display: "flex",
    flex: 1,
    justifyContent: "center",
    width: "100%",
  },
});
