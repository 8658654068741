import { StyleSheet } from "react-native";

export const styles = StyleSheet.create({
  creatorName: {
    padding: 8,
    paddingTop: 0,
  },
  root: {
    flex: 1,
    marginBottom: 8,
  },
});
