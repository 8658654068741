import { StyleSheet } from "react-native";

export const styles = StyleSheet.create({
  button: {
    borderRadius: 20,
  },
  contentContainerFilters: {
    columnGap: 4,
  },
  emptyState: {
    height: 500,
    width: "100%",
  },
  header: {
    alignItems: "center",
    columnGap: 4,
    flexDirection: "row",
  },
});
