import React, { FC, ReactElement, useCallback, useMemo } from "react";

import { useTranslation } from "react-i18next";
import { ListRenderItem, View } from "react-native";

import { Collab } from "@app/common/graphql/generated/schema.graphql";
import { Routes, useHomeNavigation } from "@app/common/types/navigation.type";
import ProductListItem from "@app/components/products/product-list/product-list-item/product-list-item.component";
import { ProductListLoading } from "@app/components/products/product-list/product-list-loading/product-list-loading.component";
import { RewardChip } from "@app/components/supercoins/reward-chip/reward-chip.component";
import { useHomeContext } from "@app/context/home/home.context";
import { useSmallestMilestoneObjective } from "@app/hooks/api/supercoins/use-smallest-milestone-objective.hook";
import { useResponsiveWidthListItem } from "@app/hooks/utils/use-responsive-width-list-item.hook";
import { useWindowDimensions } from "@app/hooks/utils/use-window-dimensions.hook";
import { milestoneTypeHomeSectionMap } from "@app/utils/milestone-type.util";
import { formatPriceUSD } from "@app/utils/price.util";

import { HomeCollabSectionType, HomeSortByType } from "../../home.type";
import { HomeSection } from "../home-section.component";

import { styles } from "./collab-section.style";

interface Props {
  title: string;
  type: HomeCollabSectionType;
  sortBy: HomeSortByType;
}

export const CollabSection: FC<Props> = ({ title, type, sortBy }) => {
  const { t } = useTranslation();
  const { width: screenWidth } = useWindowDimensions();
  const { itemWidth } = useResponsiveWidthListItem();
  const navigation = useHomeNavigation();

  const { data, loading, error } = useHomeContext();

  const collabs = data?.[type].nodes;

  const associatedMilestoneObjective = milestoneTypeHomeSectionMap?.[type];
  const { data: milestoneObjective } = useSmallestMilestoneObjective({
    variables: { where: associatedMilestoneObjective ? { milestoneType: associatedMilestoneObjective } : undefined },
  });
  const rewardPrize = formatPriceUSD(milestoneObjective?.reward, { withoutUSD: true });
  const rewardRequiredCount = milestoneObjective?.requiredCount;

  const renderCollabItem = useCallback<ListRenderItem<Collab>>(
    ({ item }) => (
      <View style={styles.itemWrapper}>
        <ProductListItem product={item} width={screenWidth / 2} />
      </View>
    ),
    [screenWidth],
  );

  const emptySection = useMemo((): ReactElement | undefined => {
    if (!loading && !error) return undefined;

    return <ProductListLoading screenHeight="auto" quantity={4} screenWidth={screenWidth} itemWidth={itemWidth} horizontal />;
  }, [error, itemWidth, loading, screenWidth]);

  const subheader = useMemo((): ReactElement | undefined => {
    if (!milestoneObjective) return undefined;

    return (
      <RewardChip
        content={t(`home.reward.${type}`, { count: rewardRequiredCount, price: rewardPrize })}
        openLoginOnPressAsAnonymous
        style={styles.rewardChip}
      />
    );
  }, [milestoneObjective, rewardPrize, rewardRequiredCount, t, type]);

  return (
    <HomeSection
      title={title}
      data={collabs}
      renderItem={renderCollabItem}
      subheader={subheader}
      ListEmptyComponent={emptySection}
      hidden={!loading && !error && !collabs?.length}
      onSeeAllPress={() => navigation.navigate(Routes.seeAllCollabs, { type, sortBy })}
    />
  );
};
