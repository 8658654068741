import React, { FC } from "react";

import { Trans, useTranslation } from "react-i18next";

import postCollabImage from "@app/assets/images/post-collab-thumbnail.png";
import { MilestoneType } from "@app/common/graphql/generated/schema.graphql";
import { ChildrenProp } from "@app/common/types/children-prop.interface";
import { Routes, Tabs, useNavigation } from "@app/common/types/navigation.type";
import { Image } from "@app/components/common/image/image.component";
import { ListItem } from "@app/components/common/list-item/list-item.component";
import { Text } from "@app/components/common/text/text.component";
import { RewardChip } from "@app/components/supercoins/reward-chip/reward-chip.component";
import { useSmallestMilestoneObjective } from "@app/hooks/api/supercoins/use-smallest-milestone-objective.hook";
import { formatPriceUSD } from "@app/utils/price.util";

import { HomeSection } from "../home-section.component";

import { styles } from "./post-collab-section.style";

export const PostCollabSection: FC = () => {
  const { t } = useTranslation();
  const navigation = useNavigation();

  const { data: milestoneObjective } = useSmallestMilestoneObjective({ variables: { where: { milestoneType: MilestoneType.collabMilestone } } });

  const imageSize = 100;

  return (
    <HomeSection title={t("home.becomingACreator")}>
      <ListItem
        descriptionProps={{ variant: "body2" }}
        title={
          <Trans
            i18nKey="home.generateAConcept"
            parent={({ children }: ChildrenProp) => <Text variant="body1">{children}</Text>}
            components={{
              b: (
                <Text variant="h8">
                  <></>
                </Text>
              ),
            }}
          />
        }
        subtitle={
          milestoneObjective && (
            <RewardChip
              content={t("home.reward.generateAConcept", {
                count: milestoneObjective.requiredCount,
                price: formatPriceUSD(milestoneObjective.reward, { withoutUSD: true }),
              })}
              openLoginOnPressAsAnonymous
            />
          )
        }
        left={<Image source={postCollabImage} width={imageSize} style={{ height: imageSize, width: imageSize }} />}
        textContainerStyle={styles.textContainer}
        onPress={() => navigation.navigate(Routes.root, { screen: Tabs.studio, params: { screen: Routes.studio } })}
      />
    </HomeSection>
  );
};
