import React, { FC, PropsWithChildren, useEffect } from "react";

import { StyleProp, ViewStyle } from "react-native";
import { Portal } from "react-native-paper";
import Animated, { Easing, SlideInUp, SlideOutUp } from "react-native-reanimated";
import { useSafeAreaInsets } from "react-native-safe-area-context";

import { Routes, navigationRef } from "@app/common/types/navigation.type";
import { conditionalItem } from "@app/utils/conditional-item-in-array.util";

import { styles } from "./banner.style";

interface Props {
  visible: boolean;
  setVisible: (visible: boolean) => void;
  closeDelay?: number;
  style: StyleProp<ViewStyle>;
}

export const Banner: FC<PropsWithChildren<Props>> = ({ visible, setVisible, closeDelay, style, children }) => {
  const { top } = useSafeAreaInsets();
  const currentRoutes = navigationRef.isReady() ? navigationRef.getState()?.routes.map(route => route.name) : [];
  const onModal = currentRoutes?.length === 1 && currentRoutes.includes(Routes.root);

  useEffect(() => {
    if (!visible || !closeDelay) return undefined;

    const timeout = setTimeout(() => setVisible(false), closeDelay);

    return () => clearTimeout(timeout);
  }, [visible, setVisible, closeDelay]);

  return (
    <Portal>
      {visible && (
        <Animated.View
          entering={SlideInUp.duration(500).easing(Easing.out(Easing.exp))}
          exiting={SlideOutUp.duration(2000).easing(Easing.ease)}
          style={[...conditionalItem(onModal, styles.onModalStyle), { top }, style]}>
          {children}
        </Animated.View>
      )}
    </Portal>
  );
};
