import React, { JSX } from "react";

import {
  Alarm,
  BellRinging,
  Binoculars,
  CalendarBlank,
  Camera,
  ChatTeardropText,
  Coins,
  Confetti,
  HandsClapping,
  IconProps,
  InstagramLogo,
  MagicWand,
  MapPin,
  MegaphoneSimple,
  UserCircleGear,
  UserSquare,
  VideoCamera,
} from "phosphor-react-native";
import { View } from "react-native";

import CartAndLike from "@app/assets/icons/cart-and-like.svg";
import CrownSimple from "@app/assets/icons/crown-simple.svg";
import Supercoin from "@app/assets/icons/supercoin.svg";
import SwipeFill from "@app/assets/icons/swipe-fill.svg";

// eslint-disable-next-line complexity
export function getAdIcon(icon: string, size: number | undefined, color: string | undefined): JSX.Element {
  const iconProps: IconProps = { size, color, weight: "fill" };

  switch (icon) {
    case "Alarm":
      return <Alarm {...iconProps} />;
    case "Bell":
      return <BellRinging {...iconProps} />;
    case "Binoculars":
      return <Binoculars {...iconProps} />;
    case "Calendar":
      return <CalendarBlank {...iconProps} />;
    case "Camera":
      return <Camera {...iconProps} />;
    case "CartAndLike":
      return <CartAndLike height={size} width={size} color={color} />;
    case "ChatBubble":
      return <ChatTeardropText {...iconProps} />;
    case "Coins":
      return <Coins {...iconProps} />;
    case "Confetti":
      return <Confetti {...iconProps} />;
    case "Crown":
      return <CrownSimple height={size} width={size} color={color} />;
    case "HandsClapping":
      return <HandsClapping {...iconProps} />;
    case "InstagramLogo":
      return <InstagramLogo {...iconProps} />;
    case "MagicWand":
      return <MagicWand {...iconProps} />;
    case "MapPin":
      return <MapPin {...iconProps} />;
    case "Megaphone":
      return <MegaphoneSimple {...iconProps} />;
    case "Supercoin":
      return <Supercoin height={size} width={size} color={color} />;
    case "Swipe":
      return <SwipeFill height={size} width={size} color={color} />;
    case "UserCircleGear":
      return <UserCircleGear {...iconProps} />;
    case "UserSquare":
      return <UserSquare {...iconProps} />;
    case "VideoCamera":
      return <VideoCamera {...iconProps} />;
    default:
      return <View style={{ height: size, width: size }} />;
  }
}
