import React, { FC } from "react";

import { TouchableOpacity, View } from "react-native";
import { ActivityIndicator, useTheme } from "react-native-paper";

import { ProfileVideo, VideoStatus } from "@app/common/graphql/generated/schema.graphql";
import { transparent } from "@app/common/style/theme";
import { Routes, useNavigation } from "@app/common/types/navigation.type";
import { AvatarImage } from "@app/components/common/avatar-image/avatar-image.component";
import { FeatureFlag } from "@app/context/launch-darkly/feature-flag.enum";
import { useLaunchDarklyContext } from "@app/context/launch-darkly/launch-darkly.context";

import { styles } from "./profile-avatar.style";

interface Props {
  imageUrl?: string;
  video?: ProfileVideo;
  name: string;
  size?: number;
}

export const ProfileAvatar: FC<Props> = ({ imageUrl, video, name, size = 32 }) => {
  const navigation = useNavigation();
  const { colors } = useTheme();
  const { isFeatureEnabled } = useLaunchDarklyContext();

  const videoOnProfileEnabled = isFeatureEnabled(FeatureFlag.videoOnProfile);
  const videoUrl = video?.videoManifestUrl;
  const videoLoading = video?.status === VideoStatus.processing;
  const hasVideo = videoUrl && videoOnProfileEnabled;

  const handleProfileImagePress = (): void => {
    if (video && hasVideo && !videoLoading) {
      navigation.navigate(Routes.video, { videoUrl, posterUrl: video.thumbnailUrl, cloudflareId: video.cloudflareId });
    } else {
      navigation.navigate(Routes.image, { imageUrl: imageUrl ?? "" });
    }
  };

  const innerAvatarBorderStyle = [styles.innerBorder, { borderColor: transparent }];
  const outerAvatarBorderStyle = [styles.outerBorder, { borderColor: !hasVideo || videoLoading ? transparent : colors.secondary }];

  return (
    <View>
      <TouchableOpacity disabled={!imageUrl && !hasVideo} onPress={handleProfileImagePress} style={outerAvatarBorderStyle}>
        <View style={innerAvatarBorderStyle}>
          <AvatarImage image={imageUrl} size={size} fallbackText={name.slice(0, 2).toUpperCase()} />
        </View>
      </TouchableOpacity>
      {videoLoading && <ActivityIndicator size={size + 9} color={colors.tertiary} style={styles.loadingVideoIndicator} />}
    </View>
  );
};
