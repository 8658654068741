import React, { useCallback, useEffect, useMemo, useState, FC } from "react";

import { REACT_APP_LAUNCH_DARKLY_CLIENT_SIDE_ID } from "@env";
import { initialize as initializeLDClient } from "launchdarkly-js-client-sdk";
import { LDClient, LDContext, LDFlagSet, LDProvider } from "launchdarkly-react-client-sdk";

import { ChildrenProp } from "@app/common/types/children-prop.interface";
import { reportError } from "@app/utils/logger/logger.util";

import { FeatureFlag, featureFlagMap } from "./feature-flag.enum";
import { LaunchDarklyContext } from "./launch-darkly.context";
import { LaunchDarklyContextInterface } from "./launch-darkly.web";

export const LaunchDarklyProvider: FC<ChildrenProp> = ({ children }) => {
  const [loading, setLoading] = useState(true);
  const [initializingClient, setInitializingClient] = useState(false);
  const [ldClient, setLDClient] = useState<LDClient | undefined>();
  const [flags, setFlags] = useState<LDFlagSet>({});
  const mapFF = useMemo(() => featureFlagMap(flags), [flags]);

  const fetchAndSetAllFlags = useCallback((client: LDClient) => {
    const allFlags = client.allFlags();
    setFlags(allFlags);
  }, []);

  const identify = useCallback(
    (newContext: LDContext) => {
      if (ldClient) {
        const oldContext = ldClient.getContext();
        if (oldContext.key === newContext.key) return;

        void ldClient.identify(newContext, undefined, (err, newFlags) => {
          if (newFlags) {
            fetchAndSetAllFlags(ldClient);
          } else if (err) {
            reportError(err);
          }
        });
      } else {
        if (initializingClient) return;
        setInitializingClient(true);
        const client = initializeLDClient(REACT_APP_LAUNCH_DARKLY_CLIENT_SIDE_ID, newContext);
        client
          .waitForInitialization(5)
          .then(() => {
            setLDClient(client);
            fetchAndSetAllFlags(client);
            client.on("change", () => fetchAndSetAllFlags(client));
          })
          .catch(error => reportError(error as Error))
          .finally(() => {
            setInitializingClient(false);
            setLoading(false);
          });
      }
    },
    [fetchAndSetAllFlags, initializingClient, ldClient],
  );

  const isFeatureEnabled = useCallback((name: FeatureFlag): boolean => mapFF[name] ?? false, [mapFF]);

  const alwaysOnFeatureFlagIsDefinedButOff = useMemo(() => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const flag = flags[FeatureFlag.alwaysOn];

    if (flag === undefined || flag === null) return false;

    return !flag;
  }, [flags]);

  useEffect(() => {
    if (alwaysOnFeatureFlagIsDefinedButOff) {
      reportError(new Error(`Feature flag that should always be on returned false`));
    }
  }, [alwaysOnFeatureFlagIsDefinedButOff]);

  const context = useMemo<LaunchDarklyContextInterface>(
    () => ({ loading, setLDContext: identify, isFeatureEnabled }),
    [loading, isFeatureEnabled, identify],
  );

  return (
    <LDProvider clientSideID={REACT_APP_LAUNCH_DARKLY_CLIENT_SIDE_ID} deferInitialization={true} ldClient={ldClient}>
      <LaunchDarklyContext.Provider value={context}>{children}</LaunchDarklyContext.Provider>
    </LDProvider>
  );
};
