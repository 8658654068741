import React, { JSX, ReactNode, useCallback, useEffect, useMemo, useRef, useState } from "react";

import groupBy from "lodash.groupby";
import { Funnel, IconProps, MagnifyingGlass } from "phosphor-react-native";
import { useTranslation } from "react-i18next";
import { Platform, View } from "react-native";
import { FlatList } from "react-native-gesture-handler";
import { Chip, useTheme, TextInput, IconButton, IconButtonProps } from "react-native-paper";

import { FilterCategory } from "@app/common/enums/filter-category.enum";
import { FilterOption, FilteringArgs, Filter } from "@app/common/types/filtering.type";
import { useFilteringArgs } from "@app/hooks/utils/filtering-args/use-filtering-args.hook";
import { isWeb } from "@app/utils/device.util";

import { BottomSheetTextInput } from "../bottom-sheet/bottom-sheet-text-input/bottom-sheet-text-input.component";
import { BottomSheet } from "../bottom-sheet/bottom-sheet.component";
import { BottomSheetRefProps } from "../bottom-sheet/bottom-sheet.types";
import { NestedBottomSheetWithList, ListItemProps } from "../bottom-sheet/nested-bottom-sheet-with-list/nested-bottom-sheet-with-list.component";
import { Button } from "../button/button.component";
import { Checkbox } from "../checkbox/checkbox.component";
import { ListItem } from "../list-item/list-item.component";
import { SearchBar } from "../search-bar/search-bar.component";
import { Text } from "../text/text.component";

import { styles } from "./filter-button.style";

interface Props<TFilteringArgs extends FilteringArgs> extends Omit<IconButtonProps, "icon"> {
  filters: Filter[];
  filteringArgs?: TFilteringArgs;
  setFilteringArgs: (args?: TFilteringArgs) => void;
  title?: string;
}

// eslint-disable-next-line max-lines-per-function
export const FilterButton = <TFilteringArgs extends FilteringArgs>({
  filters,
  filteringArgs,
  setFilteringArgs,
  title,
  ...buttonProps
}: Props<TFilteringArgs>): JSX.Element => {
  const { t } = useTranslation();
  const { colors, roundness } = useTheme();
  const sheetRef = useRef<BottomSheetRefProps>(null);

  const { getFilteringArgs } = useFilteringArgs();
  const minOptionsForSearchbar = 6;
  const multipleFilters = filters.length > 1;

  const [fixedFilteringArgs] = useState(filteringArgs);
  const [searchQuery, setSearchQuery] = useState("");
  const [filtersSelected, setFiltersSelected] = useState<FilterOption[]>([]);
  const [indexFilterSelected, setIndexFilterSelected] = useState<number | undefined>(multipleFilters ? undefined : 0);

  useEffect(() => {
    setSearchQuery("");
  }, [indexFilterSelected]);

  const handleApplyFilter = (): void => {
    setSearchQuery("");
    const newFilteringArgs = filtersSelected.length ? getFilteringArgs<TFilteringArgs>(filtersSelected) : undefined;
    setFilteringArgs(
      fixedFilteringArgs || newFilteringArgs
        ? {
            ...(fixedFilteringArgs ?? ({} as TFilteringArgs)),
            ...(newFilteringArgs ?? ({} as TFilteringArgs)),
          }
        : undefined,
    );
  };

  const handleClear = (): void => {
    setSearchQuery("");
    if (multipleFilters && indexFilterSelected !== undefined) {
      setFiltersSelected(filtersSelected.filter(f => f.category !== filters[indexFilterSelected].category));
    } else {
      setFiltersSelected([]);
    }
  };

  const handleOpenBottomSheet = (): void => {
    sheetRef?.current?.open();
  };

  const getCheckboxStatus = useCallback(
    (item: FilterOption): "checked" | "unchecked" => (filtersSelected.find(f => f.option === item.option) ? "checked" : "unchecked"),
    [filtersSelected],
  );

  const handleAddFilter = useCallback(
    (newFilterSelected: FilterOption): void => {
      if (getCheckboxStatus(newFilterSelected) === "unchecked") {
        setFiltersSelected([...filtersSelected, newFilterSelected]);
      } else {
        setFiltersSelected(filtersSelected.filter(f => f.option !== newFilterSelected.option));
      }
    },
    [filtersSelected, getCheckboxStatus],
  );

  const renderCheckbox = useCallback(
    ({ item }: { item: FilterOption }): JSX.Element => (
      <ListItem
        title={item.label ?? t(`enum.${item.category}.${item.option}`)}
        subtitle={item.subLabel}
        description={item.description}
        imageProps={{
          image: item.icon,
          size: item.category === FilterCategory.stage ? 40 : item.category === FilterCategory.isCreator ? 16 : 64,
          fallbackText: (item.subLabel ?? item.label ?? item.option).charAt(0).toUpperCase(),
          style: [
            item.category === FilterCategory.stage ? { backgroundColor: colors.tertiaryContainer } : {},
            item.category === FilterCategory.category ? { borderRadius: roundness } : {},
            item.category === FilterCategory.isCreator ? { backgroundColor: "transparent" } : {},
          ],
        }}
        titleProps={{ variant: "subtitle1" }}
        subtitleProps={{ variant: "h9" }}
        descriptionProps={{ variant: "body2", color: "tertiary" }}
        contentContainerStyle={styles.contentContainerListItem}
        style={styles.checkboxContainer}
        right={<Checkbox status={getCheckboxStatus(item)} onPress={() => handleAddFilter(item)} />}
        onPress={() => handleAddFilter(item)}
      />
    ),
    [colors, getCheckboxStatus, handleAddFilter, roundness, t],
  );

  const filteredOptions = useMemo(
    () =>
      filters[indexFilterSelected ?? 0]?.options?.filter(
        item => item.label?.toLowerCase().includes(searchQuery.toLowerCase()) || item.option?.toLowerCase().includes(searchQuery.toLowerCase()),
      ),
    [filters, indexFilterSelected, searchQuery],
  );

  const clearButton = <Button onPress={handleClear}>{t("cta.clear")}</Button>;

  const counterChip = (count: number): ReactNode =>
    count > 0 ? (
      <Chip style={[styles.counter, { backgroundColor: colors.onBackground }]} textStyle={[styles.textCounter, { color: colors.background }]}>
        {count}
      </Chip>
    ) : null;

  const searchBar = useMemo(
    () =>
      Platform.OS !== "web" ? (
        <BottomSheetTextInput
          value={searchQuery}
          onChangeText={setSearchQuery}
          placeholder={t("cta.search")}
          left={color => <TextInput.Icon icon={() => <MagnifyingGlass color={color} size={24} />} />}
          style={{ backgroundColor: colors.tertiaryContainer }}
          noUnderline
        />
      ) : (
        <SearchBar value={searchQuery} onChangeText={setSearchQuery} style={{ backgroundColor: colors.tertiaryContainer }} />
      ),
    [colors.tertiaryContainer, searchQuery, t],
  );

  const buttonIcon = filtersSelected.length ? () => counterChip(filtersSelected.length) : (iconProps: IconProps) => <Funnel {...iconProps} />;

  const getOptionsList = useCallback(
    (index: number): JSX.Element => (
      <FlatList
        key={`filter-${index}`}
        data={filteredOptions}
        renderItem={renderCheckbox}
        keyExtractor={item => `checkbox-filter-option-${item.option}`}
        scrollEnabled={false}
      />
    ),
    [filteredOptions, renderCheckbox],
  );

  const getCountPerFilter = (filterCategory: FilterCategory): number => {
    const filtersGrouped: { [key in FilterCategory]?: FilterOption[] } = groupBy(filtersSelected, "category");
    return filtersGrouped[filterCategory]?.length ?? 0;
  };

  const showSearchBar = (index?: number): boolean => index !== undefined && filters[index]?.options?.length > minOptionsForSearchbar;

  const filterTitle = `${t("filter.filterBy")} ${(t(`enum.filterCategory.${filters[indexFilterSelected ?? 0]?.category}`) as string).toLowerCase()}`;

  const multipleFiltersList: ListItemProps[] = [];
  filters.forEach((filter, index) =>
    multipleFiltersList.push({
      title: (
        <View style={styles.rowIconText}>
          <Text variant="body1">{t(`enum.filterCategory.${filter.category}`)}</Text>
          {counterChip(getCountPerFilter(filter.category))}
        </View>
      ),
      nestedView: { title: filterTitle, content: getOptionsList(index) },
      key: `filter-category-${filter.category}`,
    }),
  );

  const { style: buttonStyle, ...iconButtonProps } = buttonProps;

  return (
    <>
      <IconButton
        icon={buttonIcon}
        onPress={handleOpenBottomSheet}
        style={[{ backgroundColor: colors.tertiaryContainer, borderRadius: roundness }, buttonStyle]}
        {...iconButtonProps}
      />

      {multipleFilters ? (
        <NestedBottomSheetWithList
          ref={sheetRef}
          scrollable={(isWeb && showSearchBar(indexFilterSelected)) || !isWeb}
          title={title ?? t("filter.filterItemsBy")}
          list={multipleFiltersList}
          rightHeader={clearButton}
          subHeader={showSearchBar(indexFilterSelected) ? searchBar : undefined}
          visibleNestedIndex={indexFilterSelected}
          setVisibleNestedIndex={setIndexFilterSelected}
          onDismiss={handleApplyFilter}
          onNestedViewDismiss={handleApplyFilter}
        />
      ) : (
        <BottomSheet
          ref={sheetRef}
          scrollable={(isWeb && showSearchBar(indexFilterSelected)) || !isWeb}
          title={title ?? filterTitle}
          rightHeader={clearButton}
          subHeader={showSearchBar(0) && searchBar}
          onDismiss={handleApplyFilter}>
          {getOptionsList(0)}
        </BottomSheet>
      )}
    </>
  );
};
