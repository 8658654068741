import React, { FC, useMemo } from "react";

import { useTranslation } from "react-i18next";
import { StyleProp, View, ViewStyle } from "react-native";
import { useTheme } from "react-native-paper";

import { Collab, CollabCampaignStatus, Stage } from "@app/common/graphql/generated/schema.graphql";
import { Text } from "@app/components/common/text/text.component";
import { useConfigContext } from "@app/context/config/config.context";

import { CampaignStatusChip } from "../campaign-status-chip/campaign-status-chip.component";

import { styles } from "./product-detailed-stage.style";

type ProductStatus = Stage | "inDevelopment" | "soldOut" | "closedCampaign";

interface Props {
  product: Collab;
  style?: StyleProp<ViewStyle>;
}

export const ProductDetailedStage: FC<Props> = ({ product, style }) => {
  const { colors } = useTheme();
  const { t } = useTranslation();

  const { config } = useConfigContext();
  const likeCountObjective = config?.likeCountObjective ?? 1;
  const orderCount = product.campaign?.orderCount ?? 0;
  const orderCountObjective = product.campaign?.orderCountNeeded ?? 1;

  const isOngoingCampaign = product.progress.stage === Stage.underReview && product.campaign?.status === CollabCampaignStatus.inProgress;
  const productStatus = useMemo(() => {
    if (product.progress.inDevelopment) return "inDevelopment";
    if (product.progress.stage === Stage.underReview && product.campaign?.status === CollabCampaignStatus.cancelled) return "closedCampaign";
    if (product.progress.stage === Stage.forSale && product.inventoryQuantity !== undefined && product.inventoryQuantity <= 0) return "soldOut";

    return product.progress.stage;
  }, [product.campaign?.status, product.inventoryQuantity, product.progress.inDevelopment, product.progress.stage]);

  const statusColorMap: Record<ProductStatus, string> = {
    [Stage.concept]: colors.secondary,
    [Stage.underReview]: colors.warning,
    [Stage.forSale]: colors.success,
    [Stage.contest]: colors.secondary,
    [Stage.hidden]: colors.tertiary,
    inDevelopment: colors.success,
    soldOut: colors.tertiary,
    closedCampaign: colors.tertiary,
  };

  return (
    <View style={[styles.statusContainer, style]}>
      <View style={styles.statusContainer}>
        <View style={[styles.statusBadge, { backgroundColor: statusColorMap[productStatus] }]} />
        <Text variant="caption">
          {t("products.status", {
            context: productStatus,
            fundedPercentage: product.campaign?.percentage,
            likeCount: product.formattedNumberOfLikes,
            likeCountObjective,
            orderCount,
            orderCountObjective,
          })}
        </Text>
      </View>
      {isOngoingCampaign && <CampaignStatusChip progress={product.progress} campaignStatus={product.campaign?.status} />}
    </View>
  );
};
