import { useCallback } from "react";

import { ApolloError } from "@apollo/client";
import { useTranslation } from "react-i18next";

import { isErrorCode } from "@app/common/apollo/apollo.utils";
import { AlertCta } from "@app/common/types/alert-cta.interface";
import { useConfigContext } from "@app/context/config/config.context";
import { useContactUs } from "@app/hooks/utils/use-contact-us.hook";
import { formatSeconds } from "@app/utils/duration.util";

export const invalidAddressErrorCodes = [
  "INVALID_SHIPPING_ADDRESS",
  "INVALID_ZIP_CODE",
  "INVALID_PROVINCE",
  "INVALID_STATE",
  "INVALID_REGION",
  "INVALID_CUSTOMER_TAX_LOCATION",
  "MISSING_COUNTRY",
];

export const videoErrorCodes = ["FAILED_GETTING_VIDEO_METADATA", "VIDEO_EXCEEDS_MAXIMUM_DURATION", "UNSUPPORTED_VIDEO_FORMAT"];

interface ErrorMessage {
  message?: string;
  cta?: AlertCta;
}

interface Response {
  getShippingAddressErrorMessage: (error: ApolloError) => ErrorMessage;
  getVideoErrorMessage: (error: ApolloError) => ErrorMessage;
}

export function useErrorMessage(): Response {
  const { t } = useTranslation();
  const { contactUs } = useContactUs();
  const { config } = useConfigContext();

  const maximumVideoDurationInSeconds = config?.maximumVideoDurationForProfileInSeconds || 0;

  const getShippingAddressErrorMessage = useCallback(
    (error: ApolloError) => {
      const invalidAddress = isErrorCode(error, invalidAddressErrorCodes);

      const getInvalidAddressContext = (): string | undefined => {
        if (isErrorCode(error, "INVALID_ZIP_CODE")) return "zipCode";
        if (isErrorCode(error, "INVALID_PROVINCE")) return "province";
        if (isErrorCode(error, "INVALID_STATE")) return "state";
        if (isErrorCode(error, "INVALID_REGION")) return "region";

        return undefined;
      };

      const noShippingRateAvailable = isErrorCode(error, ["NO_SHIPPING_RATE_AVAILABLE", "UNSUPPORTED_COUNTRY"]);
      const errorKey = invalidAddress ? "invalidAddress" : noShippingRateAvailable ? "noShipppingRateAvailable" : undefined;

      return {
        message: errorKey ? t(`preorder.error.${errorKey}`, { context: invalidAddress ? getInvalidAddressContext() : undefined }) : undefined,
        cta: { onPress: contactUs, label: t("cta.contactUs") },
      };
    },
    [contactUs, t],
  );

  const getVideoErrorMessage = useCallback(
    (error: ApolloError) => {
      let errorMessage = t("error.video.generic");

      if (isErrorCode(error, "FAILED_GETTING_VIDEO_METADATA")) {
        errorMessage = t("error.video.failedGettingMetadata");
      }
      if (isErrorCode(error, "VIDEO_EXCEEDS_MAXIMUM_DURATION")) {
        const maximumVideoDuration = formatSeconds(maximumVideoDurationInSeconds);
        errorMessage = t("error.video.exceedsMaximumDuration", { maximumVideoDuration });
      }
      if (isErrorCode(error, "UNSUPPORTED_VIDEO_FORMAT")) {
        errorMessage = t("error.video.invalidFormat");
      }

      return { message: errorMessage };
    },
    [maximumVideoDurationInSeconds, t],
  );

  return { getShippingAddressErrorMessage, getVideoErrorMessage };
}
