import React, { FC } from "react";

import { StyleProp, View, ViewStyle } from "react-native";

import { Badge } from "@app/common/graphql/generated/schema.graphql";
import { Text, TextProps } from "@app/components/common/text/text.component";
import { UserBadge } from "@app/components/common/user-badge/user-badge.component";

import { styles } from "./creator-name.style";

export interface CreatorNameProps {
  name: string;
  badge: Badge;
  iconSize?: number;
  textProps: Omit<TextProps, "children"> & { maxWidth?: number };
  style?: StyleProp<ViewStyle>;
}

export const CreatorName: FC<CreatorNameProps> = ({ name, badge, iconSize = 16, textProps, style }) => {
  return (
    <View style={[styles.root, style]}>
      <View style={{ maxWidth: textProps.maxWidth }}>
        <Text numberOfLines={2} {...textProps} style={[textProps.style, styles.name]}>
          {name}
        </Text>
      </View>

      <UserBadge type={badge} size={iconSize} />
    </View>
  );
};
