/* eslint-disable @typescript-eslint/naming-convention */
import React, { ReactElement, ReactNode } from "react";

import { CaretRight } from "phosphor-react-native";
import { useTranslation } from "react-i18next";
import { FlatList, ListRenderItem, View } from "react-native";
import { useTheme } from "react-native-paper";

import { Button } from "@app/components/common/button/button.component";
import { ListItem } from "@app/components/common/list-item/list-item.component";
import { useWindowDimensions } from "@app/hooks/utils/use-window-dimensions.hook";
import { conditionalItem } from "@app/utils/conditional-item-in-array.util";
import { isWeb } from "@app/utils/device.util";

import { styles } from "./home-section.style";

interface Props<T> {
  title: string;
  data?: T[];
  renderItem?: ListRenderItem<T>;
  subheader?: ReactElement;
  ListEmptyComponent?: ReactElement;
  children?: ReactNode;
  onSeeAllPress?: () => void;
  hidden?: boolean;
}

export const HomeSection = <T,>({
  title,
  data,
  renderItem,
  ListEmptyComponent,
  subheader,
  children,
  onSeeAllPress,
  hidden,
}: Props<T>): ReactElement => {
  const { t } = useTranslation();
  const { colors } = useTheme();
  const { width: screenWidth } = useWindowDimensions();

  if (hidden) return <View style={[styles.hidden, { borderColor: colors.background }]} />;

  return (
    <View style={styles.root}>
      <ListItem
        title={title}
        titleProps={{ variant: "h7" }}
        right={
          onSeeAllPress && (
            <Button size="small" icon={CaretRight} contentStyle={styles.button} labelStyle={styles.buttonLabel} onPress={onSeeAllPress}>
              {t("cta.seeAll")}
            </Button>
          )
        }
        style={styles.header}
      />
      {subheader}
      {renderItem && (
        <FlatList
          data={data}
          renderItem={renderItem}
          horizontal
          ListEmptyComponent={ListEmptyComponent}
          showsHorizontalScrollIndicator={false}
          contentContainerStyle={[conditionalItem(isWeb, { width: screenWidth }), styles.tableContainer]}
        />
      )}
      {children}
    </View>
  );
};
