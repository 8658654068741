import React, { FC } from "react";

import { FlashList, FlashListProps } from "@shopify/flash-list";
import { DimensionValue, View } from "react-native";

import { imageRatio } from "@app/common/constants/image.const";
import { Profile } from "@app/common/graphql/generated/schema.graphql";
import { Skeleton } from "@app/components/common/skeleton/skeleton.component";

import { styles } from "./profile-list-loading.style";

interface Props extends Omit<FlashListProps<Profile>, "renderItem" | "data"> {
  quantity: number;
  screenWidth: DimensionValue;
  screenHeight: DimensionValue;
  itemWidth: number;
}

export const ProfileListLoading: FC<Props> = ({ quantity, screenHeight, screenWidth, itemWidth, ...loadingFlashListProps }) => {
  return (
    <View style={{ width: screenWidth, height: screenHeight }}>
      <FlashList
        data={new Array(quantity).fill(0)}
        keyExtractor={(_item, index) => `skeleton-profile-${index}`}
        renderItem={() => (
          <View style={[styles.itemWrapper, { width: itemWidth }]}>
            <Skeleton height={itemWidth * imageRatio} width={itemWidth} radius="square" />
          </View>
        )}
        {...loadingFlashListProps}
      />
    </View>
  );
};
