import React, { FC } from "react";

import { FlashList, FlashListProps } from "@shopify/flash-list";
import { View } from "react-native";

import { videoRatio } from "@app/common/constants/video.const";
import { Profile } from "@app/common/graphql/generated/schema.graphql";
import { Skeleton } from "@app/components/common/skeleton/skeleton.component";
import { useWindowDimensions } from "@app/hooks/utils/use-window-dimensions.hook";

import { styles } from "./video-list-loading.style";

interface Props extends Omit<FlashListProps<Profile>, "renderItem" | "data"> {
  quantity: number;
  itemWidth: number;
}

export const VideoListLoading: FC<Props> = ({ quantity, itemWidth, horizontal, ...loadingFlashListProps }) => {
  const { width: screenWidth } = useWindowDimensions();

  const itemHeight = itemWidth / videoRatio;

  return (
    <View style={{ width: screenWidth }}>
      <FlashList
        data={new Array(quantity).fill(0)}
        keyExtractor={(_item, index) => `skeleton-profile-${index}`}
        renderItem={() => (
          <View style={[styles.itemWrapper, { width: itemWidth }]}>
            <Skeleton width={itemWidth} height={itemHeight} radius="square" />
          </View>
        )}
        horizontal={horizontal}
        estimatedItemSize={horizontal ? itemWidth : itemHeight}
        showsHorizontalScrollIndicator={false}
        showsVerticalScrollIndicator={false}
        {...loadingFlashListProps}
      />
    </View>
  );
};
