import React, { FC, useState } from "react";

import { View, ImageRequireSource, StyleProp, ImageStyle, ImageProps as RNImageProps } from "react-native";
import { useTheme } from "react-native-paper";
import Animated, { runOnJS, AnimatedStyle, useAnimatedStyle, useSharedValue, withTiming } from "react-native-reanimated";

import { transparent } from "@app/common/style/theme";
import { useImage } from "@app/hooks/utils/use-image.hook";
import { conditionalItem } from "@app/utils/conditional-item-in-array.util";

import { Skeleton } from "../skeleton/skeleton.component";

import { styles } from "./image.style";

export interface ImageProps extends Omit<RNImageProps, "source" | "src"> {
  source?: string | ImageRequireSource;
  imageStyle?: StyleProp<AnimatedStyle<StyleProp<ImageStyle>>>;
  disabledResponsiveImageUrl?: boolean;
}

export const Image: FC<ImageProps> = ({ source, defaultSource, style, imageStyle, disabledResponsiveImageUrl, ...props }) => {
  const { colors } = useTheme();

  const { getPreviewImageUrl, getResponsiveImageUrl, defaultImage } = useImage({ width: props.width });

  const imageOpacity = useSharedValue(0);
  const previewOpacity = useSharedValue(0);
  const [skeletonVisible, setSkeletonVisible] = useState(!!source);

  const disableResponsiveness =
    typeof source === "string" && (source.startsWith("file://") || source.startsWith("/static") || disabledResponsiveImageUrl);
  const imageSource = typeof source === "string" ? { uri: disableResponsiveness ? source : getResponsiveImageUrl(source) } : source;
  const placeholderSource = { uri: typeof source === "string" && !disableResponsiveness ? getPreviewImageUrl(source) : undefined };

  const handlePreviewLoad = (): void => {
    previewOpacity.value = withTiming(1, {}, finished => {
      if (finished) runOnJS(setSkeletonVisible)(false);
    });
  };

  const handleImageLoad = (): void => {
    imageOpacity.value = withTiming(1, {}, finished => {
      if (finished) runOnJS(setSkeletonVisible)(false);
    });
  };

  const animatedPreviewStyle = useAnimatedStyle(() => {
    return {
      opacity: previewOpacity.value,
    };
  });

  const animatedImageStyle = useAnimatedStyle(() => {
    return {
      opacity: imageOpacity.value,
    };
  });

  return (
    <View
      style={[{ backgroundColor: !source ? colors.background : transparent }, styles.container, { width: props.width, height: props.height }, style]}>
      <Skeleton
        show={skeletonVisible}
        width="100%"
        height="100%"
        radius={style && "borderRadius" in style ? (style.borderRadius as number) : "square"}
      />

      {source && (
        <Animated.Image
          source={placeholderSource}
          style={[styles.overlayImage, animatedPreviewStyle, style, imageStyle]}
          fadeDuration={200}
          onLoadEnd={handlePreviewLoad}
          {...props}
        />
      )}

      <Animated.Image
        source={imageSource ?? defaultSource ?? defaultImage}
        style={[styles.overlayImage, animatedImageStyle, ...conditionalItem(!source, styles.defaultImage), style, imageStyle]}
        fadeDuration={200}
        onLoadEnd={handleImageLoad}
        {...props}
      />
    </View>
  );
};
