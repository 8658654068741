import { useCallback } from "react";

import { Routes, Tabs, useNavigation } from "@app/common/types/navigation.type";
import { FeatureFlag } from "@app/context/launch-darkly/feature-flag.enum";
import { useLaunchDarklyContext } from "@app/context/launch-darkly/launch-darkly.context";

interface Response {
  navigateToMainScreen: (action?: "navigate" | "replace" | "push") => void;
  navigateToNotificationsScreen: () => void;
}

export const useNewHomeNavigation = (): Response => {
  const navigation = useNavigation();
  const { isFeatureEnabled } = useLaunchDarklyContext();

  const isNewHomePageEnabled = isFeatureEnabled(FeatureFlag.enableNewHomePage);

  const navigateToMainScreen = useCallback(
    (action: "navigate" | "replace" | "push" = "navigate"): void => {
      if (isNewHomePageEnabled) {
        if (action === "replace") {
          navigation.replace(Routes.root, { screen: Tabs.home, params: { screen: Routes.home } });
        } else if (action === "push") {
          navigation.push(Routes.root, { screen: Tabs.home, params: { screen: Routes.home } });
        } else {
          navigation.navigate(Routes.root, { screen: Tabs.home, params: { screen: Routes.home } });
        }
      } else {
        if (action === "replace") {
          navigation.replace(Routes.root, { screen: Tabs.feed, params: { screen: Routes.feed } });
        } else if (action === "push") {
          navigation.push(Routes.root, { screen: Tabs.feed, params: { screen: Routes.feed } });
        } else {
          navigation.navigate(Routes.root, { screen: Tabs.feed, params: { screen: Routes.feed } });
        }
      }
    },
    [isNewHomePageEnabled, navigation],
  );

  const navigateToNotificationsScreen = useCallback(() => {
    if (isNewHomePageEnabled) navigation.navigate(Routes.root, { screen: Tabs.home, params: { screen: Routes.notifications } });
    else navigation.navigate(Routes.root, { screen: Tabs.feed, params: { screen: Routes.notifications } });
  }, [isNewHomePageEnabled, navigation]);

  return { navigateToMainScreen, navigateToNotificationsScreen };
};
