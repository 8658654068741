import { StyleSheet } from "react-native";

import { imageRatio } from "@app/common/constants/image.const";

export const defaultRowGap = 8;
export const paddingVerticalGoals = 8;
export const statusChipBackground = "rgba(29, 29, 29, 0.5)";

export const styles = StyleSheet.create({
  buttonsContainer: {
    rowGap: 8,
  },
  content: {
    flex: 1,
    margin: 8,
    rowGap: defaultRowGap,
  },
  creatorName: {
    padding: 8,
    paddingTop: 0,
  },
  detailedStage: {
    alignItems: "flex-start",
    flexDirection: "column",
    rowGap: defaultRowGap,
  },
  hiddenIndicatorContainer: {
    columnGap: 4,
    flexDirection: "row",
  },
  image: {
    aspectRatio: imageRatio,
  },
  likeButton: {
    position: "absolute",
    right: 0,
    top: 0,
  },
  namesWithGoalsContainer: {
    rowGap: defaultRowGap,
  },
  root: {
    flex: 1,
    marginBottom: 8,
  },
  soldOutChip: {
    borderRadius: 64,
  },
  statusChip: {
    borderRadius: 0,
    bottom: 0,
    left: 0,
    paddingHorizontal: 1,
    paddingVertical: 4,
    position: "absolute",
  },
});
