/* eslint-disable complexity */
import React, { FC, useEffect, useMemo } from "react";

import { t } from "i18next";
import { CaretRight, Info } from "phosphor-react-native";
import { Trans } from "react-i18next";
import { TouchableOpacity, View } from "react-native";
import { ActivityIndicator, Divider, useTheme } from "react-native-paper";

import { Collab } from "@app/common/graphql/generated/schema.graphql";
import { ChildrenProp } from "@app/common/types/children-prop.interface";
import { Routes, useNavigation } from "@app/common/types/navigation.type";
import { ListItem } from "@app/components/common/list-item/list-item.component";
import { Text } from "@app/components/common/text/text.component";
import { usePreorderContext } from "@app/context/preorder/preorder.context";
import { useSnackbarContext } from "@app/context/snackbar/snackbar.context";
import { useSupercoinLimitForCreatePreorder } from "@app/hooks/api/supercoins/use-supercoin-limit-for-create-preorder.hook";
import { useCollabVariants } from "@app/hooks/api/use-collab-variants.hook";
import { getTitleCase } from "@app/utils/convert-to-title-case.util";
import { formatPriceUSD } from "@app/utils/price.util";

import { ReferralRewardAlert } from "../../supercoins/referral-reward-alert/referral-reward-alert.component";
import { AddPaymentMethodButton } from "../add-payment-method-button/add-payment-method-button.component";
import { EnterEmailForPreorder } from "../enter-email-for-preorder/enter-email-for-preorder.component";
import { EnterRewardsInput } from "../enter-rewards-input/enter-rewards-input.component";
import { PaymentMethodList } from "../payment-method-list/payment-method-list.component";
import { PaymentMethodListMode } from "../payment-method-list/payment-method-list.types";
import { ShippingAddress } from "../shipping-address/shipping-address.component";

import { styles } from "./preorder-confirmation.style";
import { PreorderTotal } from "./preorder-total/preorder-total.component";

interface Props {
  product?: Collab;
}

export const PreorderConfirmation: FC<Props> = ({ product }) => {
  const { colors, roundness } = useTheme();
  const { showErrorSnackbar } = useSnackbarContext();
  const navigation = useNavigation();

  const {
    input: { collabId, variantId, quantity, paymentMethodId, shippingAddress, orderId, supercoinsUsed },
    isAnonymous,
    loadingShippingAddress,
    loadingAdditionalCharges,
    salesTax,
    shippingRate,
    paymentMethods,
    loadingPaymentMethods,
    setInput,
  } = usePreorderContext();

  const hasPaymentMethods = !!paymentMethods?.length;

  const { data: supercoinLimitData, loading: loadingSupercoinLimit } = useSupercoinLimitForCreatePreorder({
    variables: { input: { variantId, quantity } },
    skip: isAnonymous,
  });

  const { data: variants, refetch: refetchVariants } = useCollabVariants({
    variables: { where: { collabIds: [collabId] } },
    onError: err => showErrorSnackbar({ refetch: refetchVariants, error: err }),
  });

  const availableSupercoins = supercoinLimitData?.balance || 0;
  const supercoinLimitWithCurrentBalance = supercoinLimitData?.limitWithCurrentBalance || 0;
  const supercoinLimitWithInfiniteBalance = supercoinLimitData?.limitWithInfiniteBalance || 0;

  const variant = useMemo(
    () =>
      variants?.nodes
        ?.find(collabVariants => collabVariants.collabId === collabId)
        ?.variants.find(collabVariant => collabVariant.variantId === variantId),
    [collabId, variantId, variants?.nodes],
  );

  useEffect(() => {
    if (variants && (!variant || quantity > variant.inventoryQuantity)) {
      showErrorSnackbar();
      navigation.navigate(Routes.product, { id: collabId });
    }
  }, [navigation, collabId, showErrorSnackbar, variant, variants, quantity]);

  const productSubtitle = useMemo(() => {
    const descriptors = [];
    if (variant?.size) descriptors.push(getTitleCase(variant.size));
    if (variant?.color) descriptors.push(getTitleCase(variant.color));
    if (variant?.material) descriptors.push(getTitleCase(variant.material));
    if (variant?.style) descriptors.push(getTitleCase(variant.style));
    descriptors.push(`${t("preorder.quantityLabel")} ${quantity}`);

    return descriptors.join(" | ");
  }, [quantity, variant]);

  const paymentMethodListProps: PaymentMethodList = isAnonymous
    ? {
        mode: PaymentMethodListMode.uniqueSelected,
        paymentMethods: paymentMethods ?? [],
        setAnonymousCustomerId: (id: string) => setInput(prev => ({ ...prev, anonymousCustomerId: id })),
      }
    : {
        mode: PaymentMethodListMode.selectable,
        paymentMethods: paymentMethods ?? [],
        selectedPaymentMethodId: paymentMethodId,
        setSelectedPaymentMethodId: (id: string) => setInput(prev => ({ ...prev, paymentMethodId: id })),
      };

  return (
    <View style={styles.rootContainer}>
      {product && variants ? (
        <ListItem
          title={product.name}
          titleProps={{ variant: "h9" }}
          subtitle={productSubtitle}
          subtitleProps={{ variant: "caption" }}
          description={t("preorder.creatorCaption", { name: product.creator.displayName })}
          descriptionProps={{ variant: "caption", color: "tertiary" }}
          imageProps={{ image: product.images.length ? product.images[0].imageUrl : undefined, style: styles.productImage }}
          contentContainerStyle={styles.title}
        />
      ) : (
        <ActivityIndicator size="small" />
      )}

      <ListItem
        title={t("preorder.productMightChangeDisclaimer")}
        titleProps={{ variant: "body2", numberOfLines: 0 }}
        left={<Info color={colors.onBackground} size={24} />}
        style={[styles.alert, { backgroundColor: colors.action.selected, borderRadius: roundness }]}
      />

      <Divider />

      {isAnonymous && (
        <>
          <View style={styles.subsectionContainer}>
            <Text variant="body1">{t("preorder.emailStep")}</Text>
            <EnterEmailForPreorder />
          </View>

          <Divider />
        </>
      )}

      <View style={styles.subsectionContainer}>
        <Text variant="body1">{t("preorder.shippingAddressStep", { stepNumber: isAnonymous ? 2 : 1 })}</Text>
        {loadingShippingAddress ? (
          <ActivityIndicator size="small" />
        ) : (
          <ShippingAddress
            shippingAddress={shippingAddress}
            setShippingAddress={addr => setInput(prev => ({ ...prev, shippingAddress: addr }))}
            onReservePage
            disabled={!!orderId}
          />
        )}
      </View>

      <Divider />

      <View style={styles.subsectionContainer}>
        <TouchableOpacity disabled={!hasPaymentMethods || isAnonymous} onPress={() => navigation.navigate(Routes.managePaymentMethods)}>
          <View style={styles.spaceBetweenText}>
            <Text variant="body1">{t("preorder.paymentMethodStep", { stepNumber: isAnonymous ? 3 : 2 })}</Text>
            {hasPaymentMethods && !isAnonymous && <CaretRight color={colors.primary} />}
          </View>
        </TouchableOpacity>
        {loadingPaymentMethods ? (
          <ActivityIndicator size="small" />
        ) : hasPaymentMethods ? (
          <PaymentMethodList {...paymentMethodListProps} />
        ) : (
          <AddPaymentMethodButton
            setAnonymousCustomerId={id => setInput(prev => ({ ...prev, anonymousCustomerId: id }))}
            mode="contained"
            size="large"
            shippingAddress={shippingAddress}
          />
        )}
      </View>

      <Divider />

      {!isAnonymous && (
        <>
          <View style={styles.subsectionContainer}>
            <Trans
              i18nKey="preorder.enterRewardsStep"
              values={{
                supercoinsBalance: formatPriceUSD(availableSupercoins, { withoutUSD: true }),
                limit: formatPriceUSD(supercoinLimitWithInfiniteBalance, { withoutUSD: true }),
              }}
              parent={({ children }: ChildrenProp) => <Text variant="body1">{children}</Text>}
              components={{
                gray: (
                  <Text variant="body1" color="tertiary">
                    <></>
                  </Text>
                ),
              }}
            />
            {loadingSupercoinLimit ? (
              <ActivityIndicator size="small" />
            ) : (
              <>
                <EnterRewardsInput availableSupercoins={availableSupercoins} supercoinLimit={supercoinLimitWithCurrentBalance} disabled={!!orderId} />
                <ReferralRewardAlert />
              </>
            )}
          </View>

          <Divider />
        </>
      )}

      {product && variant && product.progress.reviewEndAt && !loadingAdditionalCharges ? (
        <PreorderTotal
          salesTax={salesTax?.tax}
          shippingPrice={shippingRate?.price}
          subtotal={variant.price * quantity}
          rewards={supercoinsUsed ?? 0}
          reviewEndAt={product.progress.reviewEndAt}
          isAnonymous={isAnonymous}
        />
      ) : (
        <ActivityIndicator size="small" />
      )}
    </View>
  );
};
