import { StyleSheet } from "react-native";

export const styles = StyleSheet.create({
  badge: {
    flex: 1,
  },
  name: {
    flexShrink: 1,
  },
  root: {
    alignItems: "center",
    columnGap: 1,
    flexDirection: "row",
  },
});
