import { FetchResult, gql, MutationFunctionOptions, useMutation } from "@apollo/client";

import { featureAnnouncementFields } from "@app/common/graphql/fragments.graphql";
import { FeatureAnnouncement } from "@app/common/graphql/generated/schema.graphql";
import { MutationResult } from "@app/common/types/apollo-result.type";

interface Response {
  markFeatureAnnouncementAsSeen: FeatureAnnouncement;
}

interface Variables {
  featureAnnouncementId: string;
  userId: string;
}

interface MarkFeatureAnnouncementAsSeenResult extends MutationResult<Response, "markFeatureAnnouncementAsSeen"> {
  markFeatureAnnouncementAsSeen: (options?: MutationFunctionOptions<Response, Variables>) => Promise<FetchResult>;
}

const markFeatureAnnouncementAsSeenMutation = gql`
  mutation MarkFeatureAnnouncementAsSeen($featureAnnouncementId: FeatureAnnouncementId!, $userId: UserId!) {
    markFeatureAnnouncementAsSeen(input: { featureAnnouncementId: $featureAnnouncementId }, userId: $userId) {
      ...FeatureAnnouncementFields
    }
  }
  ${featureAnnouncementFields}
`;

export function useMarkFeatureAnnouncementAsSeen(): MarkFeatureAnnouncementAsSeenResult {
  const [markFeatureAnnouncementAsSeen, { loading, error, data }] = useMutation<Response, Variables>(markFeatureAnnouncementAsSeenMutation);

  return { markFeatureAnnouncementAsSeen, loading, error, data: data?.markFeatureAnnouncementAsSeen };
}
