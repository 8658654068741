import React, { FC } from "react";

import { ArrowLineUpRight } from "phosphor-react-native";
import { useTranslation } from "react-i18next";
import { List } from "react-native-paper";

import { links } from "@app/common/constants/links.const";
import { ProfileStackScreenProps, Routes } from "@app/common/types/navigation.type";
import { ScreenWrapper } from "@app/components/common/screen-wrapper/screen-wrapper.component";
import { useNavigateToWebview } from "@app/hooks/utils/use-navigate-to-webview.hook";

export const AboutScreen: FC<ProfileStackScreenProps<Routes.about>> = () => {
  const { t } = useTranslation();
  const { navigateToWebview } = useNavigateToWebview();

  return (
    <ScreenWrapper>
      <List.Item
        title={t("settings.about.privacyPolicy")}
        right={props => <ArrowLineUpRight {...props} weight="thin" />}
        onPress={() => navigateToWebview(links.external.privacyPolicy, t("settings.about.privacyPolicy"))}
      />
      <List.Item
        title={t("settings.about.termsOfUse")}
        right={props => <ArrowLineUpRight {...props} weight="thin" />}
        onPress={() => navigateToWebview(links.external.termsOfUse, t("settings.about.termsOfUse"))}
      />
    </ScreenWrapper>
  );
};
