import React, { FC } from "react";

import { formatDate } from "date-fns";
import { ChatCenteredDots } from "phosphor-react-native";
import { useTranslation } from "react-i18next";
import { TouchableHighlight, View, ViewStyle } from "react-native";
import { useTheme } from "react-native-paper";

import { CollabUpdate } from "@app/common/graphql/generated/schema.graphql";
import { Routes, useNavigation } from "@app/common/types/navigation.type";
import { AnimatedLikeIcon } from "@app/components/common/animated-like-icon/animated-like-icon.component";
import { AvatarImage } from "@app/components/common/avatar-image/avatar-image.component";
import { ListItem } from "@app/components/common/list-item/list-item.component";
import { Text } from "@app/components/common/text/text.component";
import { useProfileContext } from "@app/context/profile/profile.context";

import { CommentsButton } from "../../products/comments/comments-button/comments-button.component";
import { LikeUpdateButton } from "../like-update-button/like-update-button.component";

import { styles } from "./update-list-item.style";

interface Props extends Pick<ViewStyle, "width"> {
  update: CollabUpdate;
  index?: number;
}

export const UpdateListItem: FC<Props> = ({ update, index, width }) => {
  const { t } = useTranslation();
  const { colors } = useTheme();
  const { profile } = useProfileContext();
  const navigation = useNavigation();

  const handleOpenDetails = (): void =>
    navigation.navigate(Routes.productUpdateStack, { screen: Routes.productUpdate, params: { updateId: update.collabUpdateId, index } });

  const imageUrl = update.images[0]?.imageUrl;
  const imageSize = 40;

  return (
    <TouchableHighlight activeOpacity={1} underlayColor={colors.action.selected} onPress={handleOpenDetails} style={styles.rootWrapper}>
      <View style={[styles.root, { backgroundColor: colors.tertiaryContainer, width }]}>
        <View style={styles.rowSpaceBetween}>
          {index != null && (
            <Text variant="caption" color="tertiary">
              {t("productDetails.updates.updateWithIndex", { index: index + 1 })}
            </Text>
          )}
          <Text variant="caption" color="tertiary">
            {formatDate(update.createdAt, t("dateFormat.date"))}
          </Text>
        </View>

        <ListItem
          title={update.title}
          titleProps={{ variant: "h8", numberOfLines: 2 }}
          subtitle={update.content}
          subtitleProps={{ variant: "body2", color: "tertiary", numberOfLines: 4 }}
          right={
            imageUrl ? (
              <AvatarImage image={imageUrl} size={imageSize} fallbackText="" style={styles.updateImage} />
            ) : (
              <View style={{ width: imageSize, height: imageSize }} />
            )
          }
          textContainerStyle={styles.textContainer}
          contentContainerStyle={styles.updateListItemContentContainer}
          onPress={handleOpenDetails}
        />

        <View style={styles.buttonsRowWrapper}>
          <CommentsButton
            parentId={update.collabUpdateId}
            numberOfComments={update.formattedNumberOfComments ?? "0"}
            icon={iconProps => (
              <View style={styles.buttonWithCountRow}>
                <Text variant="body2" color="tertiary">
                  {update.formattedNumberOfComments}
                </Text>

                <ChatCenteredDots {...iconProps} size={20} />
              </View>
            )}
            iconColor={colors.tertiary}
          />
          <LikeUpdateButton
            collabUpdateId={update.collabUpdateId}
            liked={update.liked}
            buttonComponent={({ onPress }) => (
              <TouchableHighlight activeOpacity={1} underlayColor={colors.action.selected} disabled={!profile} onPress={onPress}>
                <View style={styles.buttonWithCountRow}>
                  <Text variant="body2" color="tertiary">
                    {update.formattedLikeCount}
                  </Text>

                  <AnimatedLikeIcon liked={!!update.liked} size={20} floating />
                </View>
              </TouchableHighlight>
            )}
          />
        </View>
      </View>
    </TouchableHighlight>
  );
};
