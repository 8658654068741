import React, { FC, useEffect, useMemo, useState } from "react";

import { NetworkStatus } from "@apollo/client";
import { FlashList, FlashListProps } from "@shopify/flash-list";
import { useTranslation } from "react-i18next";
import { FlatList, View } from "react-native";
import { ActivityIndicator, useTheme } from "react-native-paper";

import { FilterCategory } from "@app/common/enums/filter-category.enum";
import { Collab, ContestResult } from "@app/common/graphql/generated/schema.graphql";
import { HomeStackScreenProps, Routes } from "@app/common/types/navigation.type";
import { Button } from "@app/components/common/button/button.component";
import { EmptyState } from "@app/components/common/empty-state/empty-state.component";
import { FilterButton } from "@app/components/common/filter-button/filter-button.component";
import { ScreenWrapper } from "@app/components/common/screen-wrapper/screen-wrapper.component";
import { HomeSectionType, HomeSortByType } from "@app/components/home/home.type";
import { EarlyBirdBanner } from "@app/components/products/early-bird-discounts-banner/early-bird-discounts-banner.component";
import { ProductListEmpty } from "@app/components/products/product-list/product-list-empty/product-list-empty.component";
import ProductListItem from "@app/components/products/product-list/product-list-item/product-list-item.component";
import { useCategories } from "@app/hooks/api/use-categories.hook";
import { useHomeCollabSectionData } from "@app/hooks/utils/home/use-home-collab-section-data.hook";
import { useHomeSections } from "@app/hooks/utils/home/use-home-sections.hook";
import { useSeeAllSortingMethods } from "@app/hooks/utils/home/use-see-all-sorting-methods.hook";
import { useFetchMoreItems } from "@app/hooks/utils/use-fetch-more-items.hook";
import { useResponsiveWidthListItem } from "@app/hooks/utils/use-responsive-width-list-item.hook";

import { styles } from "./see-all-collabs.style";

type CategoriesSelected = { [FilterCategory.category]: string[] };

export const SeeAllCollabsScreen: FC<HomeStackScreenProps<Routes.seeAllCollabs>> = ({ route, navigation }) => {
  const { t } = useTranslation();
  const { colors } = useTheme();
  const { itemWidth, listPadding, nbColumns } = useResponsiveWidthListItem();

  const { type, sortBy } = route.params;

  const estimatedUnderImageContentHeight = 40;

  const [selectedCategories, setSelectedCategories] = useState<CategoriesSelected>({
    [FilterCategory.category]: [],
  });
  const [selectedSortingMethod, setSelectedSortingMethod] = useState<HomeSortByType | undefined>(sortBy);

  const { data: categories } = useCategories();
  const { filters } = useSeeAllSortingMethods(type);
  const { homeSections } = useHomeSections();
  const { data, loading, fetchMore, networkStatus, error } = useHomeCollabSectionData({
    type,
    sortBy: selectedSortingMethod,
    filterByCategories: selectedCategories[FilterCategory.category],
  });

  const { fetchMoreItems } = useFetchMoreItems(type, fetchMore);

  const handleFetchMore = (): void => {
    if (data?.nodes?.length && fetchMoreItems && !error && data?.pageInfo.hasNextPage) {
      void fetchMoreItems({ offset: data.nodes.length });
    }
  };

  useEffect(() => {
    navigation.setOptions({ title: homeSections.find(section => section.type === type)?.title });
  }, [homeSections, navigation, type]);

  const commonFlashListProps: Omit<FlashListProps<Collab | ContestResult>, "renderItem" | "data"> = useMemo(
    () => ({
      numColumns: nbColumns,
      contentContainerStyle: { padding: listPadding },
      showsVerticalScrollIndicator: false,
      estimatedItemSize: itemWidth + estimatedUnderImageContentHeight,
    }),
    [itemWidth, listPadding, nbColumns],
  );

  const emptyList = useMemo(
    () => (
      <ProductListEmpty
        loading={loading}
        loadingFlashListProps={commonFlashListProps}
        noProducts={!data?.nodes?.length && !loading}
        emptyStateWhenNoProducts={<EmptyState message={t("noResults.default")} fullScreen style={styles.emptyState} />}
        noProductsAfterFiltering={false}
        itemWidth={itemWidth}
      />
    ),
    [commonFlashListProps, data?.nodes?.length, itemWidth, loading, t],
  );

  return (
    <ScreenWrapper>
      {type === HomeSectionType.underReviewCollabs && <EarlyBirdBanner />}
      <View style={styles.header}>
        {categories?.nodes?.length && (
          <FilterButton
            filters={[
              {
                category: FilterCategory.category,
                options: categories.nodes?.map(category => ({
                  option: category.categoryId,
                  category: FilterCategory.category,
                  label: category.name,
                  icon: category.imageUrl,
                })),
              },
            ]}
            filteringArgs={selectedCategories}
            setFilteringArgs={setSelectedCategories as (args: CategoriesSelected | undefined) => void}
          />
        )}
        <FlatList
          data={filters}
          renderItem={({ item }) => (
            <Button
              key={item.value}
              mode="contained"
              onPress={() => setSelectedSortingMethod(selectedSortingMethod === item.value ? undefined : item.value)}
              textColor={selectedSortingMethod !== item.value ? colors.onTertiaryContainer : undefined}
              buttonColor={selectedSortingMethod !== item.value ? colors.tertiaryContainer : undefined}
              style={styles.button}>
              {item.label}
            </Button>
          )}
          horizontal
          showsHorizontalScrollIndicator={false}
          contentContainerStyle={styles.contentContainerFilters}
        />
      </View>

      <FlashList
        data={data?.nodes}
        renderItem={({ item }) => <ProductListItem product={item} width={itemWidth} />}
        onEndReached={handleFetchMore}
        onEndReachedThreshold={0.1}
        scrollEnabled
        ListFooterComponent={networkStatus === NetworkStatus.fetchMore ? <ActivityIndicator size="small" /> : undefined}
        ListEmptyComponent={emptyList}
        {...commonFlashListProps}
      />
    </ScreenWrapper>
  );
};
