import React, { FC, useEffect, useState } from "react";

import { useTranslation } from "react-i18next";
import { View } from "react-native";

import { Collab, CollabImage } from "@app/common/graphql/generated/schema.graphql";
import { useNavigation } from "@app/common/types/navigation.type";
import { Skeleton } from "@app/components/common/skeleton/skeleton.component";
import { ProductDetails } from "@app/components/products/product-details/product-details.component";
import { ProductImages } from "@app/components/products/product-images/product-images.component";

import { ProductSideIconButtons } from "../product-side-icon-buttons/product-side-icon-buttons.component";

import { styles } from "./product.style";

interface Props {
  product?: Collab;
}

export const Product: FC<Props> = ({ product }) => {
  const { t } = useTranslation();
  const navigation = useNavigation();

  const [currentImage, setCurrentImage] = useState<CollabImage | undefined>(product?.images[0]);

  useEffect(() => {
    if (!product) return;
    navigation.setOptions({
      title: t(`enum.stage.${product.progress.stage}`),
    });
  }, [navigation, product, t]);

  const contentOnImage = product && (
    <View style={styles.imageContentContainer}>
      <ProductSideIconButtons product={product} currentImage={currentImage} context="fullScreen" />
    </View>
  );

  return (
    <View>
      <ProductImages
        collabId={product?.collabId ?? ""}
        images={product?.images ?? []}
        loading={!product}
        setCurrentImage={setCurrentImage}
        contentOnImage={contentOnImage}
      />

      {product ? (
        <ProductDetails product={product} />
      ) : (
        <View style={styles.creatorSectionSkeleton}>
          <Skeleton radius="round" height={40} width={40} />
          <Skeleton radius="round" height={20} width="90%" />
        </View>
      )}
    </View>
  );
};
