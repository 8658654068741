import React, { FC, useEffect } from "react";

import { ActivityIndicator } from "react-native-paper";

import { NavigationProps, Routes } from "@app/common/types/navigation.type";
import { ScreenWrapper } from "@app/components/common/screen-wrapper/screen-wrapper.component";
import { ShippingAddress } from "@app/components/preorders/shipping-address/shipping-address.component";
import { useProfileContext } from "@app/context/profile/profile.context";
import { useSnackbarContext } from "@app/context/snackbar/snackbar.context";
import { useShippingAddress } from "@app/hooks/api/preorder/use-shipping-address.hook";
import { useNewHomeNavigation } from "@app/hooks/utils/use-new-home-navigation.hook";

import { styles } from "./manage-shipping-address.style";

export const ManageShippingAddressScreen: FC<NavigationProps<Routes.manageShippingAddress>> = () => {
  const { profile, loading: loadingProfile } = useProfileContext();
  const { showErrorSnackbar } = useSnackbarContext();

  const { navigateToMainScreen } = useNewHomeNavigation();

  useEffect(() => {
    if (!loadingProfile && !profile) {
      navigateToMainScreen();
    }
  }, [navigateToMainScreen, loadingProfile, profile]);

  const {
    data: shippingAddress,
    loading: loadingShippingAddress,
    refetch: refetchShippingAddress,
  } = useShippingAddress({
    variables: { userId: profile?.userId },
    onError: err => showErrorSnackbar({ refetch: refetchShippingAddress, error: err }),
  });

  return (
    <ScreenWrapper withScrollView contentContainerStyle={styles.root}>
      {loadingShippingAddress ? <ActivityIndicator size="large" /> : <ShippingAddress shippingAddress={shippingAddress} />}
    </ScreenWrapper>
  );
};
