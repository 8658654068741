import React, { FC, JSX } from "react";

import { FlashListProps } from "@shopify/flash-list";
import { useTranslation } from "react-i18next";
import { View } from "react-native";

import { Collab, ContestResult } from "@app/common/graphql/generated/schema.graphql";
import { Text } from "@app/components/common/text/text.component";
import { useWindowDimensions } from "@app/hooks/utils/use-window-dimensions.hook";

import { ProductListLoading } from "../product-list-loading/product-list-loading.component";

import { styles } from "./product-list-empty.style";

interface Props {
  loading: boolean;
  loadingFlashListProps: Omit<FlashListProps<Collab | ContestResult>, "renderItem" | "data">;
  noProducts: boolean;
  emptyStateWhenNoProducts?: JSX.Element;
  noProductsAfterFiltering: boolean;
  itemWidth: number;
}

export const ProductListEmpty: FC<Props> = ({
  loading,
  noProducts,
  emptyStateWhenNoProducts,
  noProductsAfterFiltering,
  itemWidth,
  loadingFlashListProps,
}) => {
  const { width: screenWidth, height: screenHeight } = useWindowDimensions();

  const { t } = useTranslation();

  const noResultState = (
    <View style={[styles.root, styles.center]}>
      <Text variant="body2" color="tertiary" textAlign="center">
        {t("noResults.default")}
      </Text>
    </View>
  );

  if (loading)
    return (
      <ProductListLoading quantity={10} screenHeight={screenHeight} screenWidth={screenWidth} itemWidth={itemWidth} {...loadingFlashListProps} />
    );

  if (noProducts) return <View style={styles.root}>{emptyStateWhenNoProducts}</View>;

  if (noProductsAfterFiltering) return noResultState;

  return null;
};
