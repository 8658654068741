import { ApolloError, ApolloQueryResult, NetworkStatus } from "@apollo/client";

import { nbProductsPerFetch } from "@app/common/constants/products.const";
import {
  ConceptsSortingMethod,
  ForSaleCollabsSortingMethod,
  InDevelopmentCollabsSortingMethod,
  PaginatedCollabs,
  UnderReviewCollabsFilteringArgs,
  UnderReviewCollabsSortingMethod,
} from "@app/common/graphql/generated/schema.graphql";
import { FetchMoreArgs } from "@app/common/types/fetch-more-args.type";
import { HomeCollabSectionType, HomeSectionType } from "@app/components/home/home.type";
import { useConcepts } from "@app/hooks/api/home/use-concepts.hook";
import { useForSaleCollabs } from "@app/hooks/api/home/use-for-sale-collabs.hook";
import { useInDevelopmentCollabs } from "@app/hooks/api/home/use-in-development-collabs.hook";
import { usePurchaseableCollabs } from "@app/hooks/api/home/use-purchaseable-collabs.hook";
import { useUnderReviewCollabs } from "@app/hooks/api/home/use-under-review-collabs.hook";
import { removeUndefinedFromObject } from "@app/utils/object.util";

interface Response {
  data: PaginatedCollabs | undefined;
  loading: boolean;
  error: ApolloError | undefined;
  networkStatus: NetworkStatus | undefined;
  fetchMore?: (args: FetchMoreArgs<Response>) => Promise<ApolloQueryResult<Response>> | undefined;
}

interface CommonParams {
  type: HomeCollabSectionType;
  filterByCategories: string[];
}

interface ConceptParams extends CommonParams {
  type: HomeSectionType.concepts;
  sortBy: ConceptsSortingMethod;
}
interface ForSaleParams extends CommonParams {
  type: HomeSectionType.forSaleCollabs;
  sortBy: ForSaleCollabsSortingMethod;
}
interface OnGoingCampaignsParams extends CommonParams {
  type: HomeSectionType.underReviewCollabs;
  sortBy: UnderReviewCollabsSortingMethod;
}
interface ContinueShoppingParams extends CommonParams {
  type: HomeSectionType.purchaseableCollabs;
  sortBy: undefined;
}
interface SuccessfulCampaignsParams extends CommonParams {
  type: HomeSectionType.inDevelopmentCollabs;
  sortBy: InDevelopmentCollabsSortingMethod;
}

type Props = ConceptParams | ForSaleParams | OnGoingCampaignsParams | ContinueShoppingParams | SuccessfulCampaignsParams;

const defaultResponse = { data: undefined, loading: false, networkStatus: undefined, error: undefined };

export const useHomeCollabSectionData = ({ type, sortBy, filterByCategories }: Props): Response => {
  const {
    data: concepts,
    loading: conceptsLoading,
    fetchMore: conceptsFetchMore,
    networkStatus: conceptsNetworkStatus,
    error: conceptsError,
  } = useConcepts({
    variables: {
      first: nbProductsPerFetch,
      sortBy: type === HomeSectionType.concepts ? sortBy : ConceptsSortingMethod.trending,
      where: filterByCategories?.length ? { categoryIds: filterByCategories } : undefined,
    },
    skip: type !== HomeSectionType.concepts,
  });

  const {
    data: forSaleCollabs,
    loading: forSaleCollabsLoading,
    fetchMore: forSaleCollabsFetchMore,
    networkStatus: forSaleCollabsNetworkStatus,
    error: forSaleCollabsError,
  } = useForSaleCollabs({
    variables: {
      first: nbProductsPerFetch,
      sortBy: type === HomeSectionType.forSaleCollabs ? sortBy : ForSaleCollabsSortingMethod.newest,
      where: filterByCategories?.length ? { categoryIds: filterByCategories } : undefined,
    },
    skip: type !== HomeSectionType.forSaleCollabs,
  });

  const {
    data: inDevelopmentCollabs,
    loading: inDevelopmentCollabsLoading,
    fetchMore: inDevelopmentCollabsFetchMore,
    networkStatus: inDevelopmentCollabsNetworkStatus,
    error: inDevelopmentCollabsError,
  } = useInDevelopmentCollabs({
    variables: {
      first: nbProductsPerFetch,
      sortBy: type === HomeSectionType.inDevelopmentCollabs ? sortBy : InDevelopmentCollabsSortingMethod.newest,
      where: filterByCategories?.length ? { categoryIds: filterByCategories } : undefined,
    },
    skip: type !== HomeSectionType.inDevelopmentCollabs,
  });

  const {
    data: purchaseableCollabs,
    loading: purchaseableCollabsLoading,
    fetchMore: purchaseableCollabsFetchMore,
    networkStatus: purchaseableCollabsNetworkStatus,
    error: purchaseableCollabsError,
  } = usePurchaseableCollabs({
    variables: { first: nbProductsPerFetch },
    skip: type !== HomeSectionType.purchaseableCollabs,
  });

  const underReviewSortBy = type === HomeSectionType.underReviewCollabs ? sortBy : UnderReviewCollabsSortingMethod.trending;
  let underReviewWhere: UnderReviewCollabsFilteringArgs = {
    categoryIds: filterByCategories?.length ? filterByCategories : undefined,
    notPastEndDate: undefined,
    goalNotAchieved: undefined,
  };
  if (underReviewSortBy === UnderReviewCollabsSortingMethod.closestToCampaignEnd) {
    underReviewWhere.notPastEndDate = true;
  } else if (underReviewSortBy === UnderReviewCollabsSortingMethod.closestToGoal) {
    underReviewWhere.goalNotAchieved = true;
  }
  underReviewWhere = removeUndefinedFromObject(underReviewWhere);

  const {
    data: underReviewCollabs,
    loading: underReviewCollabsLoading,
    fetchMore: underReviewCollabsFetchMore,
    networkStatus: underReviewCollabsNetworkStatus,
    error: underReviewCollabsError,
  } = useUnderReviewCollabs({
    variables: {
      first: nbProductsPerFetch,
      sortBy: underReviewSortBy,
      where: Object.keys(underReviewWhere).length > 0 ? underReviewWhere : undefined,
    },
    skip: type !== HomeSectionType.underReviewCollabs,
  });

  if (type === undefined) return defaultResponse;

  switch (type) {
    case HomeSectionType.concepts:
      return {
        data: concepts,
        loading: conceptsLoading,
        error: conceptsError,
        networkStatus: conceptsNetworkStatus,
        fetchMore: conceptsFetchMore,
      };
    case HomeSectionType.purchaseableCollabs:
      return {
        data: purchaseableCollabs,
        loading: purchaseableCollabsLoading,
        error: purchaseableCollabsError,
        networkStatus: purchaseableCollabsNetworkStatus,
        fetchMore: purchaseableCollabsFetchMore,
      };
    case HomeSectionType.forSaleCollabs:
      return {
        data: forSaleCollabs,
        loading: forSaleCollabsLoading,
        error: forSaleCollabsError,
        networkStatus: forSaleCollabsNetworkStatus,
        fetchMore: forSaleCollabsFetchMore,
      };
    case HomeSectionType.inDevelopmentCollabs:
      return {
        data: inDevelopmentCollabs,
        loading: inDevelopmentCollabsLoading,
        error: inDevelopmentCollabsError,
        networkStatus: inDevelopmentCollabsNetworkStatus,
        fetchMore: inDevelopmentCollabsFetchMore,
      };
    case HomeSectionType.underReviewCollabs:
      return {
        data: underReviewCollabs,
        loading: underReviewCollabsLoading,
        error: underReviewCollabsError,
        networkStatus: underReviewCollabsNetworkStatus,
        fetchMore: underReviewCollabsFetchMore,
      };
    default:
      return defaultResponse;
  }
};
