import { StyleSheet } from "react-native";

export const padding = 8;
export const paddingBottom = 78;

export const styles = StyleSheet.create({
  contentContainer: {
    columnGap: 4,
  },
  root: {
    marginHorizontal: 8,
  },
});
