import React, { FC } from "react";

import { ContestStatus } from "@app/common/graphql/generated/schema.graphql";
import { Routes, useHomeNavigation } from "@app/common/types/navigation.type";
import { ContestList } from "@app/components/contests/contest-list/contest-list.component";
import { isWeb } from "@app/utils/device.util";

import { HomeSection } from "../home-section.component";

interface Props {
  title: string;
}

export const ContestSection: FC<Props> = ({ title }) => {
  const navigation = useHomeNavigation();

  return (
    <HomeSection title={title} onSeeAllPress={() => navigation.navigate(Routes.contests)}>
      <ContestList status={ContestStatus.inProgress} scrollEnabled={isWeb} />
    </HomeSection>
  );
};
