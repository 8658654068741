import React, { FC, useCallback } from "react";

import { Pressable } from "react-native";
import { ActivityIndicator, useTheme } from "react-native-paper";

import supercoin from "@app/assets/images/gold-dollar-coins.png";
import { ProfileTab } from "@app/common/enums/profile-tab.enum";
import { customFonts } from "@app/common/style/fonts";
import { Routes, Tabs, useNavigation } from "@app/common/types/navigation.type";
import { Image } from "@app/components/common/image/image.component";
import { Text } from "@app/components/common/text/text.component";
import { useProfileContext } from "@app/context/profile/profile.context";
import { useMilestonesProgress } from "@app/hooks/api/supercoins/use-milestones-progress.hook";
import { useSupercoinBalance } from "@app/hooks/api/supercoins/use-supercoin-balance.hook";

import { styles } from "./supercoin-button.style";

export const SupercoinButton: FC = () => {
  const navigation = useNavigation();
  const { colors } = useTheme();

  const { profile } = useProfileContext();
  const {
    data: supercoinBalance,
    loading: loadingSupercoinBalance,
    refetch: refetchSupercoinBalance,
  } = useSupercoinBalance({ variables: { userId: profile?.userId }, skip: !profile?.userId });
  const { refetch: refetchMilestonesProgress } = useMilestonesProgress({
    variables: { userId: profile?.userId },
    skip: !profile?.userId,
  });

  const handleSupercoinsPress = useCallback(() => {
    void refetchSupercoinBalance?.();
    void refetchMilestonesProgress?.();
    navigation.push(Routes.root, { screen: Tabs.profile, params: { screen: Routes.profile, params: { tab: ProfileTab.supercoins } } });
  }, [navigation, refetchMilestonesProgress, refetchSupercoinBalance]);

  if (!profile?.userId) return null;

  return (
    <Pressable onPress={handleSupercoinsPress} style={[styles.row, styles.supercoinsButton, { backgroundColor: colors.tertiaryContainer }]}>
      <Image source={supercoin} style={styles.supercoinImage} />
      {loadingSupercoinBalance ? (
        <ActivityIndicator size={customFonts.caption.lineHeight} color={colors.onBackground} />
      ) : (
        <Text variant="caption">{supercoinBalance?.formattedAmount}</Text>
      )}
    </Pressable>
  );
};
