import React, { FC } from "react";

import { HeartStraight, ShoppingCartSimple } from "phosphor-react-native";
import { View } from "react-native";
import { ActivityIndicator, IconButton, useTheme } from "react-native-paper";

import HandCoins from "@app/assets/icons/hand-coins-thin.svg";
import { Collab } from "@app/common/graphql/generated/schema.graphql";
import { Text } from "@app/components/common/text/text.component";

import { LikeButton } from "../../like-button/like-button.component";
import { OpenVariantPickerButton } from "../../open-variant-picker-button/open-variant-picker-button.component";

import { styles } from "./product-preview-action-buttons.style";

interface Props {
  product: Collab;
}

export const ProductPreviewActionButtons: FC<Props> = ({ product }) => {
  const { colors } = useTheme();

  const iconColor = colors.primary;
  const commonIconProps = { iconColor, style: styles.icon };

  return (
    <View style={styles.root}>
      <LikeButton
        collabId={product.collabId}
        liked={product.liked}
        numberOfLikes={product.numberOfLikes}
        buttonComponent={({ onPress }) => (
          <IconButton
            icon={iconProps => (
              <View style={styles.iconWithCountContainer}>
                <HeartStraight {...iconProps} size={24} color={product.liked ? colors.secondary : iconProps.color} />
                {product.numberOfLikes > 0 && <Text variant="caption">{product.formattedNumberOfLikes}</Text>}
              </View>
            )}
            onPress={onPress}
            {...commonIconProps}
          />
        )}
      />
      <OpenVariantPickerButton
        product={product}
        buyAction="addToCart"
        buttonComponent={({ onPress, loading }) => (
          <IconButton
            icon={iconProps =>
              loading ? <ActivityIndicator {...iconProps} color={colors.common.gray} /> : <ShoppingCartSimple {...iconProps} size={24} />
            }
            onPress={onPress}
            {...commonIconProps}
          />
        )}
      />
      <OpenVariantPickerButton
        product={product}
        buyAction="preorder"
        buttonComponent={({ onPress, loading }) => (
          <IconButton
            icon={iconProps =>
              loading ? <ActivityIndicator {...iconProps} color={colors.common.gray} /> : <HandCoins {...iconProps} height={24} width={24} />
            }
            onPress={onPress}
            {...commonIconProps}
          />
        )}
      />
    </View>
  );
};
