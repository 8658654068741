import React, { FC } from "react";

import { createNativeStackNavigator } from "@react-navigation/native-stack";

import { CartStackParamList } from "@app/common/types/navigation/stack-param-list.type";
import { RootTabStackScreenProps, Routes, Tabs } from "@app/common/types/navigation.type";
import { CartOrdersScreen } from "@app/screens/cart-orders/cart-orders.screen";

import { commonScreenOptions } from "../router.const";

const Stack = createNativeStackNavigator<CartStackParamList>();

export const CartOrdersStack: FC<RootTabStackScreenProps<Tabs.cartOrders>> = () => (
  <Stack.Navigator screenOptions={commonScreenOptions()}>
    <Stack.Screen name={Routes.cartOrders} component={CartOrdersScreen} options={{ headerShown: false }} />
  </Stack.Navigator>
);
