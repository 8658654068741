import { FetchResult, gql, MutationFunctionOptions, useMutation } from "@apollo/client";

import { adFields } from "@app/common/graphql/fragments.graphql";
import { Ad } from "@app/common/graphql/generated/schema.graphql";
import { MutationResult } from "@app/common/types/apollo-result.type";

interface Response {
  markAdAsSeen: Ad;
}

interface Variables {
  adId: string;
  userId: string;
}

interface MarkAdAsSeenResult extends MutationResult<Response, "markAdAsSeen"> {
  markAdAsSeen: (options?: MutationFunctionOptions<Response, Variables>) => Promise<FetchResult>;
}

const markAdAsSeenMutation = gql`
  mutation MarkAdAsSeen($adId: AdId!, $userId: UserId!) {
    markAdAsSeen(input: { adId: $adId }, userId: $userId) {
      ...AdFields
    }
  }
  ${adFields}
`;

export function useMarkAdAsSeen(): MarkAdAsSeenResult {
  const [markAdAsSeen, { loading, error, data }] = useMutation<Response, Variables>(markAdAsSeenMutation);

  return { markAdAsSeen, loading, error, data: data?.markAdAsSeen };
}
