import React, { FC, useCallback, useEffect, useMemo, useRef } from "react";

import * as Device from "expo-device";
import {
  addNotificationResponseReceivedListener,
  DEFAULT_ACTION_IDENTIFIER,
  dismissAllNotificationsAsync,
  dismissNotificationAsync,
  NotificationResponse,
  removeNotificationSubscription,
  setBadgeCountAsync,
  Subscription,
} from "expo-notifications";
import { Platform } from "react-native";

import { CartOrdersTab } from "@app/common/enums/cart-orders-tab.enum";
import { TrackEvent } from "@app/common/enums/track-events.enum";
import { ChildrenProp } from "@app/common/types/children-prop.interface";
import { Routes, Tabs } from "@app/common/types/navigation/routes.enum";
import { useNavigation } from "@app/common/types/navigation.type";
import { useAppStateContext } from "@app/context/app-state/app-state.context";
import { useNewHomeNavigation } from "@app/hooks/utils/use-new-home-navigation.hook";
import { useOpenStudio } from "@app/hooks/utils/use-open-studio.hook";
import { isCollabId, isCollabUpdateId, isContestId, isOrderId, isUserId } from "@app/utils/id.util";
import { reportError, log } from "@app/utils/logger/logger.util";

import { useMixpanelContext } from "../../mixpanel/mixpanel.context";

import { IncomingPushNotificationsContext, IncomingPushNotificationsContextInterface } from "./incoming-push-notifications.context";
import { PushNotificationType } from "./push-notifications.type";

export const IncomingPushNotificationsContextProvider: FC<ChildrenProp> = ({ children }) => {
  const navigation = useNavigation();
  const { isAppOnForeground } = useAppStateContext();
  const { trackInMixpanel } = useMixpanelContext();

  const { openImageGeneration } = useOpenStudio();
  const { navigateToNotificationsScreen } = useNewHomeNavigation();

  const interactedListener = useRef<Subscription>();

  const pushNotificationsAvailable = Device.isDevice && Platform.OS !== "web";

  useEffect(() => {
    if (isAppOnForeground && pushNotificationsAvailable) {
      void setBadgeCountAsync(-1);
    }
  }, [isAppOnForeground, pushNotificationsAvailable]);

  const clearAllNotifications = useCallback((): void => {
    if (pushNotificationsAvailable) {
      void dismissAllNotificationsAsync();
    }
  }, [pushNotificationsAvailable]);

  const clearIndividualNotification = useCallback(
    (identifier?: string | null): void => {
      if (pushNotificationsAvailable && identifier) {
        void dismissNotificationAsync(identifier).catch(error => log.error(error));
      }
    },
    [pushNotificationsAvailable],
  );

  const handleNotificationRedirect = useCallback(
    (safeNotifType: string | undefined, safeRedirectId: string | undefined): void => {
      if (safeNotifType === PushNotificationType.tokenGift) {
        openImageGeneration();
      } else if (safeNotifType === PushNotificationType.newConcepts) {
        navigation.navigate(Routes.root, { screen: Tabs.feed, params: { screen: Routes.feed } });
      } else if (!safeRedirectId) {
        navigateToNotificationsScreen();
      } else if (isCollabId(safeRedirectId)) {
        navigation.navigate(Routes.product, { id: safeRedirectId });
      } else if (isCollabUpdateId(safeRedirectId)) {
        navigation.navigate(Routes.productUpdateStack, { screen: Routes.productUpdate, params: { updateId: safeRedirectId } });
      } else if (isContestId(safeRedirectId)) {
        navigation.navigate(Routes.contest, { id: safeRedirectId });
      } else if (isUserId(safeRedirectId)) {
        navigation.navigate(Routes.profile, { id: safeRedirectId });
      } else if (isOrderId(safeRedirectId)) {
        navigation.navigate(Routes.root, { screen: Tabs.cartOrders, params: { screen: Routes.cartOrders, params: { tab: CartOrdersTab.orders } } });
      } else {
        reportError(new Error(`Received push notif with a malformed id: ${safeRedirectId}`));
        navigateToNotificationsScreen();
      }
    },
    [navigateToNotificationsScreen, navigation, openImageGeneration],
  );

  const pushNotifListener = useCallback(
    (response: NotificationResponse) => {
      if (response.actionIdentifier === DEFAULT_ACTION_IDENTIFIER) {
        clearIndividualNotification(response.notification.request.identifier);
        if (!response.notification.request.content.data) return;

        const { trackingId, typeForTracking, redirectId } = response.notification.request.content.data;

        const safeTrackingId = trackingId && typeof trackingId === "string" ? trackingId : undefined;
        const safeNotifType = typeForTracking && typeof typeForTracking === "string" ? typeForTracking : undefined;
        const safeRedirectId = redirectId && typeof redirectId === "string" ? redirectId : undefined;

        if (safeTrackingId) {
          const { title, body } = response.notification.request.content;

          trackInMixpanel(TrackEvent.pushNotifInteraction, {
            trackingId: safeTrackingId,
            notifType: safeNotifType,
            redirectId: safeRedirectId,
            title,
            body,
          });
        }

        handleNotificationRedirect(safeNotifType, safeRedirectId);
      }
    },
    [clearIndividualNotification, handleNotificationRedirect, trackInMixpanel],
  );

  useEffect(() => {
    if (pushNotificationsAvailable) {
      interactedListener.current = addNotificationResponseReceivedListener(pushNotifListener);

      return () => {
        interactedListener.current && removeNotificationSubscription(interactedListener.current);
      };
    }

    return undefined;
  }, [pushNotifListener, pushNotificationsAvailable]);

  const pushNotificationsContextValue = useMemo<IncomingPushNotificationsContextInterface>(
    () => ({ clearNotifications: clearAllNotifications }),
    [clearAllNotifications],
  );

  return <IncomingPushNotificationsContext.Provider value={pushNotificationsContextValue}>{children}</IncomingPushNotificationsContext.Provider>;
};
