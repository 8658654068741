import { StyleSheet } from "react-native";

export const styles = StyleSheet.create({
  bottomContent: {
    rowGap: 8,
  },
  creatorNameSkeleton: {
    alignItems: "center",
    columnGap: 4,
    flexDirection: "row",
  },
  productWrapper: {
    flex: 1,
  },
  skeletonsWrapper: {
    marginBottom: 16,
    rowGap: 8,
  },
});
