import React, { FC } from "react";

import { createNativeStackNavigator } from "@react-navigation/native-stack";

import { HomeStackParamList } from "@app/common/types/navigation/stack-param-list.type";
import { RootTabStackScreenProps, Routes, Tabs } from "@app/common/types/navigation.type";
import { ContestsScreen } from "@app/screens/contests/contests/contests.screen";
import { HomeScreen } from "@app/screens/home/home.screen";
import { SeeAllCollabsScreen } from "@app/screens/home/see-all-collabs/see-all-collabs.screen";
import { SeeAllVideosScreen } from "@app/screens/home/see-all-videos/see-all-videos.screen";
import { NotificationsScreen } from "@app/screens/notifications/notifications.screen";

import { commonScreenOptions } from "../router.const";

import { SearchStack } from "./search.stack";

const Stack = createNativeStackNavigator<HomeStackParamList>();

export const HomeStack: FC<RootTabStackScreenProps<Tabs.home>> = () => {
  return (
    <Stack.Navigator initialRouteName={Routes.home} screenOptions={commonScreenOptions()}>
      <Stack.Screen name={Routes.home} component={HomeScreen} options={{ title: "", headerShown: false }} />
      <Stack.Screen name={Routes.notifications} component={NotificationsScreen} />
      <Stack.Screen name={Routes.searchStack} component={SearchStack} options={{ headerShown: false }} />
      <Stack.Screen name={Routes.seeAllCollabs} component={SeeAllCollabsScreen} />
      <Stack.Screen name={Routes.seeAllVideos} component={SeeAllVideosScreen} />
      <Stack.Screen name={Routes.contests} component={ContestsScreen} />
    </Stack.Navigator>
  );
};
