import React, { FC } from "react";

import { FlashListProps } from "@shopify/flash-list";
import { useTranslation } from "react-i18next";
import { View } from "react-native";

import { Profile } from "@app/common/graphql/generated/schema.graphql";
import { Text } from "@app/components/common/text/text.component";
import { ProfileListLoading } from "@app/components/profile/profile-list/profile-list-loading/profile-list-loading.component";
import { useWindowDimensions } from "@app/hooks/utils/use-window-dimensions.hook";

import { styles } from "./profile-list-empty.style";

interface Props {
  loading: boolean;
  loadingFlashListProps: Omit<FlashListProps<Profile>, "renderItem" | "data">;
  itemWidth: number;
}

export const ProfileListEmpty: FC<Props> = ({ loading, itemWidth, loadingFlashListProps }) => {
  const { width: screenWidth, height: screenHeight } = useWindowDimensions();

  const { t } = useTranslation();

  const noResultState = (
    <View style={[styles.root, styles.center]}>
      <Text variant="body2" color="tertiary" textAlign="center">
        {t("noResults.default")}
      </Text>
    </View>
  );

  if (loading)
    return (
      <ProfileListLoading quantity={10} screenHeight={screenHeight} screenWidth={screenWidth} itemWidth={itemWidth} {...loadingFlashListProps} />
    );

  return noResultState;
};
