import { createContext, useContext } from "react";

import { HomeResult } from "@app/hooks/api/home/use-home.hook";

export const HomeContext = createContext<HomeResult>({
  data: undefined,
  loading: false,
});

export const useHomeContext = (): HomeResult => useContext(HomeContext);
