import React, { FC, useCallback } from "react";

import { useApolloClient } from "@apollo/client";
import { ArrowUpRight, X } from "phosphor-react-native";
import { View } from "react-native";
import { IconButton, useTheme } from "react-native-paper";

import { TrackEvent } from "@app/common/enums/track-events.enum";
import { Ad as ApiAd, PaginatedAds } from "@app/common/graphql/generated/schema.graphql";
import { Button } from "@app/components/common/button/button.component";
import { Text } from "@app/components/common/text/text.component";
import { useMixpanelContext } from "@app/context/mixpanel/mixpanel.context";
import { useProfileContext } from "@app/context/profile/profile.context";
import { useMarkAdAsSeen } from "@app/hooks/api/home/use-mark-ad-as-seen.hook";
import { useNavigateToAppLink } from "@app/hooks/utils/use-navigate-to-app-link.hook";

import { getAdIcon } from "../ad.utils";

import { styles } from "./ad.style";

interface Props {
  ad: ApiAd;
  width: number;
}

export const Ad: FC<Props> = ({ ad, width }) => {
  const { colors, roundness } = useTheme();

  const client = useApolloClient();
  const { profile } = useProfileContext();
  const { navigateToAppLink } = useNavigateToAppLink();
  const { trackInMixpanel } = useMixpanelContext();

  const { markAdAsSeen } = useMarkAdAsSeen();

  const handleCtaPress = useCallback(() => {
    navigateToAppLink(ad.link);
    trackInMixpanel(TrackEvent.adInteraction, { adId: ad.adId });
  }, [ad.adId, ad.link, navigateToAppLink, trackInMixpanel]);

  const handleDismiss = useCallback(() => {
    if (profile?.userId) {
      void markAdAsSeen({ variables: { adId: ad.adId, userId: profile.userId } });
    }

    client.cache.modify({
      fields: {
        ads: (existingAds: PaginatedAds, { readField }) => {
          const nodes = existingAds.nodes?.filter(existingAd => readField("adId", existingAd) !== ad.adId);
          const totalCount = existingAds.nodes?.length === nodes?.length ? existingAds.totalCount : existingAds.totalCount - 1;

          return {
            ...existingAds,
            nodes,
            totalCount,
          };
        },
      },
    });

    trackInMixpanel(TrackEvent.dismissAd, { adId: ad.adId });
  }, [ad.adId, client.cache, markAdAsSeen, profile?.userId, trackInMixpanel]);

  return (
    <View style={[styles.container, { borderRadius: roundness, width, backgroundColor: colors.tertiaryContainer }]}>
      {getAdIcon(ad.icon, 40, colors.primary)}
      <View style={styles.middleText}>
        <Text variant="h8" color="primary" textAlign="center" lineHeight={22}>
          {ad.title}
        </Text>
        <Text variant="body2" color="tertiary" textAlign="center">
          {ad.text}
        </Text>
      </View>
      <Button
        mode="contained"
        size="medium"
        contentStyle={styles.buttonContent}
        icon={ad.link.__typename === "ExternalLink" ? iconProps => <ArrowUpRight {...iconProps} /> : undefined}
        onPress={handleCtaPress}>
        {ad.linkText}
      </Button>
      <IconButton icon={iconProps => <X {...iconProps} />} size={24} style={styles.dismissButton} onPress={handleDismiss} />
    </View>
  );
};
