import { StyleSheet } from "react-native";

export const styles = StyleSheet.create({
  header: {
    paddingVertical: 12,
  },
  horizontalPadding: {
    paddingHorizontal: 20,
  },
  listItem: {
    borderWidth: 1,
    padding: 12,
  },
  listItemContainer: {
    rowGap: 8,
  },
  root: {
    paddingVertical: 20,
    rowGap: 16,
  },
  rowGap: {
    rowGap: 8,
  },
  section: {
    paddingHorizontal: 20,
  },
});
