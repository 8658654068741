import { QueryHookOptions, gql, useQuery } from "@apollo/client";

import { collabFields } from "@app/common/graphql/fragments.graphql";
import { PaginatedCollabs, ForSaleCollabsSortingMethod, ForSaleCollabsFilteringArgs } from "@app/common/graphql/generated/schema.graphql";
import { QueryResult } from "@app/common/types/apollo-result.type";

interface Response {
  forSaleCollabs: PaginatedCollabs;
}

interface Variables {
  first?: number;
  offset?: number;
  sortBy: ForSaleCollabsSortingMethod;
  where?: ForSaleCollabsFilteringArgs;
}

const forSaleCollabsQuery = gql`
  query ForSaleCollabs($first: Int, $offset: Int, $sortBy: ForSaleCollabsSortingMethod!, $where: ForSaleCollabsFilteringArgs) {
    forSaleCollabs(first: $first, offset: $offset, sortBy: $sortBy, where: $where) {
      nodes {
        ...CollabFields
      }
      pageInfo {
        hasNextPage
      }
      totalCount
    }
  }
  ${collabFields}
`;

export function useForSaleCollabs(options: QueryHookOptions<Response, Variables>): QueryResult<Response, "forSaleCollabs"> {
  const { data, loading, error, fetchMore, networkStatus } = useQuery<Response, Variables>(forSaleCollabsQuery, {
    notifyOnNetworkStatusChange: true,
    ...options,
  });

  return { data: data?.forSaleCollabs, loading, error, fetchMore, networkStatus };
}
