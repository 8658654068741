import React, { FC, useCallback, useMemo } from "react";

import { FlashList } from "@shopify/flash-list";
import { FlatList, ListRenderItem, View } from "react-native";

import { Ad as ApiAd } from "@app/common/graphql/generated/schema.graphql";
import { Skeleton } from "@app/components/common/skeleton/skeleton.component";
import { useHomeContext } from "@app/context/home/home.context";
import { useWindowDimensions } from "@app/hooks/utils/use-window-dimensions.hook";
import { conditionalItem } from "@app/utils/conditional-item-in-array.util";
import { isWeb } from "@app/utils/device.util";

import { Ad } from "./ad/ad.component";
import { styles } from "./ad-section.style";
import { FeatureAnnouncement } from "./feature-announcement/feature-announcement.component";

export const AdSection: FC = () => {
  const { width: screenWidth } = useWindowDimensions();
  const adWidth = 200;

  const { data, loading, error } = useHomeContext();
  const ads = data?.ads.nodes;
  const featureAnnouncement = data?.currentFeatureAnnouncement;

  const renderAd: ListRenderItem<ApiAd> = useCallback(({ item }) => <Ad ad={item} width={adWidth} />, []);

  const emptyAds = useMemo(() => {
    if (!loading && !error) return undefined;

    return (
      <FlashList
        horizontal
        data={new Array(4).fill(0)}
        keyExtractor={(_item, index) => `skeleton-ad-${index}`}
        renderItem={() => <Skeleton height={224} width={adWidth} radius="square" />}
        showsVerticalScrollIndicator={false}
        showsHorizontalScrollIndicator={false}
      />
    );
  }, [loading, error]);

  return (
    <View style={styles.root}>
      <FeatureAnnouncement featureAnnouncement={featureAnnouncement} loading={loading || !!error} />
      <FlatList
        data={ads}
        renderItem={renderAd}
        horizontal
        ListEmptyComponent={emptyAds}
        showsHorizontalScrollIndicator={false}
        contentContainerStyle={[conditionalItem(isWeb, { width: screenWidth }), styles.adList]}
      />
    </View>
  );
};
