import React, { FC, useCallback, useEffect, useMemo, useState } from "react";

import { useTranslation } from "react-i18next";
import { useTheme } from "react-native-paper";
import { TabBar, Route as TabRoute, TabView } from "react-native-tab-view";

import { SearchTab } from "@app/common/enums/search-tab.enum";
import { SearchCollabsSortingMethod } from "@app/common/graphql/generated/schema.graphql";
import { customFonts } from "@app/common/style/fonts";
import { SearchStackScreenProps, Routes, Tabs } from "@app/common/types/navigation.type";
import { ScreenWrapper } from "@app/components/common/screen-wrapper/screen-wrapper.component";
import { Text } from "@app/components/common/text/text.component";
import { ProductList, ProductListRefProps } from "@app/components/products/product-list/product-list.component";
import { ProfileList, ProfileListRefProps } from "@app/components/profile/profile-list/profile-list.component";
import { FeatureFlag } from "@app/context/launch-darkly/feature-flag.enum";
import { useLaunchDarklyContext } from "@app/context/launch-darkly/launch-darkly.context";
import { useSearchContext } from "@app/context/search/search.context";
import { useSearchCollabs } from "@app/hooks/api/use-search-collabs.hook";
import { useSearchUsers } from "@app/hooks/api/use-search-users.hook";
import { Facets, FacetsTypename } from "@app/hooks/utils/filtering-args/use-filtering-args.const";
import { useFilteringArgs } from "@app/hooks/utils/filtering-args/use-filtering-args.hook";

import { styles } from "./search-results.style";

const indexMap: Record<SearchTab, number> = {
  [SearchTab.collabs]: 0,
  [SearchTab.users]: 1,
};

export const SearchResultsScreen: FC<SearchStackScreenProps<Routes.searchResults>> = ({ navigation, route }) => {
  const { t } = useTranslation();
  const { colors } = useTheme();
  const { setQuery } = useSearchContext();
  const { isFeatureEnabled } = useLaunchDarklyContext();

  const query = route.params?.query?.trim() ?? "";
  const isNewHomePageEnabled = isFeatureEnabled(FeatureFlag.enableNewHomePage);

  const { getSearchFiltersFromFacets } = useFilteringArgs();
  const [collabFacets, setCollabFacets] = useState<Facets | undefined>(undefined);
  const [userFacets, setUserFacets] = useState<Facets | undefined>(undefined);

  const currentTab = route.params?.tab;
  const [index, setIndex] = useState(currentTab && currentTab in indexMap ? indexMap[currentTab] : 0);

  const [tabRoutes] = useState<TabRoute[]>([
    { key: SearchTab.collabs, title: t("search.items") },
    { key: SearchTab.users, title: t("search.users") },
  ]);

  useEffect(() => {
    if (isNewHomePageEnabled && route.path?.startsWith("/search")) {
      navigation.replace(Routes.root, {
        screen: Tabs.home,
        params: {
          screen: Routes.searchStack,
          params: { screen: Routes.searchResults, params: { query, tab: currentTab } },
        },
      });
    }
  }, [currentTab, isNewHomePageEnabled, navigation, query, route.path]);

  useEffect(() => {
    navigation.setParams({ tab: Object.keys(indexMap)[index] as SearchTab });
  }, [index, navigation]);

  useEffect(() => {
    if (query) {
      setQuery(query);
    } else {
      navigation.replace(Routes.search);
    }
  }, [navigation, query, setQuery]);

  const productListRef = useCallback((node: ProductListRefProps | null) => {
    if (node?.data && "facets" in node.data) {
      setCollabFacets(node.data.facets);
    }
  }, []);

  const profileListRef = useCallback((node: ProfileListRefProps | null) => {
    if (node?.data && "facets" in node.data) {
      setUserFacets(node.data.facets);
    }
  }, []);

  const collabFilters = useMemo(() => getSearchFiltersFromFacets(FacetsTypename.collab, collabFacets), [collabFacets, getSearchFiltersFromFacets]);
  const userFilters = useMemo(() => getSearchFiltersFromFacets(FacetsTypename.user, userFacets), [userFacets, getSearchFiltersFromFacets]);

  const renderScene = ({ route: tab }: { route: TabRoute }): React.ReactNode => {
    switch (tab.key) {
      case SearchTab.collabs:
        return (
          <ProductList
            ref={productListRef}
            useProducts={useSearchCollabs}
            variables={{ query }}
            sortProps={{
              sortButtonVisible: true,
              sortingMethodEnum: SearchCollabsSortingMethod,
              defaultSort: SearchCollabsSortingMethod.popular,
            }}
            filterProps={{
              filterButtonVisible: true,
              filters: collabFilters,
            }}
            useBatchAsOffset
            scrollEnabled
          />
        );
      case SearchTab.users:
        return <ProfileList ref={profileListRef} useProfiles={useSearchUsers} variables={{ query }} filters={userFilters} />;
      default:
        return null;
    }
  };

  return (
    <ScreenWrapper>
      <TabView
        lazy
        navigationState={{ index, routes: tabRoutes }}
        renderScene={renderScene}
        onIndexChange={setIndex}
        renderTabBar={props => (
          <TabBar
            {...props}
            getTestID={({ route: routeFromLabel }) => `${routeFromLabel.key}_search_result_top_bar`}
            indicatorStyle={{ backgroundColor: colors.onBackground }}
            style={[{ backgroundColor: colors.background }, styles.tab]}
            labelStyle={[{ color: colors.onBackground }, styles.tabLabel, customFonts.buttonSmall]}
            renderLabel={({ route: routeFromLabel }) => <Text variant="buttonSmall">{routeFromLabel.title}</Text>}
          />
        )}
      />
    </ScreenWrapper>
  );
};
