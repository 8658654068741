import { gql, QueryHookOptions, QueryResult, useQuery } from "@apollo/client";

import {
  adFields,
  behindTheScenesVideoFields,
  collabFields,
  collabUpdateFields,
  coreProfileFields,
  featureAnnouncementFields,
} from "@app/common/graphql/fragments.graphql";
import {
  ConceptsFilteringArgs,
  ConceptsSortingMethod,
  FeatureAnnouncement,
  ForSaleCollabsFilteringArgs,
  ForSaleCollabsSortingMethod,
  InDevelopmentCollabsSortingMethod,
  PaginatedAds,
  PaginatedCollabs,
  PaginatedProfiles,
  PaginatedUpdates,
  ProfilesSortingMethod,
  UnderReviewCollabsFilteringArgs,
  UnderReviewCollabsSortingMethod,
} from "@app/common/graphql/generated/schema.graphql";
import { HomeSectionType } from "@app/components/home/home.type";

interface Response {
  [HomeSectionType.ads]: PaginatedAds;
  [HomeSectionType.concepts]: PaginatedCollabs;
  [HomeSectionType.currentFeatureAnnouncement]: FeatureAnnouncement | null;
  [HomeSectionType.forSaleCollabs]: PaginatedCollabs;
  [HomeSectionType.inDevelopmentCollabs]: PaginatedCollabs;
  [HomeSectionType.purchaseableCollabs]: PaginatedCollabs;
  [HomeSectionType.underReviewCollabs]: PaginatedCollabs;
  [HomeSectionType.profiles]: PaginatedProfiles;
  [HomeSectionType.latestCollabUpdatesForUser]: PaginatedUpdates;
  [HomeSectionType.behindTheScenesVideos]: PaginatedUpdates;
}

interface Variables {
  first?: number;
  firstProfile?: number;
  offset?: number;
  userId?: string;
  profilesSortBy: ProfilesSortingMethod;
  conceptsSortBy: ConceptsSortingMethod;
  conceptsWhere?: ConceptsFilteringArgs;
  forSaleCollabsSortBy: ForSaleCollabsSortingMethod;
  forSaleCollabsWhere?: ForSaleCollabsFilteringArgs;
  inDevelopmentCollabsSortBy: InDevelopmentCollabsSortingMethod;
  underReviewCollabsSortBy: UnderReviewCollabsSortingMethod;
  underReviewCollabsWhere?: UnderReviewCollabsFilteringArgs;
}

export type HomeResult = Pick<QueryResult<Response, Variables>, "data" | "loading" | "error">;

const commonHomeScreenQueries = gql`
  fragment CommonHomeScreenQueries on Query {
    ads {
      nodes {
        ...AdFields
      }
      pageInfo {
        hasNextPage
      }
      totalCount
    }
    behindTheScenesVideos(first: $first, offset: $offset) {
      nodes {
        ...BehindTheScenesVideoFields
      }
      pageInfo {
        hasNextPage
      }
      totalCount
    }
    concepts(first: $first, offset: $offset, sortBy: $conceptsSortBy, where: $conceptsWhere) {
      nodes {
        ...CollabFields
      }
      pageInfo {
        hasNextPage
      }
      totalCount
    }
    currentFeatureAnnouncement {
      ...FeatureAnnouncementFields
    }
    forSaleCollabs(first: $first, offset: $offset, sortBy: $forSaleCollabsSortBy, where: $forSaleCollabsWhere) {
      nodes {
        ...CollabFields
      }
      pageInfo {
        hasNextPage
      }
      totalCount
    }
    inDevelopmentCollabs(first: $first, offset: $offset, sortBy: $inDevelopmentCollabsSortBy) {
      nodes {
        ...CollabFields
      }
      pageInfo {
        hasNextPage
      }
      totalCount
    }
    profiles(first: $firstProfile, offset: $offset, sortBy: $profilesSortBy) {
      nodes {
        ...CoreProfileFields
      }
      pageInfo {
        hasNextPage
      }
      totalCount
    }
    underReviewCollabs(first: $first, offset: $offset, sortBy: $underReviewCollabsSortBy, where: $underReviewCollabsWhere) {
      nodes {
        ...CollabFields
      }
      pageInfo {
        hasNextPage
      }
      totalCount
    }
  }
  ${adFields}
  ${behindTheScenesVideoFields}
  ${featureAnnouncementFields}
`;

export const homeScreenQueriesAsConnected = gql`
  query Home(
    $conceptsSortBy: ConceptsSortingMethod!
    $conceptsWhere: ConceptsFilteringArgs
    $first: Int
    $firstProfile: Int
    $forSaleCollabsSortBy: ForSaleCollabsSortingMethod!
    $forSaleCollabsWhere: ForSaleCollabsFilteringArgs
    $inDevelopmentCollabsSortBy: InDevelopmentCollabsSortingMethod!
    $offset: Int
    $profilesSortBy: ProfilesSortingMethod!
    $underReviewCollabsSortBy: UnderReviewCollabsSortingMethod!
    $underReviewCollabsWhere: UnderReviewCollabsFilteringArgs
    $userId: UserId!
  ) {
    ...CommonHomeScreenQueries
    latestCollabUpdatesForUser(first: $first, offset: $offset, userId: $userId) {
      nodes {
        ...CollabUpdateFields
      }
      pageInfo {
        hasNextPage
      }
      totalCount
    }
    purchaseableCollabs(first: $first, offset: $offset) {
      nodes {
        ...CollabFields
      }
      pageInfo {
        hasNextPage
      }
      totalCount
    }
  }
  ${collabUpdateFields}
  ${coreProfileFields}
  ${collabFields}
  ${commonHomeScreenQueries}
`;

export const homeScreenQueriesAsAnonymous = gql`
  query HomeAnonymous(
    $conceptsSortBy: ConceptsSortingMethod!
    $conceptsWhere: ConceptsFilteringArgs
    $first: Int
    $firstProfile: Int
    $forSaleCollabsSortBy: ForSaleCollabsSortingMethod!
    $forSaleCollabsWhere: ForSaleCollabsFilteringArgs
    $inDevelopmentCollabsSortBy: InDevelopmentCollabsSortingMethod!
    $offset: Int
    $profilesSortBy: ProfilesSortingMethod!
    $underReviewCollabsSortBy: UnderReviewCollabsSortingMethod!
    $underReviewCollabsWhere: UnderReviewCollabsFilteringArgs
  ) {
    ...CommonHomeScreenQueries
  }
  ${commonHomeScreenQueries}
  ${coreProfileFields}
  ${collabFields}
`;

export function useHome(options: QueryHookOptions<Response, Variables>): HomeResult {
  const { loading, error, data } = useQuery<Response, Variables>(
    options.variables?.userId ? homeScreenQueriesAsConnected : homeScreenQueriesAsAnonymous,
    {
      notifyOnNetworkStatusChange: true,
      ...options,
    },
  );

  return { loading, error, data };
}
