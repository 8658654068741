import React, { FC, useMemo } from "react";

import { ArrowRight, CaretRight } from "phosphor-react-native";
import { Trans, useTranslation } from "react-i18next";
import { View } from "react-native";
import { useTheme } from "react-native-paper";

import { SuperplanType } from "@app/common/enums/superplan-type.enum";
import { TrackEvent, ViewSuperplanScreenOrigin } from "@app/common/enums/track-events.enum";
import { Badge, FeatureAccesses } from "@app/common/graphql/generated/schema.graphql";
import { lightTheme } from "@app/common/style/theme";
import { ChildrenProp } from "@app/common/types/children-prop.interface";
import { Button } from "@app/components/common/button/button.component";
import { ListItem } from "@app/components/common/list-item/list-item.component";
import { Text } from "@app/components/common/text/text.component";
import { UserBadge } from "@app/components/common/user-badge/user-badge.component";
import { FeatureFlag } from "@app/context/launch-darkly/feature-flag.enum";
import { useLaunchDarklyContext } from "@app/context/launch-darkly/launch-darkly.context";
import { useMixpanelContext } from "@app/context/mixpanel/mixpanel.context";
import { useProfileContext } from "@app/context/profile/profile.context";
import { getSuperplanRequiredForFeature } from "@app/utils/access-role.util";

import { SuperplanBackground } from "../superplan-background/superplan-background.component";

import { styles } from "./superplan-alert.style";

interface CommonProps {
  flexDirection?: "column" | "row";
  onLearnMorePress: (tab: SuperplanType | undefined) => void;
  origin: ViewSuperplanScreenOrigin;
}

interface FeatureAccessAlertProps extends CommonProps {
  feature: keyof Omit<FeatureAccesses, "__typename">;
  superplanType?: never;
}

interface PermanentAlertProps extends CommonProps {
  feature?: never;
  superplanType: SuperplanType;
}

interface GenericAlertProps extends CommonProps {
  feature?: never;
  superplanType?: never;
}

type Props = FeatureAccessAlertProps | PermanentAlertProps | GenericAlertProps;

export const SuperplanAlert: FC<Props> = ({ feature, superplanType, flexDirection = "column", onLearnMorePress, origin }) => {
  const { t } = useTranslation();
  const { colors, roundness } = useTheme();

  const { isFeatureEnabled } = useLaunchDarklyContext();
  const { profile, featureAccesses } = useProfileContext();
  const { trackInMixpanel } = useMixpanelContext();

  const isSupercreatorEnabled = isFeatureEnabled(FeatureFlag.supercreator);

  const targetSuperplan = useMemo<SuperplanType | undefined | "superplan">(() => {
    if (feature) return getSuperplanRequiredForFeature(featureAccesses?.[feature]);

    if (superplanType) return superplanType;

    return "superplan";
  }, [feature, featureAccesses, superplanType]);

  const shouldHideAlert = useMemo(() => {
    if (!profile?.userId || !targetSuperplan) return true;

    if (feature && featureAccesses?.[feature].allowed) return true;

    if (superplanType === SuperplanType.supercreator && !isSupercreatorEnabled) return true;

    if (targetSuperplan === SuperplanType.superseller && [Badge.superseller, Badge.supercreatorAndSuperseller].includes(profile.badge)) return true;

    if (targetSuperplan === SuperplanType.supercreator && [Badge.supercreator, Badge.supercreatorAndSuperseller].includes(profile.badge)) return true;

    if (targetSuperplan === "superplan" && profile.badge === Badge.supercreatorAndSuperseller) return true;

    return false;
  }, [feature, featureAccesses, isSupercreatorEnabled, profile?.userId, profile?.badge, superplanType, targetSuperplan]);

  const iconSize = 20;

  const handleLearnMore = (): void => {
    trackInMixpanel(TrackEvent.viewSuperplanScreen, { origin, superplanType: targetSuperplan });
    onLearnMorePress(targetSuperplan === "superplan" ? undefined : targetSuperplan);
  };

  const featureExplanation = useMemo(() => {
    if (!targetSuperplan) return "";

    if (feature) return t(`superplan.explanationByFeature.${feature}`);

    return t(`${targetSuperplan}.explanation.title`);
  }, [feature, t, targetSuperplan]);

  if (shouldHideAlert) return null;

  if (flexDirection === "column") {
    return (
      <SuperplanBackground style={styles.borderRadius} containerStyle={styles.borderRadius}>
        <View style={[styles.rootColumn, { flexDirection }]}>
          <UserBadge type={targetSuperplan} size={iconSize} color={colors.common.white} />

          <Trans
            i18nKey={`${targetSuperplan}.explanation.oneLine`}
            values={{ featureExplanation }}
            parent={({ children }: ChildrenProp) => (
              <Text variant="body1" textAlign="center" color="common.black">
                {children}
              </Text>
            )}
            components={{
              b: (
                <Text variant="h8" textAlign="center" color="common.black">
                  <></>
                </Text>
              ),
            }}
          />

          <Button
            fullWidth
            mode="contained"
            icon={ArrowRight}
            onPress={handleLearnMore}
            containerStyle={styles.buttonContainer}
            contentStyle={styles.buttonContent}
            theme={lightTheme}>
            {t("cta.learnMore")}
          </Button>
        </View>
      </SuperplanBackground>
    );
  }

  return (
    <SuperplanBackground style={{ borderRadius: roundness }} containerStyle={{ borderRadius: roundness }}>
      <ListItem
        title={featureExplanation}
        titleProps={{ numberOfLines: 0, variant: "body1", theme: lightTheme }}
        subtitle={
          <Trans
            i18nKey={`${targetSuperplan}.explanation.subtitle`}
            parent={({ children }: ChildrenProp) => (
              <Text variant="body2" theme={lightTheme} color="tertiary">
                {children}
              </Text>
            )}
            components={{
              b: (
                <Text variant="h9" theme={lightTheme} color="tertiary">
                  <></>
                </Text>
              ),
            }}
          />
        }
        left={<UserBadge type={targetSuperplan} size={iconSize} />}
        right={<CaretRight color={colors.common.black} size={20} />}
        onPress={handleLearnMore}
        style={styles.rootRow}
        contentContainerStyle={styles.rootRowContentContainer}
        testId="superplan-alert"
      />
    </SuperplanBackground>
  );
};
