import { createContext, useContext } from "react";

import { mockContextFn } from "@app/utils/context.util";

export interface SearchContextInterface {
  query: string;
  setQuery: (query: string) => void;
}

export const SearchContext = createContext<SearchContextInterface>({
  query: "",
  setQuery: (_query: string) => mockContextFn("SearchContext", "setQuery"),
});

export const useSearchContext = (): SearchContextInterface => useContext(SearchContext);
