import React, { FC, useEffect, useState } from "react";

import { ApolloError } from "@apollo/client";
import { useTranslation } from "react-i18next";
import { View } from "react-native";

import { ActionFromOnboardingStep, OnboardingStep, TrackEvent } from "@app/common/enums/track-events.enum";
import { OnboardingStackScreenProps, Routes } from "@app/common/types/navigation.type";
import { Button } from "@app/components/common/button/button.component";
import { TextInput } from "@app/components/common/text-input/text-input.component";
import { OnboardingScreenLayout } from "@app/components/initial-states/onboarding-screen-layout/onboarding-screen-layout.component";
import { useMixpanelContext } from "@app/context/mixpanel/mixpanel.context";
import { useOnboardingContext } from "@app/context/onboarding/onboarding.context";
import { useProfileContext } from "@app/context/profile/profile.context";
import { UserStateEnum } from "@app/context/profile/user-state.enum";
import { useSnackbarContext } from "@app/context/snackbar/snackbar.context";
import { useUpdateProfile } from "@app/hooks/api/use-update-profile.hook";
import { useProfileInputProps } from "@app/hooks/utils/use-profile-input-props.hook";

import { styles } from "./user-info.style";

export const UserInfoScreen: FC<OnboardingStackScreenProps<Routes.userInfo>> = ({ navigation }) => {
  const { t } = useTranslation();
  const { showErrorSnackbar } = useSnackbarContext();
  const { mixpanel } = useMixpanelContext();

  const { onOnboardingStepCompleted } = useOnboardingContext();
  const { commonInputProps } = useProfileInputProps();
  const { profile, loading: isProfileLoading, updateState } = useProfileContext();
  const { updateProfile, loading } = useUpdateProfile();

  const [name, setName] = useState("");
  const [username, setUsername] = useState("");
  const [usernameAlreadyTaken, setUsernameAlreadyTaken] = useState(false);
  const [invalidFormat, setInvalidFormat] = useState(false);

  const handleChangeUsername = (value: string): void => {
    setUsername(value);
    setUsernameAlreadyTaken(false);
    setInvalidFormat(false);
  };

  const handleNext = (): void => {
    updateState({ input: { [UserStateEnum.userInfoCompleted]: true } });
    // index=0 : corresponding to the first step of the onboarding
    mixpanel?.track(TrackEvent.actionFromOnboardingStep, { index: 0, step: OnboardingStep.userInfo, action: ActionFromOnboardingStep.next });
    onOnboardingStepCompleted(Routes.userInfo, navigation);
  };

  const handleSubmit = (): void => {
    if (!profile) return;
    void updateProfile({
      variables: { userId: profile.userId, input: { displayName: name, username } },
      onCompleted: handleNext,
      onError: (e: ApolloError) => {
        if (e.graphQLErrors?.find(({ extensions }) => extensions.code === "USERNAME_ALREADY_TAKEN")) {
          setUsernameAlreadyTaken(true);
        } else if (e.graphQLErrors?.find(({ extensions }) => extensions.code === "BAD_USER_INPUT")) {
          setInvalidFormat(true);
        } else {
          showErrorSnackbar({ error: e });
          handleNext();
        }
      },
    });
  };

  useEffect(() => {
    if (!profile || isProfileLoading) return;

    setUsername(profile.username);
    setName(profile.displayName);
  }, [setUsername, setName, isProfileLoading, profile]);

  const isButtonDisabled = !name || !username || loading || isProfileLoading || usernameAlreadyTaken || invalidFormat;

  return (
    <OnboardingScreenLayout
      title={t("userInfo.title")}
      subtitle={t("userInfo.subtitle")}
      content={
        <View style={styles.formContainer}>
          <TextInput value={name} onChangeText={setName} loading={isProfileLoading} noUnderline aria-label="name" {...commonInputProps.displayName} />

          <TextInput
            value={username}
            onChangeText={handleChangeUsername}
            loading={isProfileLoading}
            error={usernameAlreadyTaken || invalidFormat}
            noUnderline
            aria-label="username"
            {...commonInputProps.username}
            errorText={invalidFormat ? t("error.invalidUsername") : usernameAlreadyTaken ? t("error.usernameTaken") : undefined}
          />
        </View>
      }
      bottomButton={buttonProps => (
        <Button {...buttonProps} onPress={handleSubmit} loading={loading} disabled={isButtonDisabled} aria-label="next">
          {t("cta.next")}
        </Button>
      )}
    />
  );
};
