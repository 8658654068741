import React, { FC, useMemo } from "react";

import { WarningOctagon } from "phosphor-react-native";
import { useTranslation } from "react-i18next";

import { NavigationProps, Routes } from "@app/common/types/navigation.type";
import { FullScreenError } from "@app/components/common/full-screen-error/full-screen-error.component";
import { ScreenWrapper } from "@app/components/common/screen-wrapper/screen-wrapper.component";
import { useLoginContext } from "@app/context/auth/login/login.context";
import { useProfileContext } from "@app/context/profile/profile.context";
import { useNewHomeNavigation } from "@app/hooks/utils/use-new-home-navigation.hook";

export const NotFoundScreen: FC<NavigationProps<Routes.notFound>> = ({ navigation }) => {
  const { t } = useTranslation();
  const { profile } = useProfileContext();
  const { openLogin } = useLoginContext();

  const { navigateToMainScreen } = useNewHomeNavigation();

  const ctaProps = useMemo(() => {
    if (profile) {
      return {
        label: t("error.notFound.cta"),
        onPress: () => navigateToMainScreen(),
      };
    } else {
      return {
        label: t("error.notFound.cta"),
        onPress: () => {
          navigation.goBack();
          openLogin();
        },
      };
    }
  }, [profile, t, navigateToMainScreen, navigation, openLogin]);

  return (
    <ScreenWrapper safeAreaEdges={["left", "right"]}>
      <FullScreenError title={t("error.notFound.title")} message={t("error.notFound.message")} image={WarningOctagon} ctaProps={ctaProps} />
    </ScreenWrapper>
  );
};
