import React, { JSX, ForwardedRef, forwardRef, useState } from "react";

import { Stream } from "@cloudflare/stream-react";
import { ResizeMode, Video as VideoExpo, VideoProps } from "expo-av";
import { ActivityIndicator } from "react-native-paper";

import { Image } from "@app/components/common/image/image.component";
import { isDesktop, isWeb } from "@app/utils/device.util";
import { extractStringUrlAsSource } from "@app/utils/media.util";

import { styles } from "./video.style";

export type VideoRefProps = VideoExpo;

interface Props extends VideoProps {
  cloudflareId: string;
  displayLoadingIndicator?: boolean;
}

const VideoInner = (
  { cloudflareId, source, shouldPlay, displayLoadingIndicator, posterStyle, style, ...props }: Props,
  ref: ForwardedRef<VideoRefProps>,
): JSX.Element => {
  const [isReady, setIsReady] = useState(false);

  if (isDesktop) {
    return (
      <Stream
        src={cloudflareId}
        controls={props.useNativeControls}
        autoplay={shouldPlay}
        loop={props.isLooping}
        muted={props.isMuted}
        poster={extractStringUrlAsSource(props.posterSource)}
      />
    );
  }

  return (
    <VideoExpo
      ref={ref}
      source={source}
      PosterComponent={({ source: posterSource, ...posterProps }) => (
        <>
          <Image
            source={posterSource && typeof posterSource === "object" && "uri" in posterSource ? posterSource.uri : (posterSource as string | number)}
            resizeMode={props.resizeMode}
            {...posterProps}
          />
          {displayLoadingIndicator && <ActivityIndicator size="large" style={styles.loading} />}
        </>
      )}
      posterStyle={[styles.poster, posterStyle]}
      usePoster={isWeb ? !!props.posterSource : !isReady && !!props.posterSource}
      shouldPlay={isWeb ? shouldPlay : shouldPlay && isReady}
      onReadyForDisplay={() => setIsReady(true)}
      resizeMode={ResizeMode.COVER}
      style={style}
      {...props}
    />
  );
};

export const Video = forwardRef(VideoInner);
