import React, { FC, Fragment, JSX, memo, useMemo } from "react";

import { HourglassSimpleLow } from "phosphor-react-native";
import { Trans, useTranslation } from "react-i18next";
import { Pressable, View, StyleProp, ViewStyle } from "react-native";
import { IconButton, useTheme } from "react-native-paper";

import Heart from "@app/assets/icons/heart-straight-duotone.svg";
import { links } from "@app/common/constants/links.const";
import { Collab, CollabCampaignStatus, ContestStatus, Stage } from "@app/common/graphql/generated/schema.graphql";
import { darkTheme } from "@app/common/style/theme";
import { ChildrenProp } from "@app/common/types/children-prop.interface";
import { Routes, useNavigation } from "@app/common/types/navigation.type";
import { Chip } from "@app/components/common/chip/chip.component";
import { Image } from "@app/components/common/image/image.component";
import { Text } from "@app/components/common/text/text.component";
import { CreatorNameWithAvatar } from "@app/components/profile/creator-name-with-avatar/creator-name-with-avatar.component";
import { useNavigateToWebview } from "@app/hooks/utils/use-navigate-to-webview.hook";
import { useProductListItemLayout } from "@app/hooks/utils/use-product-list-item-layout.hook";

import { LikeButton } from "../../like-button/like-button.component";
import { ProductDetailedStage } from "../../product-detailed-stage/product-detailed-stage.component";
import { ProductPrice } from "../../product-price/product-price.component";

import { defaultRowGap, statusChipBackground, styles } from "./product-list-item.style";

export interface ContestEntryProps {
  winner: boolean;
  contestStatus: ContestStatus;
}

export interface ProductListItemProps {
  product: Collab;
  width: number;
  isFeaturedItem?: boolean;
  hideStageChip?: boolean;
  hideCreatorName?: boolean;
  actionButtons?: JSX.Element[];
  contestEntryProps?: ContestEntryProps;
  style?: StyleProp<ViewStyle>;
}

const ProductListItem: FC<ProductListItemProps> = ({
  product,
  width,
  hideStageChip,
  hideCreatorName,
  actionButtons,
  isFeaturedItem,
  contestEntryProps,
  style,
}) => {
  const { t } = useTranslation();
  const { colors, roundness } = useTheme();
  const navigation = useNavigation();
  const { navigateToWebview } = useNavigateToWebview();

  const isHidden = product.progress.stage === Stage.hidden;
  const isContestWinner = contestEntryProps?.winner;
  const isCampaign = product.progress.stage === Stage.underReview;
  const isOngoingCampaign = isCampaign && product.campaign?.status === CollabCampaignStatus.inProgress;
  const isSuccessfulCampaign = isCampaign && product.campaign?.status === CollabCampaignStatus.successful;

  const onScreenWithButtons = !!actionButtons?.length;
  const shouldUseFixedHeight = onScreenWithButtons;

  const { numberOfLinesForProductName, productDetailsHeight } = useProductListItemLayout({
    shouldUseFixedHeight,
    rowGap: defaultRowGap,
    isOngoingCampaign,
  });

  const handleImagePress = (): void => navigation.navigate(Routes.product, { id: product.handle });

  const stageChipLabel = useMemo(() => {
    if (isFeaturedItem) return t("featured.label");
    if (isContestWinner) return t("contest.contestWinner");
    if (isCampaign && product.campaign)
      return t(`enum.collabCampaignStatus.${product.campaign.status}`, { context: isSuccessfulCampaign ? "long" : undefined });

    return t(`enum.stage.${product.progress.stage}`);
  }, [isCampaign, isContestWinner, isFeaturedItem, isSuccessfulCampaign, product.campaign, product.progress.stage, t]);

  return (
    <View style={[styles.root, { width }, style]}>
      {!hideCreatorName && (
        <CreatorNameWithAvatar profile={product.creator} textProps={{ variant: "caption" }} width={width} style={styles.creatorName} />
      )}
      <Pressable onPress={handleImagePress} style={style}>
        <View>
          <Image key={product.collabId} source={product.images[0]?.imageUrl} width={width} style={styles.image} />
          {(!hideStageChip || isContestWinner) && (
            <Chip
              label={stageChipLabel}
              textColor={isContestWinner ? "onWarning" : "onTertiaryContainer"}
              textVariant="caption"
              theme={darkTheme}
              style={[
                styles.statusChip,
                { borderTopRightRadius: roundness, backgroundColor: isContestWinner ? darkTheme.colors.warning : statusChipBackground },
              ]}
            />
          )}
          <LikeButton
            liked={product.liked}
            collabId={product.collabId}
            numberOfLikes={product.numberOfLikes}
            buttonComponent={({ onPress }) => (
              <IconButton
                icon={iconProps => (
                  <Heart {...iconProps} width={24} height={24} color={product.liked ? darkTheme.colors.secondary : colors.common.white} />
                )}
                iconColor={colors.common.white}
                onPress={onPress}
                style={styles.likeButton}
              />
            )}
          />
        </View>

        <View style={styles.content}>
          <ProductPrice
            price={product.formattedPrice}
            compareAtPrice={product.formattedCompareAtPrice}
            stage={product.progress.stage}
            commonTextProps={{ variant: "body2" }}
          />

          <View style={[styles.namesWithGoalsContainer, { height: productDetailsHeight }]}>
            <Text variant="caption" numberOfLines={numberOfLinesForProductName} textTransform="uppercase">
              {product.name}
            </Text>
            <ProductDetailedStage product={product} style={styles.detailedStage} />
            {isHidden && (
              <View style={styles.hiddenIndicatorContainer}>
                <HourglassSimpleLow size={16} color={colors.warning} weight="fill" />
                <Trans
                  i18nKey="productDetails.flagged"
                  parent={({ children }: ChildrenProp) => (
                    <Text variant="caption" color="warning" fontStyle="italic">
                      {children}
                    </Text>
                  )}
                  components={{
                    glink: (
                      <Text
                        fontStyle="italic"
                        variant="caption"
                        color="warning"
                        textDecorationLine="underline"
                        onPress={() => navigateToWebview(links.external.termsOfUse, t("settings.about.termsOfUse"))}>
                        <></>
                      </Text>
                    ),
                  }}
                />
              </View>
            )}
          </View>
        </View>
      </Pressable>

      {onScreenWithButtons && (
        <View style={[styles.content, styles.buttonsContainer]}>
          {actionButtons?.map((button, index) => <Fragment key={`product-list-item-action-${index}-${product.collabId}`}>{button}</Fragment>)}
        </View>
      )}
    </View>
  );
};

export default memo(ProductListItem);
