import { useCallback } from "react";

import { useLinkTo } from "@react-navigation/native";
import * as Linking from "expo-linking";

import { AppLink } from "@app/common/graphql/generated/schema.graphql";
import { useSnackbarContext } from "@app/context/snackbar/snackbar.context";

interface Response {
  navigateToAppLink: (link: AppLink) => void;
}

export const useNavigateToAppLink = (): Response => {
  const linkTo = useLinkTo();
  const { showErrorSnackbar } = useSnackbarContext();

  const navigateToAppLink = useCallback(
    (link: AppLink) => {
      if (link.__typename === "ExternalLink") {
        Linking.openURL(link.externalLink).catch(error => showErrorSnackbar({ error: error as Error }));
      } else if (link.__typename === "InternalLink") {
        try {
          linkTo(link.internalLinkPath);
        } catch (error) {
          showErrorSnackbar({ error: error as Error });
        }
      }
    },
    [linkTo, showErrorSnackbar],
  );

  return { navigateToAppLink };
};
