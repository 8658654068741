import React, { FC } from "react";

import { formatDistanceToNowStrict } from "date-fns";
import { LinearGradient } from "expo-linear-gradient";
import { useTranslation } from "react-i18next";
import { View } from "react-native";
import { useTheme } from "react-native-paper";

import { Contest, ContestStatus } from "@app/common/graphql/generated/schema.graphql";
import { Routes, useNavigation } from "@app/common/types/navigation.type";
import { Button } from "@app/components/common/button/button.component";
import { Chip } from "@app/components/common/chip/chip.component";
import { Image } from "@app/components/common/image/image.component";
import { Skeleton } from "@app/components/common/skeleton/skeleton.component";
import { Text } from "@app/components/common/text/text.component";
import { useWindowDimensions } from "@app/hooks/utils/use-window-dimensions.hook";

import { styles } from "./featured-contest.style";

interface Props {
  contest?: Contest;
  loading: boolean;
}

export const FeaturedContest: FC<Props> = ({ contest, loading }) => {
  const { t } = useTranslation();
  const { dark } = useTheme();
  const { navigate } = useNavigation();
  const { width } = useWindowDimensions();

  const timeLeft = contest?.status === ContestStatus.inProgress ? formatDistanceToNowStrict(new Date(contest.endOfVoteDate)) : "";

  if (loading) return <Skeleton width={width} height={width} radius="square" />;
  if (!contest || contest.status !== ContestStatus.inProgress) return null;

  const gradients = dark
    ? ["rgba(0,0,0,0.0)", "rgba(0,0,0,0.0)", "rgba(0,0,0,1)"]
    : ["rgba(255,255,255,0.0)", "rgba(255,255,255,0.0)", "rgba(255,255,255,1)"];

  return (
    <View>
      <Image source={contest.imageUrl} style={styles.imageContainer} />
      <LinearGradient locations={[0, 0.67, 1]} colors={gradients} style={styles.linearGradient} />
      <View style={styles.root}>
        <View style={styles.spacing}>
          <Chip
            label={t("studioMini.projects.featured.title")}
            bgColor="background"
            textColor="primary"
            textVariant="body2"
            style={[styles.chip, styles.chipPadding]}
          />
          <Chip label={`${timeLeft} ${t("contest.nbDaysLeft")}`} color="warning" style={styles.chip} />
          <Text variant="h7" color="primary">
            {contest.name}
          </Text>
          <View style={styles.row}>
            <Text variant="h8">{`${t("contest.prize")}:`}</Text>
            <Text variant="h8" style={styles.rewardText}>
              {t(`enum.contestRewardType.${contest.rewardType}`, { value: contest.rewardValue })}
            </Text>
          </View>
        </View>

        <Button mode="outlined" onPress={() => navigate(Routes.contest, { id: contest.contestId })}>
          {t("studioMini.projects.featured.cta")}
        </Button>
      </View>
    </View>
  );
};
