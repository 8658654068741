import { StyleSheet } from "react-native";

const popperArrowSize = 12;

export const styles = StyleSheet.create({
  creditContainer: {
    alignItems: "center",
    justifyContent: "center",
    rowGap: 8,
  },
  creditTextContainer: {
    alignItems: "center",
  },
  joinMeContainer: {
    alignItems: "center",
    columnGap: 8,
    flexDirection: "row",
    width: "100%",
  },
  joinMePopper: {
    alignItems: "center",
    flexDirection: "row",
    flexShrink: 1,
  },
  joinMePopperArrow: {
    height: popperArrowSize,
    marginRight: -(popperArrowSize / 2),
    transform: [{ rotate: "45deg" }],
    width: popperArrowSize,
  },
  joinMePopperText: {
    flexShrink: 1,
    padding: 12,
  },
  milestoneContainer: {
    alignSelf: "center",
  },
  root: {
    padding: 12,
    paddingBottom: 8,
    rowGap: 16,
  },
  supercoinImage: {
    height: 16,
    width: 16,
  },
});
