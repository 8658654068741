import React, { FC, useCallback, useEffect, useMemo, useReducer } from "react";

import { useApolloClient } from "@apollo/client";
import * as Sentry from "@sentry/react-native";
import { AuthError, User as FirebaseUser, onAuthStateChanged } from "firebase/auth";

import { ChildrenProp } from "@app/common/types/children-prop.interface";
import { homeScreenQueriesAsAnonymous } from "@app/hooks/api/home/use-home.hook";
import { feedQuery } from "@app/hooks/api/use-feed.hook";
import { frontendConfigQuery } from "@app/hooks/api/use-frontend-config.hook";
import { LoginAction, useHandleAuthError } from "@app/hooks/utils/use-handle-auth-error.hook";
import { log } from "@app/utils/logger/logger.util";
import { isTokenEmailVerified } from "@app/utils/token.util";

import { anonymousLDContext, useLaunchDarklyContext } from "../launch-darkly/launch-darkly.context";
import { useLocalStorageContext } from "../local-storage/local-storage.context";
import { LocalStorageKeys } from "../local-storage/local-storage.type";
import { useMixpanelContext } from "../mixpanel/mixpanel.context";

import { AuthContext, initialAuthContext } from "./auth.context";
import { reducer } from "./auth.reducer";
import { AuthContextAction, AuthContextInterface, FirebaseUserInterface } from "./auth.types";
import { getAuthWhenReady } from "./firebase";

export const AuthContextProvider: FC<ChildrenProp> = ({ children }) => {
  const { setLocalStorage } = useLocalStorageContext();
  const client = useApolloClient();
  const { setLDContext } = useLaunchDarklyContext();
  const { mixpanel } = useMixpanelContext();
  const { handleAuthError } = useHandleAuthError();
  const [state, dispatch] = useReducer(reducer, {
    ...initialAuthContext,
  });

  const login = useCallback(
    (payload: FirebaseUserInterface): void => {
      dispatch({ action: AuthContextAction.login, payload });
    },
    [dispatch],
  );

  const clearAuthState = useCallback((): void => {
    Sentry.setUser(null);
    mixpanel?.reset();
    setLDContext(anonymousLDContext);

    setLocalStorage(LocalStorageKeys.anonymousCartId, undefined);
    dispatch({ action: AuthContextAction.logout });
    void client.clearStore().then(() => void client.refetchQueries({ include: [feedQuery, frontendConfigQuery, homeScreenQueriesAsAnonymous] }));
  }, [client, mixpanel, setLDContext, setLocalStorage]);

  const logout = useCallback(async (): Promise<void> => {
    const auth = await getAuthWhenReady();
    await auth.signOut();
    clearAuthState();
    log.info("Logging out user");
  }, [clearAuthState]);

  const updateStateFromFirebaseUser = useCallback(
    (firebaseUser: FirebaseUser | null, forceRefresh?: boolean) => {
      if (firebaseUser) {
        firebaseUser
          .getIdToken(forceRefresh)
          .then((token): void => {
            const verified = isTokenEmailVerified(token);

            login({
              email: firebaseUser.email,
              verified,
              id: firebaseUser.uid,
              token,
              providerData: firebaseUser.providerData,
            });
          })
          .catch(() => {
            void logout();
          });
      } else {
        clearAuthState();
      }
    },
    [clearAuthState, login, logout],
  );

  const setVerified = useCallback(() => {
    getAuthWhenReady()
      .then(auth => updateStateFromFirebaseUser(auth.currentUser, true))
      .catch((error: AuthError) => handleAuthError(error, LoginAction.getAuth));
  }, [handleAuthError, updateStateFromFirebaseUser]);

  const authProviderValue = useMemo<AuthContextInterface>(() => ({ state, logout, setVerified }), [state, logout, setVerified]);

  // keep authContext up to date
  useEffect(() => {
    getAuthWhenReady()
      .then(auth => {
        const unsubscribe = onAuthStateChanged(auth, updateStateFromFirebaseUser);

        return unsubscribe;
      })
      .catch((error: AuthError) => handleAuthError(error, LoginAction.getAuth));
  }, [handleAuthError, updateStateFromFirebaseUser]);

  return <AuthContext.Provider value={authProviderValue}>{children}</AuthContext.Provider>;
};
