import { StyleSheet } from "react-native";

export const styles = StyleSheet.create({
  adList: {
    columnGap: 1,
  },
  root: {
    marginBottom: 24,
    rowGap: 1,
  },
});
