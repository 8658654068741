import { StyleSheet } from "react-native";

export const styles = StyleSheet.create({
  header: {
    rowGap: 8,
  },
  iconContainer: {
    alignItems: "center",
    height: 40,
    justifyContent: "center",
    width: 40,
  },
  root: {
    columnGap: 24,
    justifyContent: "space-between",
    marginHorizontal: 8,
    marginVertical: 4,
  },
  row: {
    alignItems: "center",
    flexDirection: "row",
  },
  searchBar: {
    flexGrow: 1,
  },
  searchButton: {
    borderRadius: 8,
    columnGap: 10,
    flex: 1,
    paddingHorizontal: 12,
    paddingVertical: 10,
  },
});
