import React, { FC } from "react";

import { X } from "phosphor-react-native";
import { useTranslation } from "react-i18next";
import { View } from "react-native";
import { FAB as Fab, useTheme } from "react-native-paper";

import { AvatarImage } from "@app/components/common/avatar-image/avatar-image.component";
import { Button } from "@app/components/common/button/button.component";
import { useProfileContext } from "@app/context/profile/profile.context";
import { useSnackbarContext } from "@app/context/snackbar/snackbar.context";
import { useDeleteProfileImage } from "@app/hooks/api/use-delete-profile-image.hook";
import { useUploadProfileImage } from "@app/hooks/api/use-upload-profile-image.hook";
import { pickImage } from "@app/utils/image-picker.util";
import { generateImageToFormData } from "@app/utils/image-upload.util";
import { reportError } from "@app/utils/logger/logger.util";

import { styles } from "./edit-profile-picture.style";

export const EditProfilePicture: FC = () => {
  const avatarImageSize = 90;

  const { t } = useTranslation();
  const { colors, roundness } = useTheme();
  const { profile } = useProfileContext();
  const { showErrorSnackbar } = useSnackbarContext();

  const { uploadImage, loading: uploadImageLoading } = useUploadProfileImage();
  const { deleteProfileImage, loading: deleteImageLoading } = useDeleteProfileImage();

  const imageLoading = uploadImageLoading || deleteImageLoading;

  const chooseAndUploadImage = async (): Promise<void> => {
    try {
      const image = await pickImage();
      if (image.error || !profile || !image.asset) return;

      const form = await generateImageToFormData(image.asset.uri, image.asset.fileName);
      void uploadImage({
        variables: { input: form, userId: profile.userId },
        onError: error => showErrorSnackbar({ error }),
      });
    } catch (e) {
      reportError(e as Error);
    }
  };

  const handleDelete = (): void => {
    if (!profile?.userId) return;

    void deleteProfileImage({ variables: { userId: profile.userId }, onError: error => showErrorSnackbar({ error }) });
  };

  return (
    <View style={styles.root}>
      {!!profile && (profile.imageUrl || imageLoading) && (
        <View style={styles.imageWithFabContainer}>
          <AvatarImage image={profile.imageUrl} loading={imageLoading} fallbackText="" size={avatarImageSize} />
          <Fab
            onPress={handleDelete}
            icon={X}
            disabled={imageLoading}
            style={[{ backgroundColor: colors.tertiaryContainer, borderRadius: roundness }, styles.deleteButton]}
            size="small"
            mode="elevated"
          />
        </View>
      )}
      <Button mode="outlined" disabled={imageLoading || !!profile?.imageUrl} fullWidth onPress={() => void chooseAndUploadImage()}>
        {t("userInfo.fields.picture.addPicture")}
      </Button>
    </View>
  );
};
