import React, { FC, useCallback, useMemo, useRef } from "react";

import { REACT_APP_BLOOM_WEBSITE } from "@env";
import { useTranslation } from "react-i18next";

import ShareIcon from "@app/assets/icons/share-fill.svg";
import { TargetSocialMedia } from "@app/common/graphql/generated/schema.graphql";
import { BottomSheetRefProps } from "@app/components/common/bottom-sheet/bottom-sheet.types";
import { Button } from "@app/components/common/button/button.component";
import { ShareBottomSheet } from "@app/components/common/share-bottom-sheet/share-bottom-sheet.component";
import { useProfileContext } from "@app/context/profile/profile.context";
import { useReferralLink } from "@app/hooks/api/use-referral-link.hook";
import { useTrackShareReferralLink } from "@app/hooks/api/use-track-share-referral-link.hook";

interface Props {
  shareId: string;
  buttonComponent?: FC<{ onPress: () => void; label: string }>;
}

// eslint-disable-next-line @typescript-eslint/naming-convention
export const ShareReferralLinkButton: FC<Props> = ({ shareId, buttonComponent: ButtonComponent }) => {
  const { t } = useTranslation();
  const { profile } = useProfileContext();

  const sheetRef = useRef<BottomSheetRefProps>(null);

  const handleOnShare = useCallback((): void => {
    sheetRef.current?.open();
  }, []);

  // TODO: once we cleanup the home screen FF, change the path to home
  const url = useMemo(() => `${REACT_APP_BLOOM_WEBSITE}/feed?referrerId=${shareId}`, [shareId]);

  const buildShareLinkVariables = useCallback(
    (targetSocialMedia: TargetSocialMedia) => ({ input: { targetSocialMedia }, userId: profile?.userId ?? "" }),
    [profile?.userId],
  );

  const buildTrackSharingVariables = useCallback(
    (destination: string) => ({ input: { destination }, userId: profile?.userId ?? "" }),
    [profile?.userId],
  );

  return (
    <>
      {ButtonComponent ? (
        <ButtonComponent onPress={handleOnShare} label={t("referral.ctaLabel")} />
      ) : (
        <Button onPress={handleOnShare} icon={props => <ShareIcon width={24} height={24} {...props} />} mode="contained" size="large">
          {t("referral.ctaLabel")}
        </Button>
      )}

      <ShareBottomSheet
        sheetRef={sheetRef}
        url={url}
        message={t("referral.shareMessage")}
        useSocialShareLink={useReferralLink}
        useTrackSharing={useTrackShareReferralLink}
        buildShareLinkVariables={buildShareLinkVariables}
        buildTrackSharingVariables={buildTrackSharingVariables}
      />
    </>
  );
};
