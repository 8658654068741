import React, { FC } from "react";

import { Image } from "expo-image";
import { useTranslation } from "react-i18next";
import { View } from "react-native";
import { useTheme } from "react-native-paper";

import { imageRatio } from "@app/common/constants/image.const";
import { StudioImage } from "@app/common/graphql/generated/schema.graphql";
import { Skeleton } from "@app/components/common/skeleton/skeleton.component";
import { Text } from "@app/components/common/text/text.component";
import { previewWidth, useImage } from "@app/hooks/utils/use-image.hook";
import { useWindowDimensions } from "@app/hooks/utils/use-window-dimensions.hook";

import { styles } from "./studio-image-list-details.style";
import { StudioImageSideIconButtons } from "./studio-image-side-icon-buttons/studio-image-side-icon-buttons.component";

interface Props {
  image?: StudioImage;
  context: "saved" | "explore";
}

export const StudioImageListDetails: FC<Props> = ({ image, context }) => {
  const { t } = useTranslation();
  const { colors } = useTheme();

  const { width: windowWidth } = useWindowDimensions();
  const { getResponsiveImageUrl, getPreviewImageUrl } = useImage({ width: windowWidth });
  const imageWidth = windowWidth;
  const imageHeight = imageWidth * imageRatio;

  const previewSource = { uri: getPreviewImageUrl(image?.imageUrl), width: previewWidth, height: previewWidth };

  return (
    <View style={styles.root}>
      <View>
        <Image
          source={previewSource}
          placeholder={previewSource}
          placeholderContentFit="cover"
          responsivePolicy="initial"
          cachePolicy="memory-disk"
          contentFit="cover"
          style={{ height: imageHeight, backgroundColor: colors.skeleton }}
        />
        <Image
          source={{ uri: getResponsiveImageUrl(image?.imageUrl), width: imageWidth, height: imageHeight }}
          placeholder={previewSource}
          placeholderContentFit="cover"
          cachePolicy="memory-disk"
          contentFit="contain"
          transition={200}
          style={[{ height: imageHeight, width: imageWidth, backgroundColor: colors.skeleton }, styles.image]}
        />
        {image && <StudioImageSideIconButtons image={image} context={context} />}
      </View>

      <View style={styles.textContainer}>
        <Text variant="h7">{t("studioMini.saved.prompt")}</Text>
        <Skeleton width="100%">
          {image?.prompt ? (
            <Text variant="body2" color="tertiary">
              {image?.prompt}
            </Text>
          ) : null}
        </Skeleton>
      </View>
    </View>
  );
};
