import React, { FC, useState } from "react";

import { formatDate } from "date-fns";
import { useTranslation } from "react-i18next";
import { View } from "react-native";
import { useTheme } from "react-native-paper";

import { CollabImage, CollabUpdate, ImageSource } from "@app/common/graphql/generated/schema.graphql";
import { Text } from "@app/components/common/text/text.component";
import { CreatorNameWithAvatar } from "@app/components/profile/creator-name-with-avatar/creator-name-with-avatar.component";
import { useWindowDimensions } from "@app/hooks/utils/use-window-dimensions.hook";

import { ProductImages } from "../../products/product-images/product-images.component";
import { UpdateSideIconButtons } from "../update-side-icon-buttons/update-side-icon-buttons.component";

import { paddingHorizontal, styles } from "./update-details.style";

interface Props {
  update: CollabUpdate;
  index?: number;
}

export const UpdateDetails: FC<Props> = ({ update, index }) => {
  const { t } = useTranslation();
  const { width } = useWindowDimensions();
  const { colors } = useTheme();

  const updateImages: CollabImage[] = update.images.map(i => ({
    collabImageId: i.collabUpdateImageId,
    imageUrl: i.imageUrl,
    source: ImageSource.upload,
    index: 0,
    __typename: "CollabImage",
  }));
  const noImages = !updateImages.length;

  const [currentImage, setCurrentImage] = useState<CollabImage | undefined>(updateImages[0]);

  const contentOnImage = (
    <View style={styles.imageContentContainer}>
      <UpdateSideIconButtons update={update} index={index} currentImage={currentImage} />
    </View>
  );

  return (
    <View style={styles.root}>
      {!!updateImages.length && (
        <ProductImages images={updateImages} collabId={update.collabId} setCurrentImage={setCurrentImage} contentOnImage={contentOnImage} />
      )}

      <View style={styles.textContainer}>
        <Text variant="h7">{update.title}</Text>

        {noImages && (
          <UpdateSideIconButtons
            update={update}
            index={index}
            currentImage={currentImage}
            iconColor={colors.onBackground}
            style={styles.actionsWhenNoImage}
          />
        )}

        <View style={styles.rowSpaceBetween}>
          <Text variant="caption" color="tertiary">
            {index ? t("productDetails.updates.updateWithIndex", { index }) : t("productDetails.updates.update")}
          </Text>
          <Text variant="caption" color="tertiary">
            {formatDate(update.createdAt, t("dateFormat.date"))}
          </Text>
        </View>
        <Text variant="body2">{update.content}</Text>

        <CreatorNameWithAvatar
          profile={update.user}
          imageSize={40}
          textProps={{ variant: "h8", color: "tertiary" }}
          width={width - 2 * paddingHorizontal}
        />
      </View>
    </View>
  );
};
