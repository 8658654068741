import { StyleSheet } from "react-native";

export const styles = StyleSheet.create({
  content: {
    alignItems: "center",
    flex: 1,
    justifyContent: "center",
    rowGap: 16,
  },
  root: {
    bottom: 0,
    left: 0,
    padding: 20,
    position: "absolute",
    right: 0,
    top: 0,
  },
});
