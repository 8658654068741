import { formatDuration } from "date-fns";

export function formatSeconds(givenSeconds: number): string {
  const dateObj = new Date(givenSeconds * 1000);
  const minutes = dateObj.getUTCMinutes();
  const seconds = dateObj.getSeconds();
  const timeFormatted = formatDuration({ minutes, seconds });

  return timeFormatted;
}
