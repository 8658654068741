import React, { FC, useEffect } from "react";

import { GearSix } from "phosphor-react-native";
import { useTranslation } from "react-i18next";
import { ActivityIndicator, IconButton } from "react-native-paper";

import { FeedStackScreenProps, Routes, Tabs } from "@app/common/types/navigation.type";
import { LoginPrompt } from "@app/components/common/login-prompt/login-prompt.component";
import { ScreenWrapper } from "@app/components/common/screen-wrapper/screen-wrapper.component";
import { NotificationList } from "@app/components/notifications/notification-list/notification-list.component";
import { useProfileContext } from "@app/context/profile/profile.context";
import { useIncomingPushNotificationsContext } from "@app/context/push-notifications/incoming/incoming-push-notifications.context";

export const NotificationsScreen: FC<FeedStackScreenProps<Routes.notifications>> = ({ navigation }) => {
  const { t } = useTranslation();
  const { profile, loading: loadingProfile } = useProfileContext();

  const { clearNotifications } = useIncomingPushNotificationsContext();

  useEffect(() => {
    if (profile?.userId) {
      navigation.setOptions({
        headerRight: () => (
          <IconButton
            icon={GearSix}
            onPress={() =>
              navigation.navigate(Routes.root, { screen: Tabs.profile, params: { screen: Routes.notificationSettings, initial: false } })
            }
          />
        ),
      });
    }
  }, [navigation, profile?.userId]);

  useEffect(() => {
    clearNotifications();
  }, [clearNotifications]);

  return (
    <ScreenWrapper>
      {loadingProfile && <ActivityIndicator size="large" />}

      {!profile && !loadingProfile && <LoginPrompt message={{ text: t("loginPrompt.notifications.message") }} />}

      {profile && <NotificationList userId={profile.userId} />}
    </ScreenWrapper>
  );
};
