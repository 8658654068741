import { customFonts } from "@app/common/style/fonts";

import { useWindowDimensions } from "./use-window-dimensions.hook";

interface ProductListItemLayoutProps {
  shouldUseFixedHeight: boolean;
  rowGap: number;
  isOngoingCampaign: boolean;
}

interface ProductListItemLayout {
  numberOfLinesForProductName: number | undefined;
  productDetailsHeight: number | undefined;
}

export function useProductListItemLayout({ shouldUseFixedHeight, rowGap, isOngoingCampaign }: ProductListItemLayoutProps): ProductListItemLayout {
  const { fontScale } = useWindowDimensions();

  const fixedNumberOfLinesForProductName = 2;
  const productNameHeight = fixedNumberOfLinesForProductName * customFonts.caption.lineHeight * fontScale + rowGap;

  const numberOfLinesForDetailedStage = 1;
  const detailedStageHeight = numberOfLinesForDetailedStage * customFonts.caption.lineHeight * fontScale;

  const daysLeftForCampaignBadgeHeight = isOngoingCampaign ? 20 + rowGap : 0;

  const fixedProductDetailsHeight = productNameHeight + detailedStageHeight + daysLeftForCampaignBadgeHeight;

  return {
    numberOfLinesForProductName: shouldUseFixedHeight ? fixedNumberOfLinesForProductName : 3,
    productDetailsHeight: shouldUseFixedHeight ? fixedProductDetailsHeight : undefined,
  };
}
