import React, { FC, useCallback } from "react";

import { useTranslation } from "react-i18next";
import { TextInputProps } from "react-native";
import CurrencyInput from "react-native-currency-input";
import { TextInput as RNTextInput } from "react-native-paper";

import { Button } from "@app/components/common/button/button.component";
import { TextInput } from "@app/components/common/text-input/text-input.component";
import { usePreorderContext } from "@app/context/preorder/preorder.context";
import { formatPriceUSD } from "@app/utils/price.util";

interface Props {
  availableSupercoins: number;
  supercoinLimit: number;
  disabled?: boolean;
}

export const EnterRewardsInput: FC<Props> = ({ availableSupercoins, disabled, supercoinLimit }) => {
  const { t } = useTranslation();
  const {
    input: { supercoinsUsed },
    setInput,
  } = usePreorderContext();

  const setSupercoinsUsed = useCallback(
    (value: number): void => {
      setInput(prev => ({ ...prev, supercoinsUsed: value }));
    },
    [setInput],
  );

  const maxSupercoins = supercoinLimit;
  const hasSupercoins = availableSupercoins > 0;

  const renderTextInput = useCallback(
    (props: TextInputProps) => (
      <TextInput
        {...props}
        selectionColor={undefined}
        cursorColor={undefined}
        disabled={!hasSupercoins || disabled}
        left={color => <RNTextInput.Affix text="$" textStyle={{ color }} />}
        helperText={hasSupercoins ? t("preorder.enterRewardsHelper") : undefined}
        rightContent={
          hasSupercoins ? (
            <Button mode="outlined" size="large" onPress={() => setSupercoinsUsed(maxSupercoins)}>
              {t("preorder.enterRewardsMax", { max: formatPriceUSD(maxSupercoins, { withoutUSD: true }) })}
            </Button>
          ) : undefined
        }
      />
    ),
    [disabled, hasSupercoins, t, maxSupercoins, setSupercoinsUsed],
  );

  return (
    <CurrencyInput
      value={supercoinsUsed}
      onChangeValue={setSupercoinsUsed}
      placeholder={t("preorder.enterRewardsPlaceholder")}
      minValue={0}
      maxValue={maxSupercoins}
      separator="."
      delimiter=","
      precision={0}
      renderTextInput={renderTextInput}
    />
  );
};
