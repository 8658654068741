import React, { FC, ReactNode } from "react";

import { createMaterialBottomTabNavigator } from "@react-navigation/material-bottom-tabs";
import { HouseSimple, IconProps, ShoppingCartSimple, User } from "phosphor-react-native";
import { useTranslation } from "react-i18next";
import { View } from "react-native";
import { useTheme } from "react-native-paper";
import { useSafeAreaInsets } from "react-native-safe-area-context";

import DiscoveryFill from "@app/assets/icons/discovery-fill.svg";
import DiscoveryThin from "@app/assets/icons/discovery-thin.svg";
import PlusSquareFill from "@app/assets/icons/plus-square-fill.svg";
import SwipeFill from "@app/assets/icons/swipe-fill.svg";
import SwipeThin from "@app/assets/icons/swipe-thin.svg";
import { NavigateContext, TrackEvent } from "@app/common/enums/track-events.enum";
import { bottomTabsHeight } from "@app/common/style/theme";
import { RootTabStackParamList, Routes, Tabs, navigationRef, NavigationProps } from "@app/common/types/navigation.type";
import { Popper } from "@app/components/common/popper/popper.component";
import { useCartContext } from "@app/context/cart/cart.context";
import { FeatureFlag } from "@app/context/launch-darkly/feature-flag.enum";
import { useLaunchDarklyContext } from "@app/context/launch-darkly/launch-darkly.context";
import { useMixpanelContext } from "@app/context/mixpanel/mixpanel.context";
import { usePopperContext } from "@app/context/popper/popper.context";
import { useProfileContext } from "@app/context/profile/profile.context";
import { UserStateEnum } from "@app/context/profile/user-state.enum";
import { SearchContextProvider } from "@app/context/search/search.provider";

import { styles } from "./router.style";
import { CartOrdersStack } from "./stacks/cart.stack";
import { FeedStack } from "./stacks/feed.stack";
import { HomeStack } from "./stacks/home.stack";
import { ProfileStack } from "./stacks/profile.stack";
import { TabSearchStack } from "./stacks/search.stack";
import { StudioStack } from "./stacks/studio.stack";

const Tab = createMaterialBottomTabNavigator<RootTabStackParamList>();

export const RootTabStack: FC<NavigationProps<Routes.root>> = () => {
  const { t } = useTranslation();
  const { colors } = useTheme();

  const { isFeatureEnabled } = useLaunchDarklyContext();
  const { cartItems } = useCartContext();
  const { updateState } = useProfileContext();
  const { trackInMixpanel } = useMixpanelContext();
  const insets = useSafeAreaInsets();

  const currentRoute = navigationRef.isReady() ? navigationRef.getCurrentRoute() : undefined;
  const tabsHidden = currentRoute?.name === Routes.productFromFeed;
  const isNewHomePageEnabled = isFeatureEnabled(FeatureFlag.enableNewHomePage);

  const {
    studioPopperState: [studioPopperOpen, setStudioPopperOpen],
  } = usePopperContext();

  const selectTabBarIcon = (name: Tabs, color: string, focused: boolean): ReactNode => {
    const iconProps: IconProps = { weight: focused ? "fill" : "thin", color };
    const Discovery = focused ? DiscoveryFill : DiscoveryThin;
    const Swipe = focused ? SwipeFill : SwipeThin;
    const PlusSquare = PlusSquareFill;

    switch (name) {
      case Tabs.home:
        return <HouseSimple {...iconProps} />;
      case Tabs.feed:
        return <Swipe color={color} height={24} width={24} />;
      case Tabs.search:
        return <Discovery color={color} height={24} width={24} />;
      case Tabs.studio:
        return (
          <Popper
            isOpen={studioPopperOpen}
            setIsOpen={setStudioPopperOpen}
            message={t("onboarding.popper.studio")}
            onClose={() => updateState({ input: { [UserStateEnum.startCreatingPopperDismissed]: true } })}
            backgroundStyle={{ marginBottom: bottomTabsHeight }}>
            <View style={styles.studioButtonContainer}>
              <PlusSquare color={color} height={32} width={32} />
            </View>
          </Popper>
        );
      case Tabs.profile:
        return <User {...iconProps} />;
      case Tabs.cartOrders:
        return <ShoppingCartSimple {...iconProps} />;
      default:
        return undefined;
    }
  };

  return (
    <SearchContextProvider>
      <Tab.Navigator
        activeColor={colors.onBackground}
        inactiveColor={colors.action.active}
        activeIndicatorStyle={styles.activeIndicatorStyle}
        labeled={false}
        barStyle={[
          // eslint-disable-next-line react-native/no-inline-styles
          {
            height: tabsHidden ? 0 : bottomTabsHeight,
            backgroundColor: colors.background,
            borderTopColor: colors.surfaceDisabled,
            justifyContent: insets.bottom > 0 ? undefined : "center",
            display: tabsHidden ? "none" : "flex",
          },
          styles.tabBarStyle,
        ]}
        screenOptions={({ route }) => ({
          tabBarIcon: ({ color, focused }) => selectTabBarIcon(route.name, color, focused),
          style: { height: 30, justifyContent: "center" },
          tabBarButtonTestID: `${route.name}_tab`,
        })}
        screenListeners={{
          tabPress: e =>
            trackInMixpanel(TrackEvent.navigate, { tab: e.target?.split("-")[0].replace("Tab", ""), context: NavigateContext.mainBottomNavigation }),
        }}>
        {isNewHomePageEnabled && (
          <Tab.Screen name={Tabs.home} component={HomeStack} options={{ tabBarAccessibilityLabel: Tabs.home, tabBarTestID: `${Tabs.home}_tab` }} />
        )}
        <Tab.Screen name={Tabs.feed} component={FeedStack} options={{ tabBarAccessibilityLabel: Tabs.feed, tabBarTestID: `${Tabs.feed}_tab` }} />
        {!isNewHomePageEnabled && (
          <Tab.Screen
            name={Tabs.search}
            component={TabSearchStack}
            options={{ tabBarAccessibilityLabel: Tabs.search, tabBarTestID: `${Tabs.search}_tab` }}
          />
        )}
        <Tab.Screen
          name={Tabs.studio}
          component={StudioStack}
          options={{ tabBarAccessibilityLabel: Tabs.studio, tabBarTestID: `${Tabs.studio}_tab` }}
        />
        <Tab.Screen
          name={Tabs.profile}
          component={ProfileStack}
          options={{ tabBarAccessibilityLabel: Tabs.profile, tabBarTestID: `${Tabs.profile}_tab` }}
        />
        <Tab.Screen
          name={Tabs.cartOrders}
          component={CartOrdersStack}
          options={{
            tabBarBadge: cartItems?.length ? cartItems.map(item => item.quantity).reduce((a, b) => a + b) : undefined,
            tabBarAccessibilityLabel: Tabs.cartOrders,
            tabBarTestID: `${Tabs.cartOrders}_tab`,
          }}
        />
      </Tab.Navigator>
    </SearchContextProvider>
  );
};
