import { QueryHookOptions, gql, useQuery } from "@apollo/client";

import { behindTheScenesVideoFields } from "@app/common/graphql/fragments.graphql";
import { PaginatedBehindTheScenesVideos } from "@app/common/graphql/generated/schema.graphql";
import { QueryResult } from "@app/common/types/apollo-result.type";

interface Response {
  videos: PaginatedBehindTheScenesVideos;
}

interface Variables {
  first?: number;
  offset?: number;
}

const behindTheScenesVideosQuery = gql`
  query BehindTheScenesVideos($first: Int, $offset: Int) {
    videos: behindTheScenesVideos(first: $first, offset: $offset) {
      nodes {
        ...BehindTheScenesVideoFields
      }
      pageInfo {
        hasNextPage
      }
      totalCount
    }
  }
  ${behindTheScenesVideoFields}
`;

export function useBehindTheScenesVideos(options?: QueryHookOptions<Response, Variables>): QueryResult<Response, "videos"> {
  const { data, loading, error, fetchMore, networkStatus } = useQuery<Response, Variables>(behindTheScenesVideosQuery, {
    notifyOnNetworkStatusChange: true,
    ...options,
  });

  return { data: data?.videos, loading, error, fetchMore, networkStatus };
}
