import { useMemo } from "react";

import { useTranslation } from "react-i18next";

import {
  ConceptsSortingMethod,
  ForSaleCollabsSortingMethod,
  InDevelopmentCollabsSortingMethod,
  UnderReviewCollabsSortingMethod,
} from "@app/common/graphql/generated/schema.graphql";
import { HomeSectionType, HomeSortByType } from "@app/components/home/home.type";

interface Response {
  filters: { value: HomeSortByType; label: string }[];
}

export const useSeeAllSortingMethods = (type?: HomeSectionType): Response => {
  const { t } = useTranslation();

  const filters = useMemo(() => {
    if (!type) return [];

    switch (type) {
      case HomeSectionType.concepts:
        return [
          { value: ConceptsSortingMethod.newest, label: t("enum.sort.newest") },
          { value: ConceptsSortingMethod.trending, label: t("enum.sort.trending") },
          { value: ConceptsSortingMethod.mostPopular, label: t("enum.sort.mostPopular") },
        ];
      case HomeSectionType.forSaleCollabs:
        return [
          { value: ForSaleCollabsSortingMethod.newest, label: t("enum.sort.newest") },
          { value: ForSaleCollabsSortingMethod.bestselling, label: t("enum.sort.bestselling") },
          { value: ForSaleCollabsSortingMethod.fewestLeft, label: t("enum.sort.fewestLeft") },
        ];
      case HomeSectionType.inDevelopmentCollabs:
        return [
          { value: InDevelopmentCollabsSortingMethod.newest, label: t("enum.sort.newest") },
          { value: InDevelopmentCollabsSortingMethod.trending, label: t("enum.sort.trending") },
        ];
      case HomeSectionType.underReviewCollabs:
        return [
          { value: UnderReviewCollabsSortingMethod.newest, label: t("enum.sort.newest", { context: "underReview" }) },
          { value: UnderReviewCollabsSortingMethod.trending, label: t("enum.sort.trending") },
          { value: UnderReviewCollabsSortingMethod.closestToGoal, label: t("enum.sort.closestToGoal") },
          { value: UnderReviewCollabsSortingMethod.closestToCampaignEnd, label: t("enum.sort.closestToCampaignEnd") },
        ];
      default:
        return [];
    }
  }, [t, type]);

  return { filters };
};
