/* eslint-disable complexity */
import React, { FC, useCallback, useEffect } from "react";

import { useTranslation } from "react-i18next";
import { View } from "react-native";
import { ActivityIndicator } from "react-native-paper";

import { isErrorCode } from "@app/common/apollo/apollo.utils";
import { ProfileTab } from "@app/common/enums/profile-tab.enum";
import { ProfileStackScreenProps, Routes } from "@app/common/types/navigation.type";
import { Head } from "@app/components/common/head/head.component";
import { LoginPrompt } from "@app/components/common/login-prompt/login-prompt.component";
import { ScreenWrapper } from "@app/components/common/screen-wrapper/screen-wrapper.component";
import { ShareButton } from "@app/components/common/share-button/share-button.component";
import { CreatorName } from "@app/components/profile/creator-name/creator-name.component";
import { Profile } from "@app/components/profile/profile/profile.component";
import { ProfileMoreButton } from "@app/components/profile/profile-more-button/profile-more-button.component";
import { useAuthContext } from "@app/context/auth/auth.context";
import { useProfileContext } from "@app/context/profile/profile.context";
import { useSnackbarContext } from "@app/context/snackbar/snackbar.context";
import { useProfile } from "@app/hooks/api/use-profile.hook";
import { useWindowDimensions } from "@app/hooks/utils/use-window-dimensions.hook";
import { conditionalItem } from "@app/utils/conditional-item-in-array.util";
import { isUserId } from "@app/utils/id.util";

import { styles } from "./profile.style";

const profileTabs = [ProfileTab.creations, ProfileTab.likes, ProfileTab.supercoins];

export const ProfileScreen: FC<ProfileStackScreenProps<Routes.profile>> = ({ navigation, route }) => {
  const headerRightWidth = 100;
  const { params } = route;
  const { t } = useTranslation();
  const { width } = useWindowDimensions();
  const { showErrorSnackbar } = useSnackbarContext();

  const { state } = useAuthContext();
  const { profile: currentProfile, loading: loadingCurrentProfile } = useProfileContext();
  const { data, loading, refetch } = useProfile({
    variables: { where: params?.id ? (isUserId(params?.id) ? { userId: params?.id } : { username: params?.id }) : {} },
    skip: !params?.id,
    onError: error => {
      if (error.message === "Not Found" || isErrorCode(error, "INVALID_SCALAR")) {
        navigation.replace(Routes.notFound);
        return;
      }
      showErrorSnackbar({ refetch, error });
    },
  });

  const profile = params?.id ? data : currentProfile;
  const isCurrentUser = !!profile && !!currentProfile && profile.userId === currentProfile.userId;
  const isCurrentUserAndAnonymous = !params?.id && !loadingCurrentProfile && !currentProfile;
  const initialTab = params?.tab && typeof params.tab === "string" && profileTabs.includes(parseInt(params.tab)) ? parseInt(params.tab) : params?.tab;

  const headerRight = useCallback(
    () => (
      <View style={styles.row}>
        {profile && (
          <ShareButton
            title={profile?.displayName}
            entityId={profile.userId}
            prettyId={profile.username}
            entityType="profile"
            style={styles.iconButton}
          />
        )}
        <ProfileMoreButton profile={profile} isCurrentUser={!params?.id || isCurrentUser} />
      </View>
    ),
    [isCurrentUser, params?.id, profile],
  );

  useEffect(() => {
    navigation.setOptions({
      title: profile?.username,
      headerRight,
      headerTitle: profile
        ? ({ children }) => (
            <CreatorName
              name={children}
              badge={profile.badge}
              textProps={{ variant: "h8", numberOfLines: 1, maxWidth: width - headerRightWidth * 2 }}
            />
          )
        : undefined,
    });
  }, [profile, navigation, params?.id, state, headerRight, width]);

  return (
    <ScreenWrapper>
      {(loadingCurrentProfile || (params?.id && loading)) && <ActivityIndicator size="large" />}

      {profile && (
        <>
          <Head
            meta={[
              { property: "og:type", content: "profile" },
              { property: "og:title", content: profile.displayName },
              { property: "og:description", content: profile.bio ?? "" },
              { property: "og:image", content: profile.imageUrl ?? "" },
              { name: "twitter:card", content: "summary_large_image" },
              { name: "twitter:site", content: "@offscriptmtl" },
              ...conditionalItem(!!profile.twitterLink, {
                name: "twitter:creator",
                content: profile.twitterLink?.replace("https://twitter.com/", "@"),
              }),
            ]}
          />

          <Profile profile={profile} isCurrentUser={isCurrentUser} initialTab={initialTab} />
        </>
      )}
      {isCurrentUserAndAnonymous && <LoginPrompt message={{ text: t("loginPrompt.profile.message") }} onTopOfEverything={false} />}
    </ScreenWrapper>
  );
};
