import React, { FC, useState } from "react";

import { formatDate } from "date-fns";
import { RocketLaunch } from "phosphor-react-native";
import { Trans, useTranslation } from "react-i18next";
import { View } from "react-native";

import FinancialQuote from "@app/assets/icons/financial-quote.svg";
import { ChildrenProp } from "@app/common/types/children-prop.interface";
import { Routes, useNavigation } from "@app/common/types/navigation.type";
import { Button, ButtonProps } from "@app/components/common/button/button.component";
import { Text } from "@app/components/common/text/text.component";
import { useProfileContext } from "@app/context/profile/profile.context";
import { useSnackbarContext } from "@app/context/snackbar/snackbar.context";
import { useQuoteRequestStatus } from "@app/hooks/api/products/use-quote-request-status.hook";
import { useRequestQuoteForCollab } from "@app/hooks/api/products/use-request-quote-for-collab.hook";
import { StartCampaignProps } from "@app/hooks/utils/use-start-campaign-button.hook";
import { accessRoleSuperplanMap } from "@app/utils/access-role.util";
import { getTitleCase } from "@app/utils/convert-to-title-case.util";

import { StartCampaignConfirmationDialog } from "../start-campaign-confirmation-dialog/start-campaign-confirmation-dialog.component";

import { styles } from "./start-campaign-button.style";

interface Props extends StartCampaignProps, Omit<ButtonProps, "children"> {
  collabId: string;
}

export const StartCampaignButton: FC<Props> = ({ collabId, progress, containerStyle, ...buttonProps }) => {
  const { t } = useTranslation();
  const { showErrorSnackbar } = useSnackbarContext();
  const navigation = useNavigation();

  const { featureAccesses } = useProfileContext();
  const { requestQuoteForCollab, loading: loadingRequestQuote } = useRequestQuoteForCollab();
  const { data: quoteRequestStatus, loading: loadingQuoteRequestStatus } = useQuoteRequestStatus({
    variables: { collabId },
    skip: !progress.canRequestQuote || featureAccesses?.moreQuoteRequests.allowed,
  });

  const requiredRoleForMoreQuotes = featureAccesses?.moreQuoteRequests.requiredRole;
  const requiredSuperplanForMoreQuotes = requiredRoleForMoreQuotes ? accessRoleSuperplanMap[requiredRoleForMoreQuotes] : undefined;
  const loading = loadingRequestQuote || loadingQuoteRequestStatus;
  const hasRequestedMaxQuotes = quoteRequestStatus?.canRequestQuote === false;
  const [confirmationDialogVisible, setConfirmationDialogVisible] = useState(false);

  const handlePress = (): void => {
    if (progress.canRequestQuote) {
      void requestQuoteForCollab({
        variables: { collabId },
        onError: error => showErrorSnackbar({ error }),
      });
    } else {
      setConfirmationDialogVisible(true);
    }
  };

  const handleDismiss = (): void => {
    setConfirmationDialogVisible(false);
  };

  return (
    <>
      <View style={[containerStyle, styles.root]}>
        <Button
          {...buttonProps}
          loading={loading}
          disabled={progress.waitingForQuote || hasRequestedMaxQuotes}
          icon={iconProps => (progress.canRequestQuote ? <FinancialQuote {...iconProps} /> : <RocketLaunch {...iconProps} weight="fill" />)}
          onPress={handlePress}>
          {t(`startCampaign.${progress.canRequestQuote || hasRequestedMaxQuotes ? "askForAQuote" : "label"}`)}
        </Button>
        {hasRequestedMaxQuotes && (
          <Trans
            i18nKey="startCampaign.quoteRequestLimit"
            values={{
              quoteRequestsPerWeek: quoteRequestStatus?.quoteRequestsPerWeek,
              nextQuoteRequestAvailableAt: quoteRequestStatus?.nextQuoteRequestAvailableAt
                ? formatDate(new Date(quoteRequestStatus?.nextQuoteRequestAvailableAt), t("dateFormat.date"))
                : "",
              requiredSuperplanForMoreQuotes: getTitleCase(requiredSuperplanForMoreQuotes),
            }}
            parent={({ children }: ChildrenProp) => (
              <Text variant="helperText" color="tertiary">
                {children}
              </Text>
            )}
            components={{
              sLink: (
                <Text
                  variant="helperText"
                  color="tertiary"
                  textDecorationLine="underline"
                  onPress={() => navigation.navigate(Routes.superplans, { tab: requiredSuperplanForMoreQuotes })}>
                  <></>
                </Text>
              ),
            }}
          />
        )}
      </View>

      <StartCampaignConfirmationDialog collabId={collabId} visible={confirmationDialogVisible} onDismiss={handleDismiss} />
    </>
  );
};
