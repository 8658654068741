import { QueryHookOptions, gql, useQuery } from "@apollo/client";

import { collabFields } from "@app/common/graphql/fragments.graphql";
import { PaginatedCollabs, InDevelopmentCollabsSortingMethod, InDevelopmentCollabsFilteringArgs } from "@app/common/graphql/generated/schema.graphql";
import { QueryResult } from "@app/common/types/apollo-result.type";

interface Response {
  inDevelopmentCollabs: PaginatedCollabs;
}

interface Variables {
  first?: number;
  offset?: number;
  sortBy: InDevelopmentCollabsSortingMethod;
  where?: InDevelopmentCollabsFilteringArgs;
}

const inDevelopmentCollabsQuery = gql`
  query InDevelopmentCollabs($first: Int, $offset: Int, $sortBy: InDevelopmentCollabsSortingMethod!, $where: InDevelopmentCollabsFilteringArgs) {
    inDevelopmentCollabs(first: $first, offset: $offset, sortBy: $sortBy, where: $where) {
      nodes {
        ...CollabFields
      }
      pageInfo {
        hasNextPage
      }
      totalCount
    }
  }
  ${collabFields}
`;

export function useInDevelopmentCollabs(options: QueryHookOptions<Response, Variables>): QueryResult<Response, "inDevelopmentCollabs"> {
  const { data, loading, error, fetchMore, networkStatus } = useQuery<Response, Variables>(inDevelopmentCollabsQuery, {
    notifyOnNetworkStatusChange: true,
    ...options,
  });

  return { data: data?.inDevelopmentCollabs, loading, error, fetchMore, networkStatus };
}
