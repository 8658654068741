import React, { FC, useCallback, useEffect } from "react";

import { useHeaderHeight } from "@react-navigation/elements";
import { LinearGradient } from "expo-linear-gradient";
import { Trophy, X } from "phosphor-react-native";
import { useTranslation } from "react-i18next";
import { LayoutChangeEvent, NativeScrollEvent, NativeSyntheticEvent } from "react-native";
import { IconButton, useTheme } from "react-native-paper";
import Animated, { useAnimatedStyle, useDerivedValue, useSharedValue, withTiming } from "react-native-reanimated";

import { isNotFound } from "@app/common/apollo/apollo.utils";
import { ContestStatus, Stage } from "@app/common/graphql/generated/schema.graphql";
import { darkTheme } from "@app/common/style/theme";
import { NavigationProps, Routes } from "@app/common/types/navigation.type";
import { EmptyState } from "@app/components/common/empty-state/empty-state.component";
import { Image } from "@app/components/common/image/image.component";
import { ScreenWrapper } from "@app/components/common/screen-wrapper/screen-wrapper.component";
import { ShareButton } from "@app/components/common/share-button/share-button.component";
import { ShareMore } from "@app/components/common/share-more/share-more.component";
import { Skeleton } from "@app/components/common/skeleton/skeleton.component";
import { ContestListItem } from "@app/components/contests/contest-list-item/contest-list-item.component";
import { EditContestFab } from "@app/components/god-mode/edit-contest-fab/edit-contest-fab.component";
import { ProductList } from "@app/components/products/product-list/product-list.component";
import { useSnackbarContext } from "@app/context/snackbar/snackbar.context";
import { useCollabsInContest } from "@app/hooks/api/contests/use-collabs-in-contest.hook";
import { useContest } from "@app/hooks/api/contests/use-contest.hook";
import { useWindowDimensions } from "@app/hooks/utils/use-window-dimensions.hook";
import { isContestId } from "@app/utils/id.util";

import { styles } from "./contest-entries.style";

export const ContestEntriesScreen: FC<NavigationProps<Routes.contestEntries>> = ({ navigation, route }) => {
  const { colors } = useTheme();
  const { t } = useTranslation();
  const { width: wWidth } = useWindowDimensions();
  const { showErrorSnackbar } = useSnackbarContext();

  const navHeight = useHeaderHeight();
  const headerPaddingBottom = 12;
  const initialHeaderHeight = 180;
  const animatedHeaderHeight = useSharedValue(initialHeaderHeight);
  const scrollOffsetY = useSharedValue(0);

  const { id: contestIdOrHandle, fromFeed } = route.params;

  const {
    data: contest,
    loading,
    refetch,
  } = useContest({
    variables: contestIdOrHandle ? (isContestId(contestIdOrHandle) ? { contestId: contestIdOrHandle } : { handle: contestIdOrHandle }) : undefined,
    onError: error => {
      if (isNotFound(error)) {
        navigation.replace(Routes.notFound);
        return;
      }
      showErrorSnackbar({ refetch, error });
    },
  });
  const contestId = isContestId(contestIdOrHandle) ? contestIdOrHandle : contest?.contestId;

  const titleContestFromSearch = contest?.status === ContestStatus.completed ? t("contest.results") : t("contest.vote");

  const onLayout = (event: LayoutChangeEvent): void => {
    animatedHeaderHeight.value = event.nativeEvent.layout.height;
  };

  const headerRight = useCallback(
    () =>
      contest && !fromFeed ? (
        <ShareButton
          title={contest.name}
          entityId={contest.contestId}
          prettyId={contest.handle}
          entityType="contestEntries"
          iconColor={colors.common.white}
        />
      ) : (
        <IconButton icon={X} onPress={() => navigation.goBack()} iconColor={colors.common.white} />
      ),
    [colors.common.white, contest, fromFeed, navigation],
  );

  useEffect(() => {
    navigation.setOptions({
      title: fromFeed ? t("contest.leaderboard") : titleContestFromSearch,
      headerTintColor: colors.common.white,
      headerRight,
    });
  }, [colors.common.white, fromFeed, headerRight, navigation, t, titleContestFromSearch]);

  const animatedGradientStyle = useAnimatedStyle(() => ({
    height: withTiming(navHeight + animatedHeaderHeight.value + headerPaddingBottom),
  }));

  const scrollHandler = useCallback(
    ({ nativeEvent }: NativeSyntheticEvent<NativeScrollEvent>) => {
      scrollOffsetY.value = nativeEvent.contentOffset.y;
    },
    [scrollOffsetY],
  );

  const headerCollapsed = useDerivedValue(() => scrollOffsetY.value > 20);

  return (
    <>
      <Animated.View style={[animatedGradientStyle, styles.gradient]}>
        {contest && <Image source={contest?.imageUrl} width={wWidth} style={styles.image} />}
        <LinearGradient locations={[0, 1]} colors={["rgba(0,0,0,0.2)", "rgba(0,0,0,0.8)"]} style={[styles.gradient, styles.image]} />
      </Animated.View>

      <ScreenWrapper style={{ marginTop: navHeight }} staticContent={contestId ? <EditContestFab contestId={contestId} /> : undefined}>
        <Animated.View style={[styles.header, { paddingBottom: headerPaddingBottom }]}>
          {contest && (
            <ContestListItem contest={contest} pointerEvents="none" left={null} collapsed={headerCollapsed} theme={darkTheme} onLayout={onLayout} />
          )}
          {!contest && loading && <Skeleton height={initialHeaderHeight} width="100%" radius="square" colorMode="dark" />}
        </Animated.View>

        <ProductList
          useProducts={useCollabsInContest}
          variables={{ where: { contestId: contestId ?? "" } }}
          listItemProps={product => ({
            hideStageChip: product.progress.stage !== Stage.forSale,
          })}
          scrollEnabled
          emptyState={<EmptyState icon={Trophy} message={t("emptyState.contestEntries.message")} />}
          contentContainerStyle={{ backgroundColor: colors.background }}
          forceLoading={!contest}
          onScroll={scrollHandler}
          scrollEventThrottle={100}
          ListFooterComponent={<ShareMore />}
          ListFooterComponentStyle={styles.footer}
        />
      </ScreenWrapper>
    </>
  );
};
