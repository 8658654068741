import { StyleSheet } from "react-native";

export const styles = StyleSheet.create({
  itemWrapper: {
    flex: 1,
  },
  rewardChip: {
    alignSelf: "flex-start",
    marginHorizontal: 8,
  },
});
