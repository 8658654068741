import { gql, useMutation, MutationFunctionOptions, FetchResult } from "@apollo/client";

import { Collab, FeaturedCollabStage } from "@app/common/graphql/generated/schema.graphql";
import { MutationResult } from "@app/common/types/apollo-result.type";

interface Response {
  setFeaturedCollab: Collab;
}

interface Variables {
  collabId: string;
  stage: FeaturedCollabStage;
}

interface SetFeaturedCreatorResult extends MutationResult<Response, "setFeaturedCollab"> {
  setFeaturedCollab: (options?: MutationFunctionOptions<Response, Variables>) => Promise<FetchResult>;
}

const setFeaturedCollabMutation = gql`
  mutation SetFeaturedCollab($collabId: CollabId!, $stage: FeaturedCollabStage!) {
    setFeaturedCollab(collabId: $collabId, input: { stage: $stage, sortingMethod: newest }) {
      ... on Collab {
        collabId
      }
    }
  }
`;

export function useSetFeaturedCollab(): SetFeaturedCreatorResult {
  const [setFeaturedCollab, { loading, error, data }] = useMutation<Response, Variables>(setFeaturedCollabMutation);

  return { setFeaturedCollab, loading, error, data: data?.setFeaturedCollab };
}
