import { StyleSheet } from "react-native";

export const styles = StyleSheet.create({
  creatorNameRow: {
    alignItems: "center",
    columnGap: 8,
    flexDirection: "row",
    marginLeft: 8,
  },
  itemWrapper: {
    flex: 1,
  },
  skeletonsWrapper: {
    marginBottom: 8,
    rowGap: 4,
  },
});
