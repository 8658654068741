import React, { FC } from "react";

import { createNativeStackNavigator } from "@react-navigation/native-stack";

import { StudioStackParamList } from "@app/common/types/navigation/stack-param-list.type";
import { RootTabStackScreenProps, Routes, Tabs } from "@app/common/types/navigation.type";
import { StudioScreen } from "@app/screens/studio-mini/studio/studio.screen";

import { commonScreenOptions } from "../router.const";

const Stack = createNativeStackNavigator<StudioStackParamList>();

export const StudioStack: FC<RootTabStackScreenProps<Tabs.studio>> = ({ route }) => {
  return (
    <Stack.Navigator initialRouteName={route?.params?.screen ?? Routes.studio} screenOptions={{ ...commonScreenOptions(), title: "" }}>
      <Stack.Screen name={Routes.studio} component={StudioScreen} options={{ headerShown: false }} />
    </Stack.Navigator>
  );
};
