import { createContext, useContext } from "react";

import { FeatureFlag } from "./feature-flag.enum";
import { LaunchDarklyContextInterface } from "./launch-darkly";

export const LaunchDarklyContext = createContext<LaunchDarklyContextInterface>({
  loading: false,
  isFeatureEnabled: (_name: FeatureFlag) => false,
  setLDContext: () => undefined,
});

export const anonymousLDContext = {
  kind: "user",
  key: "anonymous",
  anonymous: true,
};

export const useLaunchDarklyContext = (): LaunchDarklyContextInterface => useContext(LaunchDarklyContext);
