import { StyleSheet } from "react-native";

export const styles = StyleSheet.create({
  buttonWithCountRow: {
    alignItems: "center",
    columnGap: 2,
    flexDirection: "row",
  },
  buttonsRowWrapper: {
    alignItems: "center",
    columnGap: 4,
    flexDirection: "row",
    height: 20,
    justifyContent: "flex-end",
    marginTop: "auto",
  },
  root: {
    flex: 1,
    paddingHorizontal: 20,
    paddingVertical: 12,
    rowGap: 16,
  },
  rootWrapper: {
    flex: 1,
  },
  rowSpaceBetween: {
    alignItems: "center",
    flexDirection: "row",
    justifyContent: "space-between",
    width: "100%",
  },
  textContainer: {
    flex: 1,
  },
  updateImage: {
    borderRadius: 0,
  },
  updateListItemContentContainer: {
    alignItems: "flex-start",
    rowGap: 8,
  },
});
