import React, { forwardRef, useCallback, useImperativeHandle, useRef } from "react";

import { Coins } from "phosphor-react-native";
import { Keyboard, View } from "react-native";
import { IconButton, useTheme } from "react-native-paper";

import { BottomSheetRefProps } from "@app/components/common/bottom-sheet/bottom-sheet.types";
import { Text } from "@app/components/common/text/text.component";
import { useImageGenerationCredit } from "@app/hooks/api/use-image-generation-credits.hook";

import { BottomSheetCreditInfo } from "./bottom-sheet-credit-info/bottom-sheet-credit-info.component";
import { styles } from "./studio-token-button.style";

export interface StudioTokenButtonRefProps {
  openStudioTokenBottomSheet: () => void;
}

interface Props {
  onOpen?: () => void;
  onClose?: () => void;
}

export const StudioTokenButton = forwardRef<StudioTokenButtonRefProps, Props>(({ onOpen, onClose }, ref) => {
  const sheetRef = useRef<BottomSheetRefProps>(null);
  const { colors } = useTheme();

  const { data: imageCredits, loading: loadingCredit } = useImageGenerationCredit();

  const creditLeft = !imageCredits ? "0" : imageCredits.unlimited ? "∞" : imageCredits.tokensLeft.toString();

  const handleOpenBottomSheet = useCallback((): void => {
    Keyboard.dismiss();
    onOpen?.();
    sheetRef.current?.open();
  }, [onOpen]);

  const handleCloseBottomSheet = useCallback((): void => {
    sheetRef.current?.close();
    onClose?.();
  }, [onClose]);

  useImperativeHandle(ref, () => ({ openStudioTokenBottomSheet: handleOpenBottomSheet }), [handleOpenBottomSheet]);

  return !loadingCredit ? (
    <View>
      <View style={styles.creditButtonContainer}>
        <IconButton
          iconColor={creditLeft === "0" ? colors.error : colors.primary}
          icon={iconProps => <Coins {...iconProps} weight="fill" />}
          onPress={handleOpenBottomSheet}
          style={styles.iconButton}
          aria-label="credit-info"
        />
        <Text variant="caption" style={styles.creditButtonOverlay}>
          {creditLeft}
        </Text>
      </View>

      <BottomSheetCreditInfo onClose={handleCloseBottomSheet} imageCredits={imageCredits} onDismiss={onClose} ref={sheetRef} />
    </View>
  ) : null;
});

StudioTokenButton.displayName = "StudioTokenButton";
