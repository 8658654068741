import React from "react";

import { ArrowLineUp, ChatCenteredDots, HeartStraight, RocketLaunch } from "phosphor-react-native";

import HandCoins from "@app/assets/icons/hand-coins-fill.svg";
import HandHeart from "@app/assets/icons/hand-heart-fill.svg";
import ShareIcon from "@app/assets/icons/share-fill.svg";
import SwipeIcon from "@app/assets/icons/swipe-fill.svg";
import { MilestoneType } from "@app/common/graphql/generated/schema.graphql";
import { AvatarImageType } from "@app/components/common/avatar-image/avatar-image.component";
import { HomeCollabSectionType, HomeSectionType } from "@app/components/home/home.type";

export const milestoneTypeIconSize = 24;
const innerIconSize = 21;

// Make sure to add the string under home.reward.[HomeSectionType], for every HomeSectionType associated to a MilestoneType
export const milestoneTypeHomeSectionMap: { [key in HomeCollabSectionType]?: MilestoneType } = {
  [HomeSectionType.inDevelopmentCollabs]: MilestoneType.preorderPuchaseMilestone,
};

export const milestoneTypeIconMap: Record<MilestoneType, AvatarImageType> = {
  [MilestoneType.collabLikeMilestone]: ({ color }: { color: string }) => <HeartStraight color={color} weight="fill" size={milestoneTypeIconSize} />,
  [MilestoneType.collabMilestone]: ({ color }: { color: string }) => <ArrowLineUp color={color} weight="fill" size={milestoneTypeIconSize} />,
  [MilestoneType.commentMilestone]: ({ color }: { color: string }) => <ChatCenteredDots color={color} weight="fill" size={milestoneTypeIconSize} />,
  [MilestoneType.launchCampaignMilestone]: ({ color }: { color: string }) => (
    <RocketLaunch color={color} weight="fill" size={milestoneTypeIconSize} />
  ),
  [MilestoneType.preorderPuchaseMilestone]: ({ color }: { color: string }) => (
    <HandCoins color={color} height={innerIconSize} width={innerIconSize} />
  ),
  [MilestoneType.referralMilestone]: ({ color }: { color: string }) => <HandHeart color={color} height={innerIconSize} width={innerIconSize} />,
  [MilestoneType.shareMilestone]: ({ color }: { color: string }) => (
    <ShareIcon color={color} height={milestoneTypeIconSize} width={milestoneTypeIconSize} />
  ),
  [MilestoneType.swipeMilestone]: ({ color }: { color: string }) => <SwipeIcon color={color} height={innerIconSize} width={innerIconSize} />,
};

export const handledMilestoneTypes = [
  MilestoneType.collabLikeMilestone,
  MilestoneType.collabMilestone,
  MilestoneType.commentMilestone,
  MilestoneType.launchCampaignMilestone,
  MilestoneType.preorderPuchaseMilestone,
  MilestoneType.referralMilestone,
  MilestoneType.shareMilestone,
  MilestoneType.swipeMilestone,
];
