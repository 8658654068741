import { StyleSheet } from "react-native";

export const styles = StyleSheet.create({
  buttonContent: {
    flexDirection: "row-reverse",
  },
  container: {
    alignItems: "center",
    padding: 16,
    rowGap: 24,
  },
  dismissButton: {
    position: "absolute",
    right: 0,
    top: 0,
  },
  middleText: {
    flex: 1,
    justifyContent: "flex-start",
  },
});
