import { StyleSheet } from "react-native";

import { imageRatio } from "@app/common/constants/image.const";

export const styles = StyleSheet.create({
  chip: {
    borderRadius: 0,
    left: 0,
    paddingHorizontal: 1,
    paddingVertical: 4,
    position: "absolute",
    top: 0,
  },
  content: {
    bottom: 0,
    flex: 1,
    flexDirection: "row",
    left: 0,
    margin: 8,
    overflow: "hidden",
    position: "absolute",
    right: 0,
  },
  creatorName: {
    columnGap: 4,
    flexDirection: "row-reverse",
  },
  displayName: {
    flexShrink: 1,
  },
  gradient: {
    height: "100%",
    position: "absolute",
    width: "100%",
  },
  image: {
    aspectRatio: imageRatio,
  },
  root: {
    flex: 1,
  },
  username: {
    fontFamily: "PPNeueMontreal_500Medium",
    fontWeight: "500",
  },
});
