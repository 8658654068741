import React, { ForwardedRef, JSX, forwardRef, useCallback, useState } from "react";

import { useTranslation } from "react-i18next";

import HeartStraight from "@app/assets/icons/heart-straight-with-eye-slash-fill.svg";
import { Stage, LikeSortingMethod, Collab } from "@app/common/graphql/generated/schema.graphql";
import { Routes, Tabs, useNavigation } from "@app/common/types/navigation.type";
import { EmptyState } from "@app/components/common/empty-state/empty-state.component";
import { SegmentedButtons } from "@app/components/common/segmented-buttons/segmented-buttons.component";
import { OpenVariantPickerButton } from "@app/components/products/open-variant-picker-button/open-variant-picker-button.component";
import { ProductList, ProductListRefProps } from "@app/components/products/product-list/product-list.component";
import { useProfileContext } from "@app/context/profile/profile.context";
import { useLikes } from "@app/hooks/api/likes/use-likes.hook";
import { getBrowseOptionFromStage } from "@app/utils/map-stage-browse-option.util";

import { styles } from "./like-list.style";

type LikeTab = Stage.concept | Stage.underReview | Stage.forSale;

interface Props {}

const LikeListInner = (_props: Props, ref: ForwardedRef<ProductListRefProps>): JSX.Element => {
  const { t } = useTranslation();
  const navigation = useNavigation();
  const { profile, loading: loadingProfile } = useProfileContext();

  const [stage, setStage] = useState<LikeTab>(Stage.concept);

  const handleTabChange = useCallback(
    (value: string): void => {
      setStage(value as LikeTab);
    },
    [setStage],
  );

  const handleBrowse = (): void => {
    navigation.navigate(Routes.root, {
      screen: Tabs.search,
      params: {
        screen: Routes.search,
        params: {
          browseOption: getBrowseOptionFromStage(stage),
        },
        initial: false,
      },
    });
  };

  const likeTabs = (
    <SegmentedButtons
      value={stage}
      onValueChange={handleTabChange}
      buttons={[
        {
          value: Stage.concept,
          label: t(`products.stage.${Stage.concept}`),
        },
        {
          value: Stage.underReview,
          label: t(`products.stage.${Stage.underReview}`),
        },
        {
          value: Stage.forSale,
          label: t(`products.stage.${Stage.forSale}`),
        },
      ]}
    />
  );

  const productItemActionButtons = useCallback(
    (product: Collab) => [
      <OpenVariantPickerButton key="addToCart" product={product} buyAction="addToCart" />,
      <OpenVariantPickerButton key="buyNow" product={product} buyAction="buyNow" />,
      <OpenVariantPickerButton key="preorder" product={product} buyAction="preorder" />,
    ],
    [],
  );

  return (
    <ProductList
      ref={ref}
      useProducts={useLikes}
      variables={{
        userId: profile?.userId ?? "",
        where: { stages: stage === Stage.concept ? [stage, Stage.contest] : [stage] },
      }}
      listItemProps={product => ({
        hideStageChip: product.progress.stage !== Stage.contest,
        actionButtons: stage !== Stage.concept ? productItemActionButtons(product) : undefined,
      })}
      contentContainerStyle={styles.root}
      onEndReached={() => undefined}
      ListHeaderComponent={likeTabs}
      emptyState={
        <EmptyState
          icon={({ size, color }) => <HeartStraight width={size} height={size} color={color} />}
          message={t("emptyState.likes.message", { context: stage })}
          cta={[
            {
              label: t("emptyState.likes.cta", { context: stage }),
              onPress: handleBrowse,
            },
          ]}
        />
      }
      forceLoading={loadingProfile}
      sortProps={{
        sortButtonVisible: true,
        sortingMethodEnum: LikeSortingMethod,
        defaultSort: LikeSortingMethod.createdAtDesc,
        excludedSort: stage !== Stage.forSale ? [LikeSortingMethod.priceAsc, LikeSortingMethod.priceDesc] : undefined,
      }}
    />
  );
};

export const LikeList = forwardRef(LikeListInner);
