import React, { FC, useEffect } from "react";

import { useHeaderHeight } from "@react-navigation/elements";
import { ResizeMode } from "expo-av";
import { X } from "phosphor-react-native";
import { IconButton, useTheme } from "react-native-paper";
import { useSafeAreaInsets } from "react-native-safe-area-context";

import { NavigationProps, Routes } from "@app/common/types/navigation.type";
import { ScreenWrapper } from "@app/components/common/screen-wrapper/screen-wrapper.component";
import { Video } from "@app/components/common/video/video.component";
import { useWindowDimensions } from "@app/hooks/utils/use-window-dimensions.hook";

export const VideoScreen: FC<NavigationProps<Routes.video>> = ({ navigation, route }) => {
  const { colors } = useTheme();
  const { width, height: windowHeight } = useWindowDimensions();
  const headerHeight = useHeaderHeight();
  const { bottom } = useSafeAreaInsets();

  const videoHeight = windowHeight - headerHeight - bottom;

  const videoUrl = route.params?.videoUrl;
  const posterUrl = route.params?.posterUrl;
  const cloudflareId = route.params?.cloudflareId;

  useEffect(() => {
    navigation.setOptions({
      headerRight: () => <IconButton icon={X} onPress={() => navigation.goBack()} />,
    });
  }, [navigation]);

  const shouldDisplay = videoUrl && cloudflareId;

  useEffect(() => {
    if (!shouldDisplay) {
      navigation.goBack();
    }
  }, [navigation, shouldDisplay]);

  if (!shouldDisplay) return null;

  return (
    <ScreenWrapper>
      <Video
        cloudflareId={cloudflareId}
        source={{ uri: videoUrl }}
        posterSource={{ uri: posterUrl }}
        shouldPlay
        useNativeControls
        displayLoadingIndicator
        videoStyle={{ height: videoHeight, width }}
        resizeMode={ResizeMode.CONTAIN}
        style={{
          height: videoHeight,
          width,
          backgroundColor: colors.background,
        }}
      />
    </ScreenWrapper>
  );
};
