import { StyleSheet } from "react-native";

import { supercoinsGradient } from "@app/common/style/common.style";

export const styles = StyleSheet.create({
  milestoneContainer: {
    alignItems: "center",
    borderColor: supercoinsGradient[0],
    borderRadius: 18,
    borderWidth: 1,
    columnGap: 4,
    flexDirection: "row",
    justifyContent: "center",
    paddingHorizontal: 8,
    paddingVertical: 2,
  },
  supercoinImage: {
    height: 16,
    width: 16,
  },
});
