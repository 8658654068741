import { StyleSheet } from "react-native";

export const styles = StyleSheet.create({
  innerBorder: {
    borderRadius: 100,
    borderWidth: 2,
  },
  loadingVideoIndicator: {
    position: "absolute",
  },
  outerBorder: {
    borderRadius: 100,
    borderWidth: 2,
  },
});
