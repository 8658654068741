import React, { FC } from "react";

import { formatDistanceToNow, isFuture } from "date-fns";
import { useTranslation } from "react-i18next";

import { CollabCampaignStatus, Progress, Stage } from "@app/common/graphql/generated/schema.graphql";
import { Chip, ChipProps } from "@app/components/common/chip/chip.component";

import { styles } from "./campaign-status-chip.style";

interface Props extends Omit<ChipProps, "label"> {
  progress: Progress;
  campaignStatus?: CollabCampaignStatus;
  short?: boolean;
}

export const CampaignStatusChip: FC<Props> = ({ progress: { reviewEndAt, stage, inDevelopment }, campaignStatus, short, style, ...props }) => {
  const { t } = useTranslation();

  const isClosedCampaign = campaignStatus === CollabCampaignStatus.cancelled;
  const isSuccessfulCampaign = campaignStatus === CollabCampaignStatus.successful;

  if (stage !== Stage.underReview || !campaignStatus) return null;

  if (inDevelopment) {
    return (
      <Chip
        label={t("productDetails.inDevelopment", { context: short ? "short" : undefined })}
        color="success"
        style={[styles.chip, style]}
        {...props}
      />
    );
  }

  if (isClosedCampaign || isSuccessfulCampaign)
    return (
      <Chip
        label={t(`enum.collabCampaignStatus.${campaignStatus}`, { context: short ? "short" : undefined })}
        color={isClosedCampaign ? undefined : "primary"}
        disabled={isClosedCampaign}
        style={[styles.chip, style]}
        {...props}
      />
    );

  if (!reviewEndAt) return null;

  const reviewEndAtDate = new Date(reviewEndAt);
  return (
    <Chip
      label={
        isFuture(reviewEndAtDate)
          ? t("productDetails.nbDaysLeft", { nbDaysLeft: formatDistanceToNow(reviewEndAtDate) })
          : t("productDetails.noDaysLeft")
      }
      color="primary"
      style={[styles.chip, style]}
      {...props}
    />
  );
};
