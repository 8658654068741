import React, { FC, ReactElement, useCallback, useMemo } from "react";

import { ListRenderItem } from "react-native";

import { BehindTheScenesVideo } from "@app/common/graphql/generated/schema.graphql";
import { Routes, useHomeNavigation } from "@app/common/types/navigation.type";
import { VideoListItem } from "@app/components/videos/video-list-item/video-list-item.component";
import { useHomeContext } from "@app/context/home/home.context";
import { useResponsiveWidthListItem } from "@app/hooks/utils/use-responsive-width-list-item.hook";

import { HomeVideoSectionType } from "../../home.type";
import { HomeSection } from "../home-section.component";

import { VideoListLoading } from "./video-list-loading/video-list-loading.component";

interface Props {
  title: string;
  type: HomeVideoSectionType;
}

export const VideoSection: FC<Props> = ({ title, type }) => {
  const navigation = useHomeNavigation();

  const { itemWidth, listPadding } = useResponsiveWidthListItem();
  const { data, loading, error } = useHomeContext();

  const videos = data?.[type].nodes as BehindTheScenesVideo[] | undefined;

  const renderVideoItem = useCallback<ListRenderItem<BehindTheScenesVideo>>(
    ({ item }) => (
      <VideoListItem videoUrl={item.videoManifestUrl} thumbnailUrl={item.thumbnailUrl} cloudflareId={item.cloudflareId} width={itemWidth} />
    ),
    [itemWidth],
  );

  const emptySection = useMemo((): ReactElement | undefined => {
    if (!loading && !error) return undefined;

    return <VideoListLoading itemWidth={itemWidth} horizontal quantity={6} contentContainerStyle={{ padding: listPadding }} />;
  }, [error, itemWidth, listPadding, loading]);

  return (
    <HomeSection
      data={videos}
      renderItem={renderVideoItem}
      title={title}
      ListEmptyComponent={emptySection}
      hidden={!loading && !error && !videos?.length}
      onSeeAllPress={() => navigation.navigate(Routes.seeAllVideos, { type })}
    />
  );
};
