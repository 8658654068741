import Constants from "expo-constants";
import { MD3LightTheme, MD3DarkTheme, configureFonts, Theme } from "react-native-paper";

import { isWeb } from "@app/utils/device.util";

import { customFonts } from "./fonts";

export const transparent = "transparent";

export const bottomTabsHeight = isWeb ? 64 : 40 + Constants.statusBarHeight;

const spacingStep = 4;

export function spacing(value: number): number {
  return value * spacingStep;
}

export type ColorVariant = "primary" | "secondary" | "tertiary" | "error" | "success" | "warning" | "info" | "godMode";

const defaultFontConfig = {
  fontFamily: "PPNeueMontreal_400Regular",
};

export const lightTheme: Theme = {
  ...MD3LightTheme,
  dark: false,
  version: 3,
  mode: "adaptive",
  roundness: 4,
  colors: {
    ...MD3LightTheme.colors,
    primary: "rgba(0,0,0,1)",
    onPrimary: "rgba(255,255,255,1)",

    secondary: "rgba(0,0,245,1)",
    onSecondary: "rgba(255,255,255,1)",
    secondaryContainer: "rgba(0,0,0,0.04)",
    onSecondaryContainer: "rgba(0,0,0,0.54)",

    tertiary: "rgba(0,0,0,0.7)",
    tertiaryContainer: "rgba(247,247,247,1)",
    onTertiaryContainer: "rgba(0,0,0,1)",

    godMode: "rgba(124,58,237,1)",
    onGodMode: "rgba(255,255,255,1)",

    outline: "rgba(0,0,0,0.5)",
    outline23p: "rgba(0,0,0,0.23)",

    background: "rgba(255,255,255,1)",
    onBackground: "rgba(0,0,0,1)",
    backdrop: "rgba(255,255,255,0.75)",
    skeleton: "rgba(225,228,232,0.5)",

    surface: "rgba(255,255,255,1)",
    onSurface: "rgba(0,0,0,1)",
    inverseOnSurface: "rgba(255,255,255,0.5)",
    surfaceVariant: "rgba(0,0,0,0.09)",
    onSurfaceVariant: "rgba(0,0,0,0.7)",
    surfaceDisabled: "rgba(0,0,0,0.12)",
    onSurfaceDisabled: "rgba(0,0,0,0.3)",

    error: "rgba(244,67,54,1)",
    onError: "rgba(255,255,255,1)",
    error160p: "rgba(84,19,19,1)",
    error8p: "rgba(211,47,47,0.08)",

    warning: "rgba(255,152,0,1)",
    warning160p: "rgba(237,108,2,1)",
    onWarning: "rgba(0,0,0,1)",

    success: "rgba(76,175,80,1)",
    onSuccess: "rgba(0,0,0,1)",

    info: "rgba(0, 125, 255, 1)",
    onInfo: "rgba(0,0,0,1)",
    info12p: "rgba(2, 136, 209, 0.12)",
    info160p: "rgba(1, 54, 84, 1)",

    elevation: {
      ...MD3LightTheme.colors.elevation,
      level3: "rgba(0,0,0,0.09)",
    },

    action: {
      active: "rgba(0,0,0,0.56)",
      selected: "rgba(0,0,0,0.08)",
      disabled: "rgba(0,0,0,0.3)",
    },

    common: {
      white: "rgba(255,255,255,1)",
      black: "rgba(0,0,0,1)",
      gray: "rgba(158,158,158,1)",
    },
  },
  fonts: {
    ...configureFonts({ config: defaultFontConfig }),
    ...configureFonts({ config: customFonts }),
  },
};

export const darkTheme: Theme = {
  ...lightTheme,
  dark: true,
  colors: {
    ...lightTheme.colors,
    ...MD3DarkTheme.colors,
    primary: "rgba(255,255,255,1)",
    onPrimary: "rgba(0,0,0,1)",

    secondary: "rgba(60,60,255,1)",
    onSecondary: "rgba(255,255,255,1)",
    secondaryContainer: "rgba(255,255,255,0.04)",
    onSecondaryContainer: "rgba(255,255,255,0.56)",

    tertiary: "rgba(255,255,255,0.7)",
    tertiaryContainer: "rgba(29,29,29,1)",
    onTertiaryContainer: "rgba(255,255,255,1)",

    godMode: "rgba(158,98,255,1)",
    onGodMode: "rgba(255,255,255,1)",

    outline: "rgba(255,255,255,0.5)",
    outline23p: "rgba(255,255,255,0.23)",

    background: "rgba(0,0,0,1)",
    onBackground: "rgba(255,255,255,1)",
    backdrop: "rgba(0,0,0,0.75)",
    skeleton: "rgba(225,228,232,0.5)",

    surface: "rgba(0,0,0,1)",
    onSurface: "rgba(255,255,255,1)",
    inverseOnSurface: "rgba(0,0,0,0.5)",
    surfaceVariant: "rgba(255,255,255,0.09)",
    onSurfaceVariant: "rgba(255,255,255,0.7)",
    surfaceDisabled: "rgba(255,255,255,0.12)",
    onSurfaceDisabled: "rgba(255,255,255,0.3)",

    error: "rgba(244,67,54,1)",
    onError: "rgba(255,255,255,1)",
    error160p: "rgba(251,180,175,1)",
    error8p: "rgba(244,67,54,0.08)",

    warning: "rgba(255,167,38,1)",
    warning160p: "rgba(255,220,168,1)",
    onWarning: "rgba(0,0,0,1)",

    success: "rgba(102,187,106,1)",
    onSuccess: "rgba(0,0,0,1)",

    info: "rgba(41, 182, 246, 1)",
    onInfo: "rgba(0,0,0,1)",
    info12p: "rgba(2, 136, 209, 0.12)",
    info160p: "rgba(154, 207, 237, 1)",

    elevation: {
      ...MD3DarkTheme.colors.elevation,
      level3: "rgba(0,0,0,0.09)",
    },

    action: {
      active: "rgba(255,255,255,0.56)",
      selected: "rgba(255,255,255,0.08)",
      disabled: "rgba(255,255,255,0.3)",
    },
  },
};
