/* eslint-disable no-console */
import React, { FC, useEffect, useMemo, useRef, useState } from "react";

import { useNetInfo } from "@react-native-community/netinfo";
import { AppState } from "react-native";

import { analytics } from "@app/common/analytics/analytics";
import { ChildrenProp } from "@app/common/types/children-prop.interface";
import { log, reportError } from "@app/utils/logger/logger.util";

import { getAuthWhenReady } from "../auth/firebase";

import { AppStateContext } from "./app-state.context";

export const AppStateContextProvider: FC<ChildrenProp> = ({ children }) => {
  const appState = useRef(AppState.currentState);
  const [isAppOnForeground, setIsAppOnForeground] = useState(appState.current === "active");
  const { isConnected, type } = useNetInfo();

  const contextValue = useMemo(() => ({ isAppOnForeground, isInternetWorking: !!isConnected }), [isAppOnForeground, isConnected]);

  useEffect(() => {
    const subscription = AppState.addEventListener("change", nextAppState => {
      if (appState.current.match(/inactive|background/) && nextAppState === "active") {
        log.info("[AppState]: App has come to the foreground!");
        void analytics.logAppOpen();
        void getAuthWhenReady()
          .then(auth => auth.currentUser?.getIdToken())
          .then(newToken => {
            if (newToken) {
              log.info("[AppState]: User refreshed");
            }
          })
          .catch(error => {
            reportError(error as Error, {
              extra: {
                message: "[AppState]: tried to refresh the user but failed",
              },
            });
          });
      }

      appState.current = nextAppState;
      setIsAppOnForeground(appState.current === "active");
      log.info("[AppState]:", appState.current);
    });

    return () => {
      subscription.remove();
    };
  }, []);

  useEffect(() => {
    log.info("[AppState]: connection status changed", { isConnected, type });
  }, [isConnected, type]);

  return <AppStateContext.Provider value={contextValue}>{children}</AppStateContext.Provider>;
};
