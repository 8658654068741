import React, { FC } from "react";

import { createNativeStackNavigator } from "@react-navigation/native-stack";
import { ArrowBendRightDown } from "phosphor-react-native";
import { IconButton } from "react-native-paper";

import { FeedStackParamList } from "@app/common/types/navigation/stack-param-list.type";
import { RootTabStackScreenProps, Routes, Tabs, navigationRef } from "@app/common/types/navigation.type";
import { FeedContextProvider } from "@app/context/feed/feed.provider";
import { FeedScreen } from "@app/screens/feed/feed.screen";
import { ProductFromFeed } from "@app/screens/feed/product-from-feed/product-from-feed.screen";
import { NotificationsScreen } from "@app/screens/notifications/notifications.screen";

import { commonScreenOptions } from "../router.const";

const Stack = createNativeStackNavigator<FeedStackParamList>();

export const FeedStack: FC<RootTabStackScreenProps<Tabs.feed>> = () => {
  return (
    <FeedContextProvider>
      <Stack.Navigator initialRouteName={Routes.feed} screenOptions={commonScreenOptions()}>
        <Stack.Screen name={Routes.feed} component={FeedScreen} options={{ title: "", headerLeft: () => undefined }} />
        <Stack.Screen name={Routes.notifications} component={NotificationsScreen} />
        <Stack.Screen
          name={Routes.productFromFeed}
          component={ProductFromFeed}
          options={{
            presentation: "card",
            animation: "fade",
            animationDuration: 100,
            gestureDirection: "vertical",
            headerLeft: () => undefined,
            headerRight: () => <IconButton icon={ArrowBendRightDown} onPress={() => navigationRef.goBack()} />,
            title: "",
          }}
        />
      </Stack.Navigator>
    </FeedContextProvider>
  );
};
