import React, { FC, useState } from "react";

import { Minus, Plus } from "phosphor-react-native";
import { useTranslation } from "react-i18next";
import { TouchableHighlight, View } from "react-native";
import { useTheme } from "react-native-paper";
import Animated, { FadeIn } from "react-native-reanimated";

import { Profile } from "@app/common/graphql/generated/schema.graphql";
import { ListItem } from "@app/components/common/list-item/list-item.component";
import { Text } from "@app/components/common/text/text.component";
import { CreatorNameWithAvatar } from "@app/components/profile/creator-name-with-avatar/creator-name-with-avatar.component";
import { useWindowDimensions } from "@app/hooks/utils/use-window-dimensions.hook";

import { styles } from "./creator-section.style";

interface Props {
  creator: Profile;
}

export const CreatorSection: FC<Props> = ({ creator }) => {
  const { t } = useTranslation();
  const { width } = useWindowDimensions();
  const { colors, roundness } = useTheme();

  const [expanded, setExpanded] = useState(false);
  const expandable = !!creator.pronouns.length || !!creator.bio;

  const handleExpand = (): void => setExpanded(isExpanded => !isExpanded);

  return (
    <View style={styles.root}>
      <ListItem
        title={<CreatorNameWithAvatar profile={creator} imageSize={40} textProps={{ variant: "h8", color: "tertiary" }} width={width - 140} />}
        right={
          expandable && (
            <TouchableHighlight activeOpacity={1} underlayColor={colors.action.selected} onPress={handleExpand}>
              <View style={styles.expandableButton}>
                <Text variant="body2" color="tertiary" textTransform="uppercase">
                  {t("productDetails.aboutMe")}
                </Text>
                {expanded ? (
                  <Minus color={colors.onBackground} weight="thin" size={24} />
                ) : (
                  <Plus color={colors.onBackground} weight="thin" size={24} />
                )}
              </View>
            </TouchableHighlight>
          )
        }
      />

      {expanded && expandable && (
        <Animated.View
          entering={FadeIn}
          style={[styles.bioPronounsContainer, { backgroundColor: colors.tertiaryContainer, borderRadius: roundness }]}>
          <Text style={styles.bioPronouns}>
            {!!creator.pronouns.length && <Text color="tertiary">{`${creator.pronouns.join("/")} `}</Text>}
            <Text>{creator.bio}</Text>
          </Text>
        </Animated.View>
      )}
    </View>
  );
};
