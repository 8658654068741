import React, { FC, useCallback, useEffect, useMemo, useRef, useState } from "react";

import { AuthCredential } from "firebase/auth";

import { EmailType } from "@app/common/enums/email-type.enum";
import { ChildrenProp } from "@app/common/types/children-prop.interface";
import { BottomSheetRefProps } from "@app/components/common/bottom-sheet/bottom-sheet.types";
import { LinkLoginProvidersPrompt } from "@app/components/login/link-login-providers-prompt/link-login-providers-prompt.component";
import { LoginBottomSheet, LoginStep } from "@app/components/login/login-bottom-sheet/login-bottom-sheet.component";
import { useColorSchemeContext } from "@app/context/color-scheme/color-scheme.context";
import { useProfileContext } from "@app/context/profile/profile.context";
import { reportError } from "@app/utils/logger/logger.util";

import { LoginContext, LoginContextInterface, LinkLoginProvidersProps } from "./login.context";
import { CustomableLoginBottomSheetProps } from "./login.type";

interface Props extends ChildrenProp {
  initialRoute?: string;
}

export const LoginContextProvider: FC<Props> = ({ children, initialRoute }) => {
  const { profile, loading: loadingProfile } = useProfileContext();
  const { setColorSchemeSetting } = useColorSchemeContext();

  const loginSheetRef = useRef<BottomSheetRefProps>(null);
  const linkLoginProvidersSheetRef = useRef<BottomSheetRefProps>(null);

  const [loginBottomSheetProps, setLoginBottomSheetProps] = useState<CustomableLoginBottomSheetProps | undefined>();
  const [step, setStep] = useState(LoginStep.login);
  const [email, setEmail] = useState("");
  const [emailType, setEmailType] = useState<EmailType>(EmailType.verifyEmail);
  const [linkLoginEmail, setLinkLoginEmail] = useState("");
  const [newSignInMethod, setNewSignInMethod] = useState("");
  const [existingSignInMethods, setExistingSignInMethods] = useState<string[]>([]);
  const [pendingCredential, setPendingCredential] = useState<AuthCredential>();

  const openLogin = useCallback((props?: CustomableLoginBottomSheetProps) => {
    setLoginBottomSheetProps(props);

    try {
      if (!loginSheetRef.current) {
        reportError(new Error("Login bottom sheet is undefined"));
      }
      loginSheetRef.current?.open();
    } catch (error) {
      reportError(error as Error, { extra: { details: "Couldn't open the login bottom sheet" } });
    }
  }, []);

  const openLinkProvidersPrompt = useCallback((props: LinkLoginProvidersProps): void => {
    setLinkLoginEmail(props.email);
    setNewSignInMethod(props.newSignInMethod);
    setExistingSignInMethods(props.existingSignInMethods);
    setPendingCredential(props.pendingCredential);
    linkLoginProvidersSheetRef.current?.open();
  }, []);

  const closeLogin = useCallback(() => loginSheetRef.current?.close(), []);

  useEffect(() => {
    if (profile?.userId && !loadingProfile) {
      closeLogin();
      setColorSchemeSetting("system");
    }
  }, [closeLogin, loadingProfile, profile?.userId, setColorSchemeSetting]);

  const loginContextValue = useMemo<LoginContextInterface>(
    () => ({ openLogin, openLinkProvidersPrompt, closeLogin }),
    [openLogin, openLinkProvidersPrompt, closeLogin],
  );

  return (
    <LoginContext.Provider value={loginContextValue}>
      {children}
      <LoginBottomSheet
        sheetRef={loginSheetRef}
        initialRoute={initialRoute}
        step={step}
        setStep={setStep}
        email={email}
        setEmail={setEmail}
        emailType={emailType}
        setEmailType={setEmailType}
        {...loginBottomSheetProps}
      />
      <LinkLoginProvidersPrompt
        sheetRef={linkLoginProvidersSheetRef}
        email={linkLoginEmail}
        newSignInMethod={newSignInMethod}
        existingSignInMethods={existingSignInMethods}
        pendingCredential={pendingCredential}
        setStep={setStep}
        setEmail={setEmail}
        setEmailType={setEmailType}
      />
    </LoginContext.Provider>
  );
};
