import { StyleSheet } from "react-native";

export const styles = StyleSheet.create({
  buttonContainer: {
    paddingHorizontal: 16,
    paddingVertical: 8,
  },
  imageContainer: {
    aspectRatio: 1,
    width: "100%",
  },
  linearGradient: {
    height: "100%",
    position: "absolute",
    width: "100%",
  },
  textContainer: {
    paddingHorizontal: 20,
    paddingVertical: 8,
    rowGap: 24,
  },
});
