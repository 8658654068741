/* eslint-disable complexity */
import React, { FC, ReactNode } from "react";

import { IconProps } from "phosphor-react-native";
import { StyleProp, View, ViewStyle } from "react-native";
import { ButtonProps as RNButtonProps, Button as RNButton, useTheme, Theme } from "react-native-paper";

import { ColorVariant } from "@app/common/style/theme";
import { getOnColor } from "@app/utils/color-theme.util";
import { conditionalItem } from "@app/utils/conditional-item-in-array.util";

import { styles } from "./button.style";

export interface ButtonProps extends Omit<RNButtonProps, "color" | "theme" | "icon"> {
  size?: "xsmall" | "small" | "medium" | "large";
  color?: ColorVariant;
  fullWidth?: boolean;
  icon?: (props: IconProps) => ReactNode;
  containerStyle?: StyleProp<ViewStyle>;
  theme?: Partial<Theme>;
}

export const Button: FC<ButtonProps> = ({
  size = "medium",
  mode = "text",
  color,
  fullWidth = false,
  icon,
  children,
  style,
  labelStyle,
  contentStyle,
  containerStyle,
  theme: overriddenTheme = {},
  loading,
  disabled,
  ...rest
}) => {
  const currentTheme = useTheme();
  const theme = { ...currentTheme, ...overriddenTheme };

  const sizeLabelStyle =
    size === "small" ? styles.sizeSmallLabel : size === "large" ? styles.sizeLargeLabel : size === "xsmall" ? styles.sizeXSmallLabel : undefined;
  const rootStyle = size === "small" ? styles.sizeSmall : undefined;
  const textColor = color && !disabled ? (mode === "contained" ? getOnColor(theme.colors, color) : theme.colors[color]) : rest.textColor;
  const buttonColor = color && !disabled && mode === "contained" ? theme.colors[color] : rest.buttonColor;
  const borderColor = color && color !== "primary" && mode === "outlined" && !disabled ? { borderColor: textColor } : {};
  const iconSize = size === "large" ? 24 : 20;

  return (
    <View style={[...conditionalItem(fullWidth, styles.fullWidth), containerStyle]}>
      <RNButton
        mode={mode}
        style={[styles.root, rootStyle, style, borderColor]}
        labelStyle={[styles.rootLabel, sizeLabelStyle, labelStyle]}
        buttonColor={buttonColor}
        textColor={textColor}
        contentStyle={[...conditionalItem((!!icon || !!loading) && size === "medium" && mode !== "text", styles.rootContentWithIcon), contentStyle]}
        compact={size === "xsmall"}
        icon={icon ? iconProps => icon({ weight: "thin", ...iconProps, size: iconSize }) : undefined}
        theme={theme}
        loading={loading}
        disabled={loading || disabled}
        {...rest}>
        {children}
      </RNButton>
    </View>
  );
};
