import { QueryHookOptions, gql, useQuery } from "@apollo/client";

import { collabFields } from "@app/common/graphql/fragments.graphql";
import { PaginatedCollabs } from "@app/common/graphql/generated/schema.graphql";
import { QueryResult } from "@app/common/types/apollo-result.type";

interface Response {
  purchaseableCollabs: PaginatedCollabs;
}

interface Variables {
  first?: number;
  offset?: number;
}

const purchaseableCollabsQuery = gql`
  query PurchaseableCollabs($first: Int, $offset: Int) {
    purchaseableCollabs(first: $first, offset: $offset) {
      nodes {
        ...CollabFields
      }
      pageInfo {
        hasNextPage
      }
      totalCount
    }
  }
  ${collabFields}
`;

export function usePurchaseableCollabs(options: QueryHookOptions<Response, Variables>): QueryResult<Response, "purchaseableCollabs"> {
  const { data, loading, error, fetchMore, networkStatus } = useQuery<Response, Variables>(purchaseableCollabsQuery, {
    notifyOnNetworkStatusChange: true,
    ...options,
  });

  return { data: data?.purchaseableCollabs, loading, error, fetchMore, networkStatus };
}
