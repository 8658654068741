import React, { FC } from "react";

import { Trophy, Lightbulb, CaretRight, SkipBack, BookmarkSimple, MagicWand, RocketLaunch } from "phosphor-react-native";
import { Trans, useTranslation } from "react-i18next";
import { View } from "react-native";
import { List, useTheme } from "react-native-paper";

import { TrackEvent, ViewContestsOrigin, ViewSuperplanScreenOrigin } from "@app/common/enums/track-events.enum";
import { ContestStatus } from "@app/common/graphql/generated/schema.graphql";
import { ChildrenProp } from "@app/common/types/children-prop.interface";
import { Routes, StudioStackScreenProps } from "@app/common/types/navigation.type";
import { Alert } from "@app/components/common/alert/alert.component";
import { ListItem, ListItemProps } from "@app/components/common/list-item/list-item.component";
import { ScreenWrapper } from "@app/components/common/screen-wrapper/screen-wrapper.component";
import { Text } from "@app/components/common/text/text.component";
import { FeaturedContest } from "@app/components/contests/featured-contest/featured-contest.component";
import { ExploreSection } from "@app/components/studio-mini/explore/explore.component";
import { StudioTokenButton } from "@app/components/studio-mini/studio-token-button/studio-token-button.component";
import { SuperplanAlert } from "@app/components/superplans/superplan-alert/superplan-alert.component";
import { useLoginContext } from "@app/context/auth/login/login.context";
import { FeatureFlag } from "@app/context/launch-darkly/feature-flag.enum";
import { useLaunchDarklyContext } from "@app/context/launch-darkly/launch-darkly.context";
import { useMixpanelContext } from "@app/context/mixpanel/mixpanel.context";
import { useProfileContext } from "@app/context/profile/profile.context";
import { useStudioMiniContext } from "@app/context/studio-mini/studio-mini.context";
import { useContests } from "@app/hooks/api/contests/use-contests.hook";

import { styles } from "./studio.style";

const StudioActionTitle: FC<{ i18nKey: string }> = ({ i18nKey }) => (
  <Trans
    i18nKey={i18nKey}
    parent={({ children }: ChildrenProp) => <Text variant="body1">{children}</Text>}
    components={{
      b: (
        <Text variant="h8">
          <></>
        </Text>
      ),
    }}
  />
);

export const StudioScreen: FC<StudioStackScreenProps<Routes.studio>> = ({ navigation }) => {
  const { colors, roundness } = useTheme();
  const { t } = useTranslation();
  const { isFeatureEnabled } = useLaunchDarklyContext();

  const { trackInMixpanel } = useMixpanelContext();
  const { generation, resetGeneration } = useStudioMiniContext();
  const { data, loading } = useContests({ variables: { where: { includeHidden: false, status: ContestStatus.inProgress }, first: 1, offset: 0 } });
  const { profile } = useProfileContext();
  const { openLogin } = useLoginContext();

  const isStartCampaignFromStudioEnabled = isFeatureEnabled(FeatureFlag.startCampaignFromStudio);
  const areProjectInProgress = !!generation.taskIds;
  const featuredContest = data?.nodes?.[0];

  const handlePress = (): void => {
    if (areProjectInProgress) {
      Alert.alert(t("error.warning"), t("studioMini.warningMessage"), [
        { text: t("cta.cancel"), style: "cancel" },
        {
          style: "destructive",
          text: t("cta.yes"),
          onPress: () => {
            resetGeneration();
            navigation.navigate(Routes.studioMiniGeneration);
          },
        },
      ]);
    } else {
      resetGeneration();
      navigation.navigate(Routes.studioMiniGeneration);
    }
  };

  const handleOnPressPublish = (): void => {
    if (!profile) {
      openLogin();
    } else {
      navigation.navigate(Routes.studioMiniCreateCollab);
    }
  };

  const handleOpenGeneration = (): void => navigation.navigate(Routes.studioMiniGeneration);

  const handleNavigateToContest = (): void => {
    trackInMixpanel(TrackEvent.viewContests, { origin: ViewContestsOrigin.studio });
    navigation.navigate(Routes.activeContests);
  };

  const handleStartCampaign = (): void => {
    //TODO(BLOOM-3177): navigate to start campaign
  };

  const commonActionProps: Partial<ListItemProps> = {
    style: [{ borderColor: colors.outline23p, borderRadius: roundness }, styles.listItem],
    right: <CaretRight color={colors.primary} />,
  };

  const commonHeaderProps: Partial<ListItemProps> = {
    style: styles.header,
    titleProps: { variant: "h8" },
  };

  return (
    <ScreenWrapper safeAreaEdges={featuredContest ? ["right", "left"] : undefined} withScrollView>
      <FeaturedContest contest={featuredContest} loading={loading} />
      <List.Section style={styles.section}>
        <ListItem {...commonHeaderProps} title={t("studioMini.projects.publish.title")} />
        <View style={styles.listItemContainer}>
          {isStartCampaignFromStudioEnabled && (
            <ListItem
              {...commonActionProps}
              title={<StudioActionTitle i18nKey="studioMini.projects.publish.startCampaign.title" />}
              left={<RocketLaunch weight="fill" color={colors.primary} />}
              onPress={handleStartCampaign}
            />
          )}
          <ListItem
            {...commonActionProps}
            title={<StudioActionTitle i18nKey="studioMini.projects.publish.post.title" />}
            left={<Lightbulb weight="fill" color={colors.primary} />}
            onPress={handleOnPressPublish}
          />
          <ListItem
            {...commonActionProps}
            title={<StudioActionTitle i18nKey="studioMini.projects.publish.contest.title" />}
            left={<Trophy weight="fill" color={colors.primary} />}
            onPress={handleNavigateToContest}
          />
        </View>
      </List.Section>

      <List.Section style={styles.section}>
        <ListItem {...commonHeaderProps} title={t("studioMini.projects.create.title")} right={<StudioTokenButton />} />

        <View style={styles.listItemContainer}>
          <SuperplanAlert
            feature="infiniteTokens"
            flexDirection="row"
            origin={ViewSuperplanScreenOrigin.studioTab}
            onLearnMorePress={tab => navigation.navigate(Routes.superplans, { tab })}
          />
          <ListItem
            {...commonActionProps}
            title={<StudioActionTitle i18nKey="studioMini.projects.create.start.title" />}
            left={<MagicWand weight="fill" color={colors.primary} />}
            onPress={handlePress}
          />
          {areProjectInProgress && (
            <ListItem
              {...commonActionProps}
              title={<StudioActionTitle i18nKey="studioMini.projects.create.resume.title" />}
              left={<SkipBack weight="fill" color={colors.primary} />}
              onPress={handleOpenGeneration}
            />
          )}
          <ListItem
            {...commonActionProps}
            title={<StudioActionTitle i18nKey="studioMini.projects.publish.saved.title" />}
            left={<BookmarkSimple weight="fill" color={colors.primary} />}
            onPress={() => navigation.navigate(Routes.studioMiniSaved, { selectionMode: "allowed" })}
          />
        </View>
      </List.Section>

      <List.Section style={styles.section}>
        <ListItem {...commonHeaderProps} title={t("studioMini.projects.inspiration.title")} />
      </List.Section>
      <ExploreSection />
    </ScreenWrapper>
  );
};
