import React, { FC, useCallback, useEffect, useMemo, useState } from "react";

import { useApolloClient } from "@apollo/client";
import { useTranslation } from "react-i18next";

import { analytics } from "@app/common/analytics/analytics";
import { isErrorCode } from "@app/common/apollo/apollo.utils";
import { SetShippingAddressInput } from "@app/common/graphql/generated/schema.graphql";
import { ChildrenProp } from "@app/common/types/children-prop.interface";
import { useComputeAdditionalCharges } from "@app/hooks/api/preorder/use-compute-additional-charges.hook";
import { useCreatePreorderForAnonymous } from "@app/hooks/api/preorder/use-create-preorder-for-anonymous.hook";
import { useCreatePreorder } from "@app/hooks/api/preorder/use-create-preorder.hook";
import { usePaymentMethodForAnonymous } from "@app/hooks/api/preorder/use-payment-method-for-anonymous.hook";
import { usePaymentMethods } from "@app/hooks/api/preorder/use-payment-methods.hook";
import { useShippingAddress } from "@app/hooks/api/preorder/use-shipping-address.hook";
import { useUpdatePreorderPaymentMethod } from "@app/hooks/api/preorder/use-update-preorder-payment-method.hook";
import { supercoinBalanceQuery } from "@app/hooks/api/supercoins/use-supercoin-balance.hook";
import { useSupercoinLimitForCreatePreorder } from "@app/hooks/api/supercoins/use-supercoin-limit-for-create-preorder.hook";
import { ordersQuery } from "@app/hooks/api/use-orders.hook";
import { useErrorMessage } from "@app/hooks/utils/use-error-message.hook";
import {
  getComputeAdditionalChargesInputFromContextAddress,
  getShippingAddressInputFromContextAddress,
} from "@app/utils/address-converter/address-converter.util";

import { useProfileContext } from "../profile/profile.context";
import { useSnackbarContext } from "../snackbar/snackbar.context";

import { PreorderContext, PreorderContextInterface, defaultInputsValue } from "./preorder.context";

export const PreorderProvider: FC<ChildrenProp> = ({ children }) => {
  const client = useApolloClient();
  const { t } = useTranslation();
  const { profile, loading: loadingProfile } = useProfileContext();
  const { showErrorSnackbar } = useSnackbarContext();
  const { getShippingAddressErrorMessage } = useErrorMessage();

  const [input, setInput] = useState(defaultInputsValue);
  const [preorderCompleted, setPreorderCompleted] = useState(false);

  const { email, paymentMethodId, quantity, shippingAddress, anonymousCustomerId, variantId, orderId, supercoinsUsed } = input;
  const isAnonymous = !profile && !loadingProfile;

  const { computeAdditionalCharges, data: additionalCharges, loading: loadingAdditionalCharges } = useComputeAdditionalCharges();
  const { createPreorder, loading: loadingCreatePreorder } = useCreatePreorder(input.collabId);
  const { createPreorderForAnonymous, loading: loadingCreatePreorderForAnonymous } = useCreatePreorderForAnonymous(input.collabId);
  const { loading: loadingShippingAddress, refetch: refetchShippingAddress } = useShippingAddress({
    variables: { userId: profile?.userId },
    onCompleted: data => setInput(prev => ({ ...prev, shippingAddress: data.shippingAddress })),
    onError: err => showErrorSnackbar({ refetch: refetchShippingAddress, error: err }),
  });
  const { updatePreorderPaymentMethod, loading: loadingUpdatePreorderPayment } = useUpdatePreorderPaymentMethod();
  const {
    data: paymentMethodsConnected,
    loading: loadingPaymentMethodsConnected,
    refetch: refetchPaymentMethods,
  } = usePaymentMethods({
    variables: { userId: profile?.userId },
    onError: err => showErrorSnackbar({ refetch: refetchPaymentMethods, error: err }),
  });
  const {
    data: paymentMethodForAnonymous,
    loading: loadingPaymentMethodForAnonymous,
    refetch: refetchPaymentMethodForAnonymous,
  } = usePaymentMethodForAnonymous({
    variables: { anonymousCustomerId },
    onCompleted: data => setInput(prev => ({ ...prev, paymentMethodId: data.paymentMethodForAnonymous.paymentMethodId })),
    onError: err => showErrorSnackbar({ refetch: refetchPaymentMethodForAnonymous, error: err }),
  });
  const { data: supercoinLimit } = useSupercoinLimitForCreatePreorder({
    variables: { input: { variantId, quantity } },
    skip: isAnonymous,
  });

  const loadingPreorder = loadingCreatePreorder || loadingCreatePreorderForAnonymous || loadingUpdatePreorderPayment;

  const loadingPaymentMethods = isAnonymous ? loadingPaymentMethodForAnonymous : loadingPaymentMethodsConnected;

  const paymentMethods = useMemo(
    () => (isAnonymous ? (paymentMethodForAnonymous ? [paymentMethodForAnonymous] : undefined) : paymentMethodsConnected),
    [isAnonymous, paymentMethodForAnonymous, paymentMethodsConnected],
  );

  const handlePreorderCompletedAsConnected = useCallback(() => {
    void client.refetchQueries({ include: [ordersQuery] });
    setPreorderCompleted(true);
  }, [client]);

  const preorder = useCallback(() => {
    if (!paymentMethodId || loadingProfile) return;

    if (orderId) {
      void updatePreorderPaymentMethod({
        variables: { input: { paymentMethodId }, orderId },
        onCompleted: handlePreorderCompletedAsConnected,
        onError: error => showErrorSnackbar({ error }),
      });
    } else if (profile?.userId) {
      void createPreorder({
        variables: { userId: profile.userId, input: { paymentMethodId, variantId, quantity, supercoinAmount: supercoinsUsed ?? 0 } },
        onCompleted: data => {
          handlePreorderCompletedAsConnected();
          if (supercoinsUsed && supercoinsUsed > 0) {
            void client.refetchQueries({ include: [supercoinBalanceQuery] });
          }
          void analytics.logSpendVirtualCurrency(data.createPreorder.supercoinAmount, data.createPreorder.variantId);
        },
        onError: error => {
          let message;
          if (isErrorCode(error, "AMOUNT_EXCEEDS_HALF_OF_ORDER_PRICE")) {
            message = t("error.supercoinsUsedExceedsLimit", { limit: supercoinLimit?.limitWithCurrentBalance });
          }
          showErrorSnackbar({ error, message });
        },
      });
    } else {
      if (!anonymousCustomerId || !email || !shippingAddress) return;

      const shippingAddressInput: SetShippingAddressInput = getShippingAddressInputFromContextAddress(shippingAddress);

      void createPreorderForAnonymous({
        variables: { anonymousCustomerId, input: { email, shippingAddress: shippingAddressInput, paymentMethodId, variantId, quantity } },
        onCompleted: () => setPreorderCompleted(true),
        onError: error => showErrorSnackbar({ error }),
      });
    }
  }, [
    anonymousCustomerId,
    client,
    createPreorder,
    createPreorderForAnonymous,
    email,
    handlePreorderCompletedAsConnected,
    loadingProfile,
    orderId,
    paymentMethodId,
    profile?.userId,
    quantity,
    shippingAddress,
    showErrorSnackbar,
    supercoinLimit?.limitWithCurrentBalance,
    supercoinsUsed,
    t,
    updatePreorderPaymentMethod,
    variantId,
  ]);

  useEffect(() => {
    if (shippingAddress) {
      void computeAdditionalCharges({
        variables: {
          input: {
            address: getComputeAdditionalChargesInputFromContextAddress(shippingAddress),
            quantity,
            variantId,
          },
        },
        onError: error => {
          const { message, cta } = getShippingAddressErrorMessage(error);
          showErrorSnackbar({ error: message ? undefined : error, message, cta, duration: cta ? 10000 : undefined });
        },
      });
    }
  }, [computeAdditionalCharges, getShippingAddressErrorMessage, quantity, shippingAddress, showErrorSnackbar, t, variantId]);

  const context = useMemo<PreorderContextInterface>(
    () => ({
      isAnonymous,
      input,
      loadingShippingAddress,
      loadingPreorder,
      loadingPaymentMethods,
      paymentMethods,
      preorderCompleted,
      salesTax: additionalCharges?.salesTax,
      shippingRate: additionalCharges?.shippingRate,
      loadingAdditionalCharges,
      setInput,
      preorder,
    }),
    [
      input,
      isAnonymous,
      loadingPaymentMethods,
      loadingPreorder,
      loadingShippingAddress,
      loadingAdditionalCharges,
      paymentMethods,
      preorder,
      preorderCompleted,
      additionalCharges,
    ],
  );

  return <PreorderContext.Provider value={context}>{children}</PreorderContext.Provider>;
};
