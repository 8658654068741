import React, { FC } from "react";

import { useTranslation } from "react-i18next";
import { View } from "react-native";

import { MilestoneType } from "@app/common/graphql/generated/schema.graphql";
import { darkTheme } from "@app/common/style/theme";
import { AvatarImage } from "@app/components/common/avatar-image/avatar-image.component";
import { Text } from "@app/components/common/text/text.component";
import { RewardChip } from "@app/components/supercoins/reward-chip/reward-chip.component";
import { useSmallestMilestoneObjective } from "@app/hooks/api/supercoins/use-smallest-milestone-objective.hook";
import { InAppBrowserContentType } from "@app/hooks/utils/use-get-shared-item.hook";
import { log } from "@app/utils/logger/logger.util";
import { formatPriceUSD } from "@app/utils/price.util";

import { styles } from "./join-me-card.style";

export interface Props {
  userImageUrl?: string;
  username?: string;
  sharedItemType: InAppBrowserContentType;
}

export const JoinMeCard: FC<Props> = ({ userImageUrl, username, sharedItemType }) => {
  const { t } = useTranslation();

  const { roundness, colors } = darkTheme;

  const { data: milestoneObjective } = useSmallestMilestoneObjective({ variables: { where: { milestoneType: MilestoneType.referralMilestone } } });

  if (!username) {
    log.warn("JoinMeCard: Card displayed with a utm_campaing not being an user");
    return null;
  }

  return (
    <View style={styles.root}>
      <View style={styles.joinMeContainer}>
        <AvatarImage image={userImageUrl} fallbackText={username?.charAt(0) ?? ""} size={56} />
        <View style={styles.joinMePopper}>
          <View style={[styles.joinMePopperArrow, { backgroundColor: colors.secondary }]} />
          <View style={[styles.joinMePopperText, { backgroundColor: colors.secondary, borderRadius: roundness }]}>
            <Text variant="body2" color="common.white">
              {t("inAppBrowser.joinMe", { context: sharedItemType })}
            </Text>
          </View>
        </View>
      </View>

      {milestoneObjective?.reward && (
        <View style={styles.creditContainer}>
          <View style={styles.creditTextContainer}>
            <Text variant="caption" textTransform="uppercase" textAlign="center" color="common.white">
              {t("inAppBrowser.bySigningUp")}
            </Text>
            <Text variant="h6" textAlign="center" color="common.white">
              {t("inAppBrowser.youAnd", { username })}
            </Text>
            <Text variant="caption" textTransform="uppercase" textAlign="center" color="common.white">
              {t("inAppBrowser.willEarn")}
            </Text>
          </View>
          <RewardChip
            content={
              <Text variant="h5" color="common.white">
                {formatPriceUSD(milestoneObjective.reward, { withoutUSD: true })}
              </Text>
            }
            style={styles.milestoneContainer}
          />
        </View>
      )}
    </View>
  );
};
