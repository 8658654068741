import React, { FC } from "react";

import { MagnifyingGlass } from "phosphor-react-native";
import { useTranslation } from "react-i18next";
import { Pressable, SafeAreaView, View } from "react-native";
import { Divider, useTheme } from "react-native-paper";
import { useSafeAreaInsets } from "react-native-safe-area-context";

import OffscriptLogo from "@app/assets/logos/offscript-compact-logo.svg";
import { Routes, useHomeNavigation } from "@app/common/types/navigation.type";
import { NotificationButton } from "@app/components/common/notification-button/notification-button.component";
import { SupercoinButton } from "@app/components/common/supercoin-button/supercoin-button.component";
import { Text } from "@app/components/common/text/text.component";

import { styles } from "./home-nav-bar.style";

export const HomeNavigationBar: FC = () => {
  const { t } = useTranslation();
  const { top } = useSafeAreaInsets();
  const { colors } = useTheme();

  const navigation = useHomeNavigation();

  return (
    <SafeAreaView style={[styles.header, { marginTop: top }]}>
      <View style={[styles.root, styles.row]}>
        <View style={styles.iconContainer}>
          <OffscriptLogo color={colors.onBackground} />
        </View>
        <Pressable
          onPress={() => navigation.push(Routes.searchStack, { screen: Routes.search })}
          style={[styles.searchButton, styles.row, { backgroundColor: colors.surfaceVariant }]}>
          <MagnifyingGlass size={20} color={colors.primary} />
          <Text variant="inputText" color="tertiary">
            {t("search.placeholder")}
          </Text>
        </Pressable>
        <View style={styles.row}>
          <NotificationButton onButtonPress={() => navigation.navigate(Routes.notifications)} />
          <SupercoinButton />
        </View>
      </View>
      <Divider />
    </SafeAreaView>
  );
};
