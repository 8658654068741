import React, { FC, useCallback, useEffect, useState } from "react";

import { AuthError, applyActionCode } from "firebase/auth";
import { useTranslation } from "react-i18next";
import { View } from "react-native";
import { ActivityIndicator } from "react-native-paper";

import { useNavigation } from "@app/common/types/navigation.type";
import { Text } from "@app/components/common/text/text.component";
import { useAuthContext } from "@app/context/auth/auth.context";
import { getAuthWhenReady } from "@app/context/auth/firebase";
import { useLoginContext } from "@app/context/auth/login/login.context";
import { useSnackbarContext } from "@app/context/snackbar/snackbar.context";
import { LoginAction, useHandleAuthError } from "@app/hooks/utils/use-handle-auth-error.hook";
import { useNewHomeNavigation } from "@app/hooks/utils/use-new-home-navigation.hook";

import { styles } from "./handle-verify-email.style";

interface Props {
  actionCode: string;
}

export const HandleVerifyEmail: FC<Props> = ({ actionCode }) => {
  const { t } = useTranslation();
  const navigation = useNavigation();

  const { setVerified } = useAuthContext();
  const { showErrorSnackbar } = useSnackbarContext();
  const { openLogin } = useLoginContext();
  const { handleAuthError } = useHandleAuthError();

  const { navigateToMainScreen } = useNewHomeNavigation();

  const [success, setSuccess] = useState(false);

  const navigateBack = useCallback((): void => {
    navigateToMainScreen();
  }, [navigateToMainScreen]);

  useEffect(() => {
    navigation.setOptions({ headerTitle: t("login.email.verifyEmail") });
  }, [navigation, t]);

  useEffect(() => {
    getAuthWhenReady()
      .then(auth =>
        applyActionCode(auth, actionCode)
          .then(() => {
            const user = auth.currentUser;

            if (!user) {
              navigateBack();
              openLogin();
              return;
            }

            setVerified();
            setSuccess(true);
          })
          .catch(() => {
            showErrorSnackbar({ message: t("login.email.invalidActionCode") });
            navigateBack();
          }),
      )
      .catch((error: AuthError) => {
        handleAuthError(error, LoginAction.getAuth);
        navigateBack();
      });
  }, [actionCode, handleAuthError, navigateBack, openLogin, setVerified, showErrorSnackbar, t]);

  return success ? (
    <View style={styles.successContainer}>
      <Text variant="title" textAlign="center">
        {t("success.generic")}
      </Text>
      <Text variant="body1" textAlign="center">
        {t("login.email.noRedirect")}
      </Text>
    </View>
  ) : (
    <ActivityIndicator size="large" />
  );
};
