import { useTranslation } from "react-i18next";

import {
  ConceptsSortingMethod,
  ForSaleCollabsSortingMethod,
  InDevelopmentCollabsSortingMethod,
  UnderReviewCollabsSortingMethod,
} from "@app/common/graphql/generated/schema.graphql";
import { HomeDataType, HomeSectionItemType, HomeSectionType } from "@app/components/home/home.type";
import { useProfileContext } from "@app/context/profile/profile.context";
import { conditionalItem } from "@app/utils/conditional-item-in-array.util";

interface Response {
  homeSections: HomeSectionItemType[];
}

export const useHomeSections = (): Response => {
  const { t } = useTranslation();
  const { profile } = useProfileContext();

  const homeSections = [
    { title: "", type: HomeSectionType.ads, dataType: HomeDataType.ad },
    { title: t("home.profiles"), type: HomeSectionType.profiles, dataType: HomeDataType.profile },
    ...conditionalItem(!!profile?.userId, {
      title: t("home.continueShopping"),
      type: HomeSectionType.purchaseableCollabs,
      dataType: HomeDataType.collab,
    }),
    {
      title: t("home.forSale"),
      type: HomeSectionType.forSaleCollabs,
      dataType: HomeDataType.collab,
      defaultSort: ForSaleCollabsSortingMethod.newest,
    },
    {
      title: t("home.onGoingCampaigns"),
      type: HomeSectionType.underReviewCollabs,
      dataType: HomeDataType.collab,
      defaultSort: UnderReviewCollabsSortingMethod.trending,
    },
    {
      title: t("home.successfulCampaigns"),
      type: HomeSectionType.inDevelopmentCollabs,
      dataType: HomeDataType.collab,
      defaultSort: InDevelopmentCollabsSortingMethod.newest,
    },
    { title: t("home.concepts"), type: HomeSectionType.concepts, dataType: HomeDataType.collab, defaultSort: ConceptsSortingMethod.trending },
    ...conditionalItem(!!profile?.userId, {
      title: t("home.latestUpdates"),
      type: HomeSectionType.latestCollabUpdatesForUser,
      dataType: HomeDataType.update,
    }),
    { title: t("home.behindTheScenes"), type: HomeSectionType.behindTheScenesVideos, dataType: HomeDataType.video },
  ];

  return { homeSections };
};
