import { useCallback } from "react";

import { AuthCredential, AuthError, AuthErrorCodes, fetchSignInMethodsForEmail } from "firebase/auth";
import { useTranslation } from "react-i18next";

import { getAuthWhenReady } from "@app/context/auth/firebase";
import { LinkLoginProvidersProps } from "@app/context/auth/login/login.context";
import { useSnackbarContext } from "@app/context/snackbar/snackbar.context";
import { getUserLocation } from "@app/utils/location.util";

export enum LoginAction {
  changePassword = "changePassword",
  createAccountWithEmail = "createAccountWithEmail",
  enterEmail = "enterEmail",
  fetchSignInMethodsForEmailAfterEnterEmail = "fetchSignInMethodsForEmailAfterEnterEmail",
  fetchSignInMethodsForEmailToLinkAccount = "fetchSignInMethodsForEmailToLinkAccount",
  getAuth = "getAuthWhenReady",
  linkWithCredential = "linkWithCredential",
  loginWithApple = "loginWithApple",
  loginWithEmail = "loginWithEmail",
  loginWithFacebook = "loginWithFacebook",
  loginWithGoogle = "loginWithGoogle",
  reauthenticateWithCredential = "reauthenticateWithCredential",
}

interface HandleAuthErrorWithAccountLinkingErrorsParams {
  email: string;
  pendingCredential: AuthCredential;
  loginAction: LoginAction;
  setLoading: (loading: boolean) => void;
  currentSignInMethod: string;
  openLinkProvidersPrompt: (props: LinkLoginProvidersProps) => void;
}

interface HandleAuthErrorUtils {
  handleAuthError: (error: AuthError, loginAction: LoginAction) => void;
  handleAuthErrorWithAccountLinkingErrors: (error: AuthError, params: HandleAuthErrorWithAccountLinkingErrorsParams) => void;
}

const chinaCountryCodes = ["CN", "HK"];
const ignoreAuthErrors: string[] = [AuthErrorCodes.POPUP_CLOSED_BY_USER, AuthErrorCodes.POPUP_BLOCKED, AuthErrorCodes.USER_CANCELLED];

export function useHandleAuthError(): HandleAuthErrorUtils {
  const { t } = useTranslation();
  const { showErrorSnackbar } = useSnackbarContext();

  const isFromChina = useCallback(
    (): Promise<boolean> =>
      getUserLocation()
        .then(location => chinaCountryCodes.includes(location.country_code))
        .catch(() => false),
    [],
  );

  const getErrorMessage = useCallback(
    async (code: string): Promise<string> => {
      switch (code) {
        case AuthErrorCodes.POPUP_BLOCKED:
          return t("error.login.popupBlocked");
        case AuthErrorCodes.TOO_MANY_ATTEMPTS_TRY_LATER:
          return t("error.login.tooMany");
        case AuthErrorCodes.NETWORK_REQUEST_FAILED: {
          const isUserFromChina = await isFromChina();
          if (isUserFromChina) {
            return t("error.login.fromChina");
          }
          return t("error.login.generic");
        }
        default:
          return t("error.login.generic");
      }
    },
    [isFromChina, t],
  );

  const handleAuthError = useCallback(
    (error: AuthError, loginAction: LoginAction): void => {
      if (ignoreAuthErrors.includes(error.code)) return;

      getErrorMessage(error.code)
        .then(message => ({
          error,
          message,
          context: {
            tags: { loginAction },
            extra: {
              loginAction,
              details: JSON.stringify(error.message),
              name: error.name,
              code: JSON.stringify(error.code),
              cause: error.cause,
              stack: error.stack,
              customData: JSON.stringify(error.customData),
              network: "",
            },
          },
        }))
        .then(showErrorSnackbar)
        .catch(() => undefined);
    },
    [getErrorMessage, showErrorSnackbar],
  );

  const handleAuthErrorWithAccountLinkingErrors = useCallback(
    (
      error: AuthError,
      {
        email,
        pendingCredential,
        loginAction,
        setLoading,
        currentSignInMethod,
        openLinkProvidersPrompt,
      }: HandleAuthErrorWithAccountLinkingErrorsParams,
    ): void => {
      const accountLinkingErrors: string[] = [AuthErrorCodes.EMAIL_EXISTS, AuthErrorCodes.NEED_CONFIRMATION];
      if (accountLinkingErrors.includes(error.code)) {
        getAuthWhenReady()
          .then(auth =>
            fetchSignInMethodsForEmail(auth, email)
              .then(signInMethodsResponse => {
                openLinkProvidersPrompt({
                  email,
                  newSignInMethod: currentSignInMethod,
                  existingSignInMethods: signInMethodsResponse,
                  pendingCredential,
                });

                setLoading(false);
              })
              .catch((nestedError: AuthError) => {
                setLoading(false);
                handleAuthError(nestedError, LoginAction.fetchSignInMethodsForEmailToLinkAccount);
              }),
          )
          .catch((nestedError: AuthError) => {
            setLoading(false);
            handleAuthError(nestedError, LoginAction.getAuth);
          });
      } else {
        setLoading(false);
        handleAuthError(error, loginAction);
      }
    },
    [handleAuthError],
  );

  return { handleAuthError, handleAuthErrorWithAccountLinkingErrors };
}
