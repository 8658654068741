import React, { FC } from "react";

import { StyleProp, ViewStyle } from "react-native";
import { useTheme } from "react-native-paper";

import { Collab } from "@app/common/graphql/generated/schema.graphql";
import { Routes, useNavigation } from "@app/common/types/navigation.type";
import { ListItem } from "@app/components/common/list-item/list-item.component";

import { ProductDetailedStage } from "../product-detailed-stage/product-detailed-stage.component";
import { ProductPrice } from "../product-price/product-price.component";

import { ProductPreviewActionButtons } from "./product-preview-action-buttons/product-preview-action-buttons.component";
import { styles } from "./product-preview.style";

interface Props {
  product: Collab;
  style?: StyleProp<ViewStyle>;
}

export const ProductPreview: FC<Props> = ({ product, style }) => {
  const { colors } = useTheme();

  const navigation = useNavigation();

  const handlePress = (): void => navigation.navigate(Routes.product, { id: product.handle });

  return (
    <ListItem
      title={product.name}
      titleProps={{ variant: "caption", textTransform: "uppercase", numberOfLines: 1 }}
      subtitle={
        <ProductPrice price={product.formattedPrice} compareAtPrice={product.formattedCompareAtPrice} commonTextProps={{ variant: "caption" }} />
      }
      description={<ProductDetailedStage product={product} style={styles.detailedStage} />}
      imageProps={{ image: product.images?.[0].imageUrl, size: 80, style: { borderRadius: 0 } }}
      right={<ProductPreviewActionButtons product={product} />}
      onPress={handlePress}
      contentContainerStyle={styles.contentContainer}
      textContainerStyle={styles.textContainer}
      style={[{ backgroundColor: colors.tertiaryContainer }, styles.root, style]}
    />
  );
};
