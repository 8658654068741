import React, { FC } from "react";

import { StyleProp, ViewStyle } from "react-native";

import { Profile } from "@app/common/graphql/generated/schema.graphql";
import { Routes, useNavigation } from "@app/common/types/navigation.type";
import { ListItem } from "@app/components/common/list-item/list-item.component";

import { CreatorName, CreatorNameProps } from "../creator-name/creator-name.component";
import { ProfileAvatar } from "../profile-avatar/profile-avatar.component";

import { styles } from "./creator-name-with-avatar.style";

interface Props extends Pick<CreatorNameProps, "textProps"> {
  profile: Profile;
  badgeSize?: number;
  imageSize?: number;
  style?: StyleProp<ViewStyle>;
  width: number;
}

export const CreatorNameWithAvatar: FC<Props> = ({ profile, badgeSize, imageSize = 32, textProps, width, style }) => {
  const navigation = useNavigation();

  return (
    <ListItem
      title={
        <CreatorName
          name={profile.displayName}
          badge={profile.badge}
          iconSize={badgeSize}
          textProps={{ numberOfLines: 1, maxWidth: width - imageSize - 40, ...textProps }}
        />
      }
      left={<ProfileAvatar imageUrl={profile.imageUrl} video={profile.video} size={imageSize} name={profile.displayName} />}
      contentContainerStyle={styles.contentContainer}
      style={style}
      onPress={() => navigation.navigate(Routes.profile, { id: profile.username })}
    />
  );
};
