import React, { FC } from "react";

import { FlashList, FlashListProps } from "@shopify/flash-list";
import { DimensionValue, View } from "react-native";
import { useTheme } from "react-native-paper";

import { imageRatio } from "@app/common/constants/image.const";
import { Collab } from "@app/common/graphql/generated/schema.graphql";
import { customFonts } from "@app/common/style/fonts";
import { Skeleton } from "@app/components/common/skeleton/skeleton.component";

import { styles } from "./product-list-loading.style";

interface Props extends Omit<FlashListProps<Collab>, "renderItem" | "data"> {
  quantity: number;
  screenWidth: DimensionValue;
  screenHeight: DimensionValue;
  itemWidth: number;
}

export const ProductListLoading: FC<Props> = ({ quantity, screenHeight, screenWidth, itemWidth, ...loadingFlashListProps }) => {
  const { roundness } = useTheme();

  return (
    <View style={{ width: screenWidth, height: screenHeight }}>
      <FlashList
        data={new Array(quantity).fill(0)}
        keyExtractor={(_item, index) => `skeleton-product-${index}`}
        renderItem={() => (
          <View style={[styles.productWrapper, { width: itemWidth }, styles.skeletonsWrapper]}>
            <View style={styles.creatorNameSkeleton}>
              <Skeleton height={32} width={32} radius="round" />
              <Skeleton height={customFonts.caption.lineHeight} width={(itemWidth * 3) / 4} radius={roundness} />
            </View>
            <View style={styles.bottomContent}>
              <Skeleton height={itemWidth * imageRatio} width={itemWidth} radius="square" />
              <Skeleton height={customFonts.caption.lineHeight} width={(itemWidth * 3) / 4} radius={roundness} />
              <Skeleton height={customFonts.caption.lineHeight} width={itemWidth / 2} radius={roundness} />
            </View>
          </View>
        )}
        showsVerticalScrollIndicator={false}
        showsHorizontalScrollIndicator={false}
        {...loadingFlashListProps}
      />
    </View>
  );
};
