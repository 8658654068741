import React, { FC, useCallback } from "react";

import { FlashList, ListRenderItem } from "@shopify/flash-list";
import { useTranslation } from "react-i18next";

import { HomeStackScreenProps, Routes } from "@app/common/types/navigation.type";
import { ScreenWrapper } from "@app/components/common/screen-wrapper/screen-wrapper.component";
import { HomeNavigationBar } from "@app/components/home/home-nav-bar/home-nav-bar.component";
import { AdSection } from "@app/components/home/home-section/ad-section/ad-section.component";
import { CollabSection } from "@app/components/home/home-section/collab-section/collab-section.component";
import { CollabUpdateSection } from "@app/components/home/home-section/collab-update-section/collab-update-section.component";
import { ContestSection } from "@app/components/home/home-section/contest-section/contest-section.component";
import { PostCollabSection } from "@app/components/home/home-section/post-collab-section/post-collab-section.component";
import { ProfileSection } from "@app/components/home/home-section/profile-section/profile-section.component";
import { VideoSection } from "@app/components/home/home-section/video-section/video-section.component";
import { HomeCollabSectionType, HomeDataType, HomeSectionItemType, HomeVideoSectionType } from "@app/components/home/home.type";
import { HomeContextProvider } from "@app/context/home/home.provider";
import { useHomeSections } from "@app/hooks/utils/home/use-home-sections.hook";
import { useHandleReferral } from "@app/hooks/utils/use-handle-referral.hook";
import { assertNever } from "@app/utils/runtime.util";

export const HomeScreen: FC<HomeStackScreenProps<Routes.home>> = () => {
  const { t } = useTranslation();

  const { homeSections } = useHomeSections();

  useHandleReferral();

  const renderItem = useCallback<ListRenderItem<HomeSectionItemType>>(({ item }) => {
    switch (item.dataType) {
      case HomeDataType.ad:
        return <AdSection />;
      case HomeDataType.profile:
        return <ProfileSection title={item.title} type={item.type} />;
      case HomeDataType.collab:
        return <CollabSection title={item.title} type={item.type as HomeCollabSectionType} sortBy={item.defaultSort} />;
      case HomeDataType.update:
        return <CollabUpdateSection title={item.title} type={item.type} />;
      case HomeDataType.video:
        return <VideoSection title={item.title} type={item.type as HomeVideoSectionType} />;
      default:
        return assertNever(item.dataType);
    }
  }, []);

  return (
    <HomeContextProvider>
      <HomeNavigationBar />
      <ScreenWrapper safeAreaEdges={["left", "right"]}>
        <FlashList
          data={homeSections}
          renderItem={renderItem}
          estimatedItemSize={400}
          getItemType={item => item.dataType}
          showsVerticalScrollIndicator={false}
          ListFooterComponent={
            <>
              <PostCollabSection />
              <ContestSection title={t("home.activeContests")} />
            </>
          }
        />
      </ScreenWrapper>
    </HomeContextProvider>
  );
};
